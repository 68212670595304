import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Cell } from "react-table";
import { Params } from "src/api/orders";
import BreadCrumbs from "src/components/BreadCrumbs";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Table from "src/components/Table";
import Typography from "src/components/Typography";
import { postActiveCheckoutId } from "src/store/actions/checkout";
import { getAllOrders, getOrderById } from "src/store/actions/order";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { column } from "../Dashboard/constants";
import { Reports } from "../OrderCreate/Components/SelectReport/types";
import classes from "./styles.module.scss";

const Orders = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const actions = useStoreActions({
    getAllOrders,
    getOrderById,
    postActiveCheckoutId,
  });

  const { data: user } = useReducerData("user", "user", {});
  const isPartner = get(user, "is_partner", 0) === 1;
  const { loading, orders } = useReducerData("order", "allOrders", {});
  const { data = [], meta = {} } = orders;
  const [sortState, setSortState] = useState<Params>({});
  const [searchString, setSearchString] = useState<string | null>();

  const handleSort = (id: string) => {
    setSortState({
      ...sortState,
      sort_by: id,
      sort_order: sortState.sort_order === "asc" ? "desc" : "asc",
    });
  };

  useEffect(() => {
    actions.getAllOrders(sortState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortState]);

  const getUpdatedUrlBasedOnReportType = (
    url: string,
    reportType: "Shingle ID Report" | "Siding ID Report"
  ) => {
    const report =
      reportType === "Shingle ID Report" ? Reports.Shingle : Reports.Siding;
    navigate(`${url}?report=${report}`);
  };

  const handleTableRowClick = (cell: Cell<any>) => {
    if (cell.row.original.status === "Draft") {
      const reportType = cell.row.original?.report_type || "Shingle ID Report";
      actions.postActiveCheckoutId(cell.row.original.id);
      switch (cell.row.original?.step_on) {
        case 1:
          return getUpdatedUrlBasedOnReportType(
            "/order/create/property",
            reportType
          );

        case 2:
          return getUpdatedUrlBasedOnReportType(
            "/order/create/contact",
            reportType
          );

        case 3:
          return getUpdatedUrlBasedOnReportType(
            "/order/create/checkout",
            reportType
          );

        default:
          return getUpdatedUrlBasedOnReportType(
            "/order/create/property",
            reportType
          );
      }
    } else {
      navigate(`/orders/${cell.row.original.id}`, {
        state: {
          id: cell.row.original.id,
          status: cell.row.original?.status,
        },
      });
    }
  };

  const onPageChange = async (page: number) => {
    setSortState({ ...sortState, page: page });
  };

  if (!orders?.data?.length) {
    return (
      <>
        <div className={classes.breadcrumbNoOrderWrapper}>
          <BreadCrumbs path={pathname} />
        </div>
        <div className={classes.noOrders}>
          <Card
            cardTitleClassName={classes.title}
            cardClassName={classes.card}
            cardBodyClassName={classes.cardBody}
            cardTitle="No orders to display"
          >
            <Typography fontWeight="light" variant="p14">
              You haven’t placed any orders yet
            </Typography>
            {!isPartner && (
              <Button
                onClick={() => navigate("/order/create/select-report")}
                buttonText="Order your first report now"
              />
            )}
          </Card>
        </div>
      </>
    );
  }
  const handleSearch = (searchText: string) => {
    let page = sortState.page;
    if (searchText !== searchString) {
      page = 1;
    }
    setSearchString(searchText);
    const params = {
      ...sortState,
      page,
      keyword: searchText,
    };
    setSortState(params);
    actions.getAllOrders(params);
  };
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.breadcrumbWrapper}>
          <BreadCrumbs path={pathname} />
        </div>
        <Table
          loading={loading}
          showPagination={meta?.per_page < meta?.total}
          paginationOption={{
            currentPage: meta?.current_page,
            itemPerPage: meta?.per_page,
            totalItems: meta?.total,
            from: meta?.from,
            to: meta?.to,
          }}
          onPageChange={onPageChange}
          handleRowClick={handleTableRowClick}
          data={data}
          columns={column}
          handleSort={handleSort}
          tableHeaderText="All Orders"
          search
          searchProps={{
            onSearch: handleSearch,
            searchPlaceholder: "Search order",
          }}
        />
      </div>
    </>
  );
};

export default Orders;
