import cx from "classnames";
import React from "react";
import { Input as BaseInput, InputGroup, InputGroupText } from "reactstrap";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Input: React.FC<Props> = (Props) => {
  const {
    isAutoComplete = "off",
    inputClassName = "",
    inputGroupClassName = "",
    outlined = true,
    loading = false,
    leftIcon,
    rightIcon,
    iconClassName = "",
    disabled = false,
    focused = false,
    error = "",
    label,
    labelClassName = "",
    id,
    alternative = false,
    inputId,
    touched = false,
    required = false,
    ...rest
  } = Props;

  const renderIcon = (icon: React.ReactNode) => {
    if (!icon) return;
    return (
      <InputGroupText
        className={cx(classes.iconStyling, {
          "border-0": !outlined && !alternative,
          [iconClassName]: iconClassName,
        })}
      >
        {icon}
      </InputGroupText>
    );
  };

  const renderLabel = () => {
    if (!label) return;
    return (
      <label
        className={cx("form-control-label mb-1", {
          [labelClassName]: labelClassName,
        })}
        htmlFor={id}
      >
        {label}
        {required && <span className={classes.requiredLabel}>*</span>}
      </label>
    );
  };

  if (!leftIcon && !rightIcon) {
    return (
      <>
        {renderLabel()}
        <InputGroup
          className={cx("input-group-merge", classes.formGroupStyling, {
            [inputGroupClassName]: inputGroupClassName,
            "input-group-alternative": alternative,
            [classes.focused]: focused,
            [classes.isInvalid]: touched && error,
          })}
        >
          <BaseInput
            {...(inputId ? { id: inputId } : {})}
            className={cx(classes.inputStyling, {
              [inputClassName]: inputClassName,
              "border-0": !outlined,
            })}
            disabled={disabled || loading}
            autoComplete={isAutoComplete ? isAutoComplete : "off"}
            {...rest}
          />
        </InputGroup>
        {touched && error && (
          <div className={classes.invalidFeedback}>{error}</div>
        )}
      </>
    );
  }

  return (
    <>
      {renderLabel()}
      <InputGroup
        className={cx("input-group-merge", classes.formGroupStyling, {
          "input-group-alternative": alternative,
          [inputGroupClassName]: inputGroupClassName,
          [classes.focused]: focused,
          [classes.isInvalid]: touched && error,
        })}
      >
        {renderIcon(leftIcon)}
        <BaseInput
          className={cx(classes.inputStyling, {
            ["px-0"]: !!leftIcon,
            [inputClassName]: inputClassName,
          })}
          {...(inputId ? { id: inputId } : {})}
          disabled={disabled || loading}
          error={null}
          {...rest}
        />
        {renderIcon(rightIcon)}
      </InputGroup>
      {touched && error && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </>
  );
};

export default Input;
