import Signup from "src/pages/Auth/Signup";
import AdminDashboard from "./pages/AdminDashboard";
import AdminOrders from "./pages/AdminOrders";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import SignIn from "./pages/Auth/Signin";
import Customers from "./pages/Customers";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import CheckoutForm from "./pages/OrderCreate/Components/CheckoutForm";
import ContactForm from "./pages/OrderCreate/Components/ContactForm";
import PropertyForm from "./pages/OrderCreate/Components/PropertyForm";
import SelectReport from "./pages/OrderCreate/Components/SelectReport";
import OrderDetails from "./pages/OrderDetails";
import Orders from "./pages/Orders";
import Plans from "./pages/Plans";
import Settings from "./pages/Settings";
import StripeCallback from "./pages/StripeCallback";
import TermsConditions from "./pages/TermConditions";

const routes = [
  {
    path: "/auth/login",
    name: "Login",
    component: SignIn,
    layout: "auth",
  },
  {
    path: "/auth/signup",
    name: "Signup",
    component: Signup,
    layout: "auth",
  },
  {
    path: "/auth/signup/:params",
    name: "Signup",
    component: Signup,
    layout: "auth",
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    layout: "auth",
  },
  {
    path: "/auth/forgot-password/:params",
    name: "ForgotPassword",
    component: ForgotPassword,
    layout: "auth",
  },
  {
    path: "/auth/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    layout: "auth",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "private",
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    layout: "admin",
  },
  {
    path: "/admin/orders",
    name: "AdminOrders",
    component: AdminOrders,
    layout: "admin",
  },
  {
    path: "/admin/customers",
    name: "Customers",
    component: Customers,
    layout: "admin",
  },
  {
    path: "/admin/settings",
    name: "Settings",
    component: Settings,
    layout: "admin",
  },
  {
    path: "/admin/orders/:id",
    name: "OrdersDetails",
    component: OrderDetails,
    layout: "admin",
  },
  {
    path: "/admin/plans",
    name: "Plans",
    component: Plans,
    layout: "admin",
  },
  {
    path: "/plans",
    name: "Plans",
    component: Plans,
    layout: "private",
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    layout: "private",
  },
  {
    path: "/orders/:id",
    name: "OrdersDetails",
    component: OrderDetails,
    layout: "private",
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "private",
  },
  {
    path: "/order/create/select-report",
    name: "SelectReport",
    component: SelectReport,
    layout: "checkout",
  },
  {
    path: "/order/create/property",
    name: "PropertyForm",
    component: PropertyForm,
    layout: "checkout",
  },
  {
    path: "/order/create/contact",
    name: "ContactForm",
    component: ContactForm,
    layout: "checkout",
  },
  {
    path: "/order/create/checkout",
    name: "CheckOutForm",
    component: CheckoutForm,
    layout: "checkout",
  },
  {
    path: "/stripe-callback",
    name: "StripeCallback",
    component: StripeCallback,
    layout: "private",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    layout: "public",
  },
  {
    path: "/terms",
    name: "termCondition",
    component: TermsConditions,
    layout: "public",
  },
  {
    path: "*",
    name: "AdminDashboard",
    component: AdminDashboard,
    layout: "admin",
  },
  {
    path: "*",
    name: "Dashboard",
    component: Dashboard,
    layout: "private",
  },
  {
    path: "*",
    name: "Login",
    component: SignIn,
    layout: "auth",
  },
  {
    path: "*",
    name: "Home",
    component: Home,
    layout: "public",
  },
];

export default routes;
