import {
  GoogleMap,
  StreetViewPanorama,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";
import { AddressProps } from "./types";

const mapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";
Geocode.setApiKey(mapApiKey);

const StreetView: React.FC<AddressProps> = ({ propertyAddress }) => {
  const [center, setCenter] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: mapApiKey,
    libraries: ["places"],
  });

  useEffect(() => {
    if (!isLoaded) return;
    const getGeometryLat = async () => {
      setLoading(true);
      try {
        const { results } = await Geocode.fromAddress(
          propertyAddress as string
        );
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { lat, lng } = results[0]?.geometry?.location;
        setCenter({
          lat: lat,
          lng: lng,
        });
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    getGeometryLat();
  }, [isLoaded, propertyAddress]);

  if (!isLoaded || loading) {
    return (
      <div style={{ position: "relative" }}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Typography variant="p14">Streetview Image Not Found</Typography>
      </div>
    );
  }

  return (
    <div className={classes.mapStyle}>
      <GoogleMap
        id="map"
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={center}
        zoom={7}
        options={{
          fullscreenControl: false,
          disableDefaultUI: true,
          scrollwheel: false,
          disableDoubleClickZoom: true,
          draggable: false,
        }}
      >
        <StreetViewPanorama
          options={{
            position: center,
            visible: true,
            fullscreenControl: false,
            disableDefaultUI: true,
            scrollwheel: false,
            disableDoubleClickZoom: true,
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default StreetView;
