import { faCircleRight } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router";
import { Container } from "reactstrap";
import HeroBg from "src/assets/images/NTS-hero-bg.jpg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";

const HeroComponent = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    const element = document.getElementById("nts-report");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: ` url(${HeroBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container className={classes.container}>
        <div className={classes.leftContent}>
          <Typography className={classes.heading} fontWeight="bold">
            <span> Independent, third-party </span>identification reports
          </Typography>
          <Typography className={classes.description} variant="p20">
            NTS provides objective, expert analysis of roofing products. Our
            reports determine market availability, compatibility & repairability
            to property owners, contractors, engineers, insurers & adjusters.
          </Typography>
          <div className={classes.reportButtons}>
            <Button
              onClick={() => navigate("/order/create/select-report")}
              buttonColor="warning"
              buttonText="Order a Single Identification Report"
              rightIcon={<FontAwesomeIcon icon={faCircleRight} />}
            />
            <Button
              onClick={handleNavigation}
              buttonClassName={classes.outlineClass}
              variant="outline"
              buttonColor="secondary"
              buttonText="See a sample report"
            />
          </div>
          <a
            className={classes.links}
            href="https://ntsid.com/tile-reports"
            target="_blank"
            rel="noreferrer"
          >
            Click here to order a tile shingle identification report{" "}
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
          {/* <div className={classes.colorLinks}>
            <a
              className={classes.links}
              href="https://ntsid.com/color-analysis"
              target="_blank"
              rel="noreferrer"
            >
              Click here to order a color analysis report{" "}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          </div> */}
        </div>
        <div className={classes.media}>
          <div className={classes.videoWrapper}>
            <iframe
              height="100%"
              width="100%"
              src="https://youtube.com/embed/q9vi-6IMOx4"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroComponent;
