/* eslint-disable max-lines */

import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import CheckoutFormThreeImage from "src/assets/images/checkout-step-three-image.jpg";
import Button from "src/components/Button";
import ButtonGroup from "src/components/ButtonGroup";
import CheckBox from "src/components/CheckBox";
import Input from "src/components/Input";
import RadioInput from "src/components/RadioInput";
import Typography from "src/components/Typography";
import CheckoutSidebar from "src/pages/OrderCreate";
import OrderSummary from "src/pages/OrderCreate/Components/OrderSummary";
import {
  getProducts,
  postCheckoutDetails,
  removeCheckoutDetails,
} from "src/store/actions/checkout";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { telephoneNumberRegex } from "src/utils/constants";
import { useWindowSize } from "src/utils/useWindowSize";
import { getFormattedNumber } from "src/utils/utils";
import FormikAddress from "../FormikAddress";
import { OptionType } from "../PropertyForm/types";
import StripeForm from "../StripeForm";
import SubscriptionSummary from "../SubscriptionSummary";
import classes from "./styles.module.scss";
import { ErrorMessageProps, FormProps } from "./types";
import "./payment.scss";

const CheckoutForm = () => {
  const navigate = useNavigate();
  const { width = 0 } = useWindowSize();
  const isTablet = width < 1024;
  const id = useReducerData("checkout", "activeCheckoutId", null);
  const { data: products } = useReducerData("checkout", "products", {});
  const { data: user } = useReducerData("user", "user", {});
  const isCredit = user?.subscription?.credits > 0;
  const isSubscriber = user?.subscription?.status === "active";
  const hasPaymentMethodSaved = user?.payment_methods?.payment_method_id;
  const { data: promoCodeDetails } = useReducerData(
    "checkout",
    "promoSuccessDetails",
    {
      data: {},
    }
  );
  const actions = useStoreActions({
    postCheckoutDetails,
    removeCheckoutDetails,
    getProducts,
  });

  const radioOptions = [
    {
      label: `Use existing payment method (Card ending with ${user?.payment_methods?.card_last_digit})
    `,
      value: "existing",
    },
    { label: "Add new payment method", value: "new" },
  ];

  useEffect(() => {
    if (hasPaymentMethodSaved) {
      setSelectedOption(radioOptions[0].value);
    } else {
      setSelectedOption(radioOptions[1].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPaymentMethodSaved]);

  useEffect(() => {
    actions.getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [generateShippingLabel, setGenerateShippingLabel] = useState(true);
  const useExistingPaymentMethod =
    isSubscriber && isCredit && !generateShippingLabel;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<ErrorMessageProps>({
    number: "",
    date: "",
    code: "",
  });

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const useSavedPaymentMethod = selectedOption === "existing";
  const checkoutSchema = Yup.lazy(() => {
    return Yup.object().shape({
      contact_name: Yup.string().when("isRequired", {
        is: true,
        then: Yup.string().required("Contact name is required"),
      }),
      contact_number: Yup.string().when("isRequired", {
        is: true,
        then: Yup.string()
          .required("Phone number is required")
          .matches(
            telephoneNumberRegex,
            "Should be valid phone number (i.e. XXX-XXX-XXXX)"
          ),
      }),
      shipping_address: Yup.string().when("isRequired", {
        is: true,
        then: Yup.string().required("Shipping address is required"),
      }),
      name_on_card: Yup.string().when([], {
        is: () => useExistingPaymentMethod || useSavedPaymentMethod,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required("Card holder name is required"),
      }),
      save: Yup.boolean(),
      termsAndCondition: Yup.boolean().oneOf(
        [true],
        "Please check this to continue"
      ),
      additionalPackaging: Yup.boolean(),
      shipping_city: Yup.string().when("isRequired", {
        is: true,
        then: Yup.string().required("Please enter city name"),
      }),
      shipping_state: Yup.object()
        .when("isRequired", {
          is: true,
          then: Yup.object().required("Please select state"),
        })
        .shape({
          value: Yup.string(),
          label: Yup.string(),
        })
        .nullable(),
      shipping_zipcode: Yup.string().when("isRequired", {
        is: true,
        then: Yup.string().required("Please enter zip code"),
      }),
    });
  });

  const validatePaymentForm = (type: string) => {
    if (error.number === "" && type === "cardNumber") {
      setError({ ...error, number: "Please enter card number" });
    }
    if (error.date === "" && type === "cardExpiry") {
      setError({ ...error, date: "Please enter expiry date" });
    }
    if (error.code === "" && type === "cardCvc") {
      setError({ ...error, code: "Please enter secure code" });
    }
  };

  const handleSubmit = async (values: FormProps) => {
    if (loading) return;
    setLoading(true);

    const formDetails = {
      order_id: id,
      shipping_details: {
        generate_label: generateShippingLabel,
        contact_name: values.contact_name,
        contact_number: values.contact_number,
        shipping_city: values.shipping_city,
        shipping_zipcode: values.shipping_zipcode,
        shipping_state: values.shipping_state?.value,
        shipping_address: values.shipping_address,
      },
      order_summary: {
        promo_code_id: promoCodeDetails?.id || "",
      },
    } as any;

    if (selectedOption === "existing") {
      formDetails.use_saved_payment_method = true;
    }

    if (!(useExistingPaymentMethod || useSavedPaymentMethod)) {
      // TODO: find a way to trigger required field validation for stripe form
      // validatePaymentForm();
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      const cardNumberElement = await elements?.getElement(CardNumberElement);
      if (!cardNumberElement) return;

      const { token } = await stripe.createToken(cardNumberElement, {
        name: values.name_on_card,
      });

      const expiryMonth =
        token?.card?.exp_month.toString().length === 1
          ? `0${token?.card?.exp_month}`
          : token?.card?.exp_month;
      const expiryYear = token?.card?.exp_year.toString().slice(2, 4);
      const cardExpiry = `${expiryMonth}/${expiryYear}`;
      const stripeToken = token?.id;
      const lastFourDigits = token?.card?.last4;

      formDetails.payment_details = {
        save: values.save,
        name_on_card: values.name_on_card,
        token: stripeToken,
        card_last_digit: lastFourDigits,
        expiry: cardExpiry,
      };
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.postCheckoutDetails(formDetails);
    setLoading(false);
    if (status === 1) {
      actions.removeCheckoutDetails();
      navigate("/dashboard");
    }
  };

  const handleChoosePayment = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <CheckoutSidebar
      title="Shipping & payment"
      step={3}
      bgImage={CheckoutFormThreeImage}
      content=""
      generateLabel={generateShippingLabel}
    >
      <div className={classes.wrapper}>
        <Typography
          fontWeight="bold"
          variant="p18"
          className={cx(classes.titleWrapper, "text-start")}
        >
          Finally, let’s confirm shipping & payment details
        </Typography>
        <Formik
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={checkoutSchema}
          initialValues={{
            isRequired: true,
            contact_name: "",
            contact_number: "",
            shipping_address: "",
            save: false,
            termsAndCondition: false,
            additionalPackaging: false,
            name_on_card: "",
            shipping_city: "",
            shipping_state: null as unknown as OptionType,
            shipping_zipcode: "",
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
            setFieldTouched,
            setFieldValue,
          }: FormikProps<FormProps>) => (
            <Form className={cx(classes.form, "d-flex flex-column")}>
              {values.isRequired && (
                <>
                  <FormikAddress
                    googleAutoComplete
                    inputId="checkout-form-address"
                    inputNames={{
                      address: "shipping_address",
                      city: "shipping_city",
                      state: "shipping_state",
                      zip_code: "shipping_zipcode",
                    }}
                    inputAddressPlaceholder="Enter shipping from address"
                    inputAddressLabel="Shipping From Address"
                    values={{
                      address: values.shipping_address,
                      city: values.shipping_city,
                      state: values.shipping_state,
                      zip_code: values.shipping_zipcode,
                    }}
                    touched={{
                      address: touched.shipping_address,
                      city: touched.shipping_city,
                      state: touched.shipping_state,
                      zip_code: touched.shipping_zipcode,
                    }}
                    errors={{
                      address: errors.shipping_address,
                      city: errors.shipping_city,
                      state: errors.shipping_state,
                      zip_code: errors.shipping_zipcode,
                    }}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                  <div className={classes.contactDetails}>
                    <FormGroup className={classes.formGroup}>
                      <Input
                        inputGroupClassName={classes.inputGroupStyle}
                        required={true}
                        labelClassName={classes.label}
                        label="Contact Name"
                        value={values.contact_name}
                        placeholder="Enter contact name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.contact_name}
                        touched={touched.contact_name}
                        name="contact_name"
                      />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                      <Input
                        inputGroupClassName={classes.inputGroupStyle}
                        required={true}
                        labelClassName={classes.label}
                        label="Contact Phone"
                        value={values.contact_number}
                        placeholder="555-555-5555"
                        onChange={(value) => {
                          setFieldValue(
                            "contact_number",
                            getFormattedNumber(value)
                          );
                        }}
                        onBlur={handleBlur}
                        error={errors.contact_number}
                        touched={touched.contact_number}
                        name="contact_number"
                      />
                    </FormGroup>
                  </div>
                </>
              )}

              <div className={classes.buttonGroupWrapper}>
                <Typography
                  fontWeight="medium"
                  variant="p14"
                  className={cx("mb-2", classes.label)}
                >
                  Generate a prepaid UPS Ground shipping label for $
                  {products?.shipping_details?.unit_amount}?
                </Typography>
                <ButtonGroup className={classes.toggleButtonWrapper}>
                  <Button
                    onClick={() => {
                      setGenerateShippingLabel(!generateShippingLabel);
                      setFieldValue("isRequired", true);
                    }}
                    type="button"
                    buttonText="Yes please!"
                    buttonColor={
                      generateShippingLabel ? "primary" : "secondary"
                    }
                  />
                  <Button
                    onClick={() => {
                      setGenerateShippingLabel(!generateShippingLabel);
                      setFieldValue("isRequired", false);
                    }}
                    type="button"
                    buttonText={
                      isTablet
                        ? "No, I’ll buy my own shipping"
                        : "No thanks, I’ll buy my own shipping"
                    }
                    buttonColor={
                      !generateShippingLabel ? "primary" : "secondary"
                    }
                  />
                </ButtonGroup>
              </div>
              {!useExistingPaymentMethod && (
                <div>
                  {hasPaymentMethodSaved && (
                    <>
                      <Typography
                        className={cx("mb-2", classes.paymentDetailText)}
                        fontWeight="medium"
                        variant="p14"
                      >
                        Select Payment method <span>*</span>
                      </Typography>
                      <RadioInput
                        inputGroupClassName="mb-4 flex-column align-items-baseline gap-1"
                        onChange={handleChoosePayment}
                        options={radioOptions}
                        selectedOption={selectedOption}
                      />
                    </>
                  )}
                  {selectedOption === "new" && (
                    <>
                      <Typography
                        className={cx("mb-2", classes.paymentDetailText)}
                        fontWeight="medium"
                        variant="p14"
                      >
                        Payment details <span>*</span>
                      </Typography>
                      <div className={classes.paymentDetails}>
                        <FormGroup className={classes.formGroup}>
                          <StripeForm
                            error={error}
                            setError={setError}
                            validatePaymentForm={validatePaymentForm}
                          />
                          <Input
                            inputGroupClassName={classes.inputGroupStyle}
                            labelClassName={classes.label}
                            value={values.name_on_card}
                            placeholder="Name on card"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.name_on_card}
                            touched={touched.name_on_card}
                            name="name_on_card"
                          />
                        </FormGroup>
                      </div>
                    </>
                  )}

                  {selectedOption === "new" && (
                    <FormGroup
                      className={cx("text-start", classes.formGroupCheckBox)}
                    >
                      <CheckBox
                        checkBoxClassName="mb-3"
                        onChange={handleChange}
                        checked={values.save}
                        onBlur={handleBlur}
                        error={errors.save}
                        touched={touched.save}
                        showOutline
                        label={
                          <Typography variant="p14" className="mb-0">
                            Save this payment method for later
                          </Typography>
                        }
                        name="save"
                      />
                    </FormGroup>
                  )}
                </div>
              )}
              <FormGroup
                className={cx("text-start", classes.formGroupCheckBox)}
              >
                <CheckBox
                  checkBoxClassName="mb-0"
                  onChange={handleChange}
                  checked={values.termsAndCondition}
                  onBlur={handleBlur}
                  error={errors.termsAndCondition}
                  touched={touched.termsAndCondition}
                  showOutline
                  label={
                    <div className="d-flex align-items-start gap-1">
                      <Typography variant="p14" className="mb-0">
                        I agree to the
                      </Typography>
                      <Button
                        buttonText="terms & conditions"
                        variant="link"
                        onClick={() => navigate("/terms")}
                      />
                    </div>
                  }
                  name="termsAndCondition"
                />
              </FormGroup>
              <FormGroup
                className={cx(
                  "text-start mb-4 mb-md-0 ",
                  classes.formGroupCheckBox
                )}
              >
                <CheckBox
                  checkBoxClassName="mb-0"
                  onChange={handleChange}
                  checked={values.additionalPackaging}
                  onBlur={handleBlur}
                  error={errors.additionalPackaging}
                  touched={touched.additionalPackaging}
                  showOutline
                  label={
                    <Typography variant="p14" className="mb-0">
                      I agree to use my own packaging. I understand I may be
                      charged separately for any additional packaging costs
                      incurred by NTS.
                    </Typography>
                  }
                  name="additionalPackaging"
                />
              </FormGroup>
              <div className={classes.orderSummaryMobile}>
                <OrderSummary generateLabel={generateShippingLabel} />
                {isSubscriber && <SubscriptionSummary hasCredit={isCredit} />}
              </div>
              <div className={classes.nextStep}>
                <Button
                  loading={loading}
                  disabled={
                    !isValid ||
                    !dirty ||
                    (!(useExistingPaymentMethod || useSavedPaymentMethod) &&
                      error.number === "") ||
                    (!(useExistingPaymentMethod || useSavedPaymentMethod) &&
                      error.date === "") ||
                    (!(useExistingPaymentMethod || useSavedPaymentMethod) &&
                      error.code === "")
                  }
                  type="submit"
                  buttonText="Complete This Order"
                  rightIcon={<i className="fa fa-arrow-circle-o-right" />}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CheckoutSidebar>
  );
};

export default CheckoutForm;
