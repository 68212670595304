/* eslint-disable max-lines */
import cx from "classnames";
import { FieldArray, Form, Formik, FormikProps, getIn } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import CheckoutFormTwoImage from "src/assets/images/checkout-step-two-image.jpg";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import CheckoutSidebar from "src/pages/OrderCreate";
import { postContactDetails } from "src/store/actions/checkout";
import { getOrderById } from "src/store/actions/order";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { telephoneNumberRegex } from "src/utils/constants";
import { getFormattedNumber } from "src/utils/utils";
import FormikAddress from "../FormikAddress";
import { stateOptions } from "../PropertyForm/constants";
import { OptionType, OrderType } from "../PropertyForm/types";
import classes from "./styles.module.scss";
import { FormProps } from "./types";

const ContactForm = () => {
  const navigate = useNavigate();
  const id = useReducerData("checkout", "activeCheckoutId", null);
  const { data: user } = useReducerData("user", "user", {});
  const [loading, setLoading] = useState(false);
  const [contactDetails, setContactDetails] = useState<OrderType | null>(null);
  const actions = useStoreActions({ postContactDetails, getOrderById });
  const comapnyDetail = user?.subscription && user?.company;
  const [searchParams] = useSearchParams();
  const report = searchParams.get("report");
  const selectedState = stateOptions.find(
    ({ value }) =>
      contactDetails?.company_state === value || comapnyDetail?.state === value
  );

  const addedRecipients = contactDetails?.additional_recipients?.map(
    (data: { recipient_name: string; recipient_email: string }) => ({
      recipient_name: data?.recipient_name || "",
      recipient_email: data?.recipient_email || "",
    })
  );

  const checkoutSchema = Yup.object().shape({
    rep_email: Yup.string()
      .required("Email is required")
      .email("Must be valid email address"),
    rep_phone: Yup.string()
      .required("Phone number is required")
      .matches(
        telephoneNumberRegex,
        "Should be valid phone number (i.e. XXX-XXX-XXXX)"
      ),
    rep_name: Yup.string().required("Representative name is required"),
    company_name: Yup.string().required("Company name is required"),
    company_address: Yup.string().required("Company address is required"),
    company_city: Yup.string().required("Please enter city name"),
    company_zip_code: Yup.string().required("Please enter zip code"),
    company_state: Yup.object()
      .required("Please select state")
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
    additional_recipients: Yup.array().of(
      Yup.object().shape({
        recipient_name: Yup.string(),
        recipient_email: Yup.string().email("Must be valid email address"),
      })
    ),
  });

  useEffect(() => {
    const fetchOrder = async () => {
      if (id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { data } = await actions.getOrderById(id as string);
        setContactDetails(data);
      }
    };
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: FormProps) => {
    if (loading) return;
    setLoading(true);
    const contactData = {
      ...values,
      company_state: values?.company_state?.value || "",
      order_id: contactDetails?.id,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.postContactDetails(contactData);
    setLoading(false);
    if (status === 1) {
      navigate(`/order/create/checkout?report=${report}`);
    }
  };

  return (
    <CheckoutSidebar
      title="Contact information"
      step={2}
      bgImage={CheckoutFormTwoImage}
      content="The representative is the person NTS will communicate with about this
      order. This representative’s name will be on the final report, and the
      final report will be sent to this representative upon completion."
    >
      <div className={classes.wrapper}>
        <Typography
          fontWeight="bold"
          variant="p18"
          className={cx(classes.titleWrapper, "text-start")}
        >
          Now let’s gather contact information for this order
        </Typography>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={checkoutSchema}
          initialValues={{
            rep_email: contactDetails?.rep_email || user?.email || "",
            rep_phone: contactDetails?.rep_phone || user?.phone || "",
            rep_name:
              contactDetails?.rep_name ||
              user?.first_name + " " + user?.last_name ||
              "",
            company_name:
              contactDetails?.company_name || comapnyDetail?.name || "",
            company_address:
              contactDetails?.company_address || comapnyDetail?.address || "",
            company_city:
              contactDetails?.company_city || comapnyDetail?.city || "",
            company_state: selectedState || (null as unknown as OptionType),
            company_zip_code:
              contactDetails?.company_zip_code || comapnyDetail?.zip_code || "",
            additional_recipients: addedRecipients || [
              {
                recipient_name: "",
                recipient_email: "",
              },
            ],
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<FormProps>) => (
            <Form className={cx(classes.form, "d-flex flex-column")}>
              <FormGroup className={classes.formGroup}>
                <Input
                  inputGroupClassName={classes.inputGroupStyle}
                  required={true}
                  labelClassName={classes.label}
                  label="Representative Name"
                  value={values.rep_name}
                  placeholder="Enter representative name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.rep_name}
                  touched={touched.rep_name}
                  name="rep_name"
                />
              </FormGroup>
              <div className={classes.nameAndEmail}>
                <FormGroup className={classes.formGroup}>
                  <Input
                    inputGroupClassName={classes.inputGroupStyle}
                    required={true}
                    labelClassName={classes.label}
                    label="Representative Email"
                    value={values.rep_email}
                    placeholder="Enter email address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.rep_email}
                    touched={touched.rep_email}
                    name="rep_email"
                  />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                  <Input
                    inputGroupClassName={classes.inputGroupStyle}
                    required={true}
                    labelClassName={classes.label}
                    label="Representative Phone"
                    value={values.rep_phone}
                    placeholder="555-555-5555"
                    onChange={(value) => {
                      setFieldValue("rep_phone", getFormattedNumber(value));
                    }}
                    onBlur={handleBlur}
                    error={errors.rep_phone}
                    touched={touched.rep_phone}
                    name="rep_phone"
                  />
                </FormGroup>
              </div>
              <FormGroup className={classes.formGroup}>
                <Input
                  inputGroupClassName={classes.inputGroupStyle}
                  required={true}
                  labelClassName={classes.label}
                  label="Company Name"
                  value={values.company_name}
                  placeholder="Enter company name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.company_name}
                  touched={touched.company_name}
                  name="company_name"
                />
              </FormGroup>
              <FormikAddress
                inputId="contact-form-address"
                inputNames={{
                  address: "company_address",
                  city: "company_city",
                  state: "company_state",
                  zip_code: "company_zip_code",
                }}
                inputAddressPlaceholder="Enter company address"
                inputAddressLabel="Company Address"
                values={{
                  address: values.company_address,
                  city: values.company_city,
                  state: values.company_state,
                  zip_code: values.company_zip_code,
                }}
                touched={{
                  address: touched.company_address,
                  city: touched.company_city,
                  state: touched.company_state,
                  zip_code: touched.company_zip_code,
                }}
                errors={{
                  address: errors.company_address,
                  city: errors.company_city,
                  state: errors.company_state,
                  zip_code: errors.company_zip_code,
                }}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
              <Typography
                variant="p12"
                className={cx("text-start", classes.additionalINfoText)}
              >
                If you would like the final report from this order to be sent to
                any additional email addresses, enter those below:
              </Typography>
              <div>
                <FieldArray name="additional_recipients">
                  {({ push }) => (
                    <div>
                      {values?.additional_recipients?.map((p, index) => {
                        const recipientName = `additional_recipients[${index}].recipient_name`;
                        const touchedRecipientName = getIn(
                          touched,
                          recipientName
                        );
                        const errorRecipientName = getIn(errors, recipientName);

                        const recipientEmail = `additional_recipients[${index}].recipient_email`;
                        const touchedRecipientEmail = getIn(
                          touched,
                          recipientEmail
                        );
                        const errorRecipientEmail = getIn(
                          errors,
                          recipientEmail
                        );
                        return (
                          <div key={index}>
                            <div className={classes.additionalInfo}>
                              <FormGroup className={classes.formGroup}>
                                <Input
                                  inputGroupClassName={classes.inputGroupStyle}
                                  label="Name"
                                  labelClassName={classes.label}
                                  value={p.recipient_name}
                                  placeholder="Enter Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errorRecipientName}
                                  touched={touchedRecipientName}
                                  name={recipientName}
                                />
                              </FormGroup>
                              <FormGroup className={classes.formGroup}>
                                <Input
                                  inputGroupClassName={classes.inputGroupStyle}
                                  label="Email Address"
                                  labelClassName={classes.label}
                                  value={p.recipient_email}
                                  placeholder="email@example.com"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errorRecipientEmail}
                                  touched={touchedRecipientEmail}
                                  name={recipientEmail}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        );
                      })}
                      <div className={cx("text-start", classes.addbtn)}>
                        <Button
                          buttonText="+ Add another recipient"
                          variant="link"
                          onClick={() =>
                            push({
                              recipient_name: "",
                              recipient_email: "",
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>

              <div className={classes.nextStep}>
                <Button
                  loading={loading}
                  disabled={!isValid}
                  type="submit"
                  buttonText="Continue to Step 3"
                  rightIcon={<i className="fa fa-arrow-circle-o-right" />}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CheckoutSidebar>
  );
};

export default ContactForm;
