import Cookie from "js-cookie";
import { Dispatch } from "redux";
import {
  User,
  forgotPassword as forgotPasswordApi,
  postLogin as postLoginApi,
  postSignup as postSignupApi,
  resendVerificationEmail as resendVerificationEmailApi,
  resetPassword as resetPasswordApi,
  resetPasswordParams,
} from "src/api/auth";
import { toast } from "src/components/Toast/ToastManager";
import Types from "../types/auth";

export const doSignUp = (data: User) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_SIGNUP_STARTED,
    });

    const resp = await postSignupApi(data);

    if (resp?.status === 1) {
      dispatch({
        type: Types.USER_SIGNUP_SUCCESS,
        payload: { data: resp },
      });
    } else {
      const { details, error } = resp.data;
      const [message] = details.email;

      dispatch({
        type: Types.USER_SIGNUP_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: error,
        content: message,
        variant: "danger",
      });
    }
    return resp;
  };
};

export const doLogin = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_LOGIN_STARTED,
    });

    const resp = await postLoginApi(email, password);
    const userData = {
      ...(resp?.access_token || {}),
      ...(resp?.user || {}),
    };
    if (resp.status === 1) {
      dispatch({
        type: Types.USER_LOGIN_SUCCESS,
        payload: { data: userData },
      });
      Cookie.set("token", userData.token);
    } else {
      dispatch({
        type: Types.USER_LOGIN_FAILED,
        payload: {
          message: resp.message,
        },
      });
      toast.show({
        content: resp.data.error,
        variant: "danger",
      });
    }
    return resp;
  };
};

export const resendEmailVerification = (email: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_EMAIL_VERIFICATION_STARTED,
    });

    const resp = await resendVerificationEmailApi(email);

    if (resp.status === 1) {
      dispatch({
        type: Types.USER_EMAIL_VERIFICATION_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.USER_EMAIL_VERIFICATION_FAILED,
        payload: {
          message: resp.message,
        },
      });
    }
    return resp;
  };
};

export const resetPassword = (params: resetPasswordParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_RESET_PASSWORD_STARTED,
    });

    const resp = await resetPasswordApi(params);

    if (resp.status === 1) {
      dispatch({
        type: Types.USER_RESET_PASSWORD_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.USER_RESET_PASSWORD_FAILED,
        payload: {
          message: resp.message,
        },
      });
      toast.show({
        content: resp.data.error,
        variant: "danger",
      });
    }
    return resp;
  };
};

export const logoutUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_LOGOUT_SUCCESS,
    });
    Cookie.remove("token");
    window.location.reload();
  };
};

export const forgotPassword = (email: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_FORGOT_PASSWORD_STARTED,
    });

    const resp = await forgotPasswordApi(email);

    if (resp.status === 1) {
      dispatch({
        type: Types.USER_FORGOT_PASSWORD_SUCCESS,
        payload: { data: resp, email },
      });
      toast.show({
        content: resp.message,
        variant: "success",
      });
    } else {
      dispatch({
        type: Types.USER_FORGOT_PASSWORD_FAILED,
        payload: {
          message: resp.message,
        },
      });
      toast.show({
        content: resp.data.error,
        variant: "danger",
      });
    }
    return resp;
  };
};
