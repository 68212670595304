import { Reducer } from "redux";

import Types from "../types/home";

const initialState = {
  loading: false,
  isError: false,
  message: "",
  data: {},
};

const HomeReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.USER_CONTACT_INFO_POST_STARTED:
      return {
        loading: true,
        isError: false,
        message: "",
      };
    case Types.USER_CONTACT_INFO_POST_SUCCESS:
      return {
        loading: false,
        isError: false,
        data: payload.data,
      };
    case Types.USER_CONTACT_INFO_POST_FAILED:
      return {
        loading: false,
        isError: true,
        message: payload.message,
      };

    default:
      return state;
  }
};

export default HomeReducer;
