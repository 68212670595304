import { createTypes } from "./createTypes";

export default createTypes(
  "FETCH_SUBSCRIPTION_PLAN_STARTED",
  "FETCH_SUBSCRIPTION_PLAN_SUCCESS",
  "FETCH_SUBSCRIPTION_PLAN_FAILED",
  "SUBSCRIPTION_PLAN_CHECKOUT_STARTED",
  "SUBSCRIPTION_PLAN_CHECKOUT_SUCCESS",
  "SUBSCRIPTION_PLAN_CHECKOUT_FAILED",
  "UPDATE_SUBSCRIPTION_STARTED",
  "UPDATE_SUBSCRIPTION_SUCCESS",
  "UPDATE_SUBSCRIPTION_FAILED",
  "CANCEL_SUBSCRIPTION_STARTED",
  "CANCEL_SUBSCRIPTION_SUCCESS",
  "CANCEL_SUBSCRIPTION_FAILED"
);
