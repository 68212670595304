/* eslint-disable react/no-multi-comp */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { Row, useTable } from "react-table";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Button from "../Button";
import Input from "../Input";
import Loader from "../Loader";
import Typography from "../Typography";
import Pagination from "./Pagination";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import classes from "./styles.module.scss";
import { Props, SearchProps } from "./types";

const Search = (
  props: SearchProps & { handleSearchTextChange: (text: string) => void }
) => {
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    // persisting event as we will make a call after 500ms
    e.persist();
    delayedSearch(e);
  };

  const searchInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    // actual call to the search will be made from here
    props.handleSearchTextChange(e.target.value);
    props.onSearch(e.target.value);
  };

  // debouncing search function so that we don't make a call on each key down event
  const delayedSearch = debounce(searchInputChanged, 500);
  return (
    <Input
      leftIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
      inputGroupClassName={classes.searchInput}
      onChange={handleSearch}
      placeholder={props.searchPlaceholder}
    />
  );
};

const Table: React.FC<Props> = ({
  columns,
  data = [],
  loading = false,
  tableHeaderText = "",
  showHeaderButton = false,
  showHeaderButtonText = "See all",
  handleSort,
  noDataIndication = (
    <div className={cx(classes.noData, "d-flex  justify-content-center py-4")}>
      No record to show
    </div>
  ),
  noSearchResults = (
    <div className={cx(classes.noData, "d-flex  justify-content-center py-4")}>
      No Results Found
    </div>
  ),
  handleRowClick,
  handleHeaderButton,
  tableHeaderClassname = "",
  showPagination = false,
  search = false,
  searchProps = {
    onSearch: () => {},
    searchPlaceholder: "",
  },
  onPageChange,
  paginationOption,
  headerDropdown = false,
  handleDropdownClick,
  headerDropdownOptions = [],
}) => {
  const {
    from = 1,
    to = 10,
    currentPage = 1,
    totalItems = 0,
    itemPerPage = 10,
  } = paginationOption || {};

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });
  const [pageIndex, setPageIndex] = useState(currentPage);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (onPageChange) {
      onPageChange(pageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  const handlePageChange = (
    pageNumber: number | ((pageIndex: number) => number)
  ) => {
    setPageIndex(pageNumber);
  };

  const handleSearchTextChange = (text: string) => setSearchText(text);

  const onNextHandler = () => {
    setPageIndex(pageIndex + 1);
  };

  const onPreviousHandler = () => {
    setPageIndex(pageIndex - 1);
  };

  return (
    <div className={classes.wrapper}>
      {tableHeaderText && (
        <div className={classes.tableHeader}>
          <div className={classes.tableHeading}>
            <Typography className="mb-0" variant="p24" fontWeight="semibold">
              {tableHeaderText}
            </Typography>
            {showHeaderButton && (
              <div className={classes.seeAll}>
                <Button
                  onClick={handleHeaderButton}
                  buttonText={showHeaderButtonText}
                  size="small"
                />
              </div>
            )}
            {headerDropdown && (
              <UncontrolledDropdown className={classes.dropDownStyling}>
                <DropdownToggle className={classes.dropdownToggle}>
                  <Button
                    size="sm"
                    buttonText={showHeaderButtonText}
                    buttonClassName={classes.btn}
                  />
                </DropdownToggle>
                <DropdownMenu className={classes.dropdownMenu}>
                  {headerDropdownOptions.map((item, index) => {
                    return (
                      <DropdownItem
                        className={classes.dropdownItem}
                        key={index}
                        onClick={() => {
                          if (handleDropdownClick)
                            handleDropdownClick(item.value);
                        }}
                        active={false}
                      >
                        {item.label}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
          {search && (
            <div className={classes.search}>
              <Search
                handleSearchTextChange={handleSearchTextChange}
                {...searchProps}
              />
            </div>
          )}
        </div>
      )}
      <div className={classes.tableWrapper}>
        <table {...getTableProps()} rules="none">
          <thead
            className={cx(classes.cursorPointer, {
              [tableHeaderClassname]: tableHeaderClassname,
            })}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, index) => (
                  <TableHeader
                    key={index}
                    column={column}
                    index={index}
                    handleSort={handleSort}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className={cx(classes.tableBody, {
              [classes.showEmpty]: loading || data.length === 0,
            })}
            {...getTableBodyProps()}
          >
            {loading ? (
              <Loader loaderClass={classes.loader} />
            ) : data?.length === 0 ? (
              search && searchText ? (
                noSearchResults
              ) : (
                noDataIndication
              )
            ) : (
              <>
                {rows.map((row: Row<any>, i: React.Key | null | undefined) => {
                  prepareRow(row);
                  return (
                    <TableBody
                      key={i}
                      row={row}
                      handleRowClick={(cell) => {
                        if (handleRowClick) {
                          return handleRowClick(cell);
                        }
                      }}
                    />
                  );
                })}
              </>
            )}
          </tbody>
        </table>

        {!!data.length && !loading && showPagination && (
          <Pagination
            onPreviousHandler={onPreviousHandler}
            onNextHandler={onNextHandler}
            handlePageChange={handlePageChange}
            pageSize={itemPerPage}
            pageIndex={currentPage}
            from={from}
            to={to}
            length={totalItems}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
