import React from "react";
import { Container } from "reactstrap";
import leadershipImg1 from "src/assets/images/leadership-team1.jpg";
import leadershipImg2 from "src/assets/images/leadership-team2.jpg";
import leadershipImg3 from "src/assets/images/leadership-team3.jpg";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";

const NTSMission = () => {
  return (
    <div id="nts-mission" className={classes.mainWrapper}>
      <Container>
        <div className={classes.missionWrapper}>
          <Typography className={classes.titleWrapper}>Our Mission</Typography>
          <Typography className={classes.subtitleWrapper}>
            A wise man said “Knowledge is power, sharing knowledge is the key to
            unlocking that power”.
          </Typography>
          <Typography variant="p20" className={classes.descriptionWrapper}>
            We believe in sharing knowledge and information for the benefit of
            property owners, contractors, engineers, insurers and adjusters. We
            aim to be the most reliable and reputable source of information when
            it comes to the identification and related documentation of roofing
            materials.
          </Typography>
          <Typography className={classes.teamTextWrapper}>
            NTS Leadership Team
          </Typography>
        </div>
        <div className={classes.CardWrapper}>
          <div className={classes.cardViewWrapper}>
            <Typography variant="p32" className={classes.cardTitleWrapper}>
              John Senac
            </Typography>
            <Typography className={classes.cardtextWrapper}>
              President
            </Typography>
            <img
              alt="nts-image"
              src={leadershipImg1}
              className={classes.cardImgWrapper}
            />
            <Typography variant="p14" className={classes.cardContentWrapper}>
              John Senac is a veteran of the roofing industry and is an expert
              on construction materials. John is a proven team leader and a
              seasoned trainer, disseminating knowledge and leading classes
              focused on training contractors and adjusters in the
              identification of roofing materials. John is passionate about his
              family and loves traveling and experiencing new places with his
              wife and five children.
            </Typography>
          </div>
          <div className={classes.cardViewWrapper}>
            <Typography variant="p32" className={classes.cardTitleWrapper}>
              TJ Ware
            </Typography>
            <Typography className={classes.cardtextWrapper}>CEO</Typography>
            <img
              alt="nts-image"
              src={leadershipImg2}
              className={classes.cardImgWrapper}
            />
            <Typography variant="p14" className={classes.cardContentWrapper}>
              TJ has led electrical contracting and restoration companies and
              has been in the insurance adjusting industry since 2017, serving
              property owners dealing with insurance claims. TJ is a board
              advisor for the American Policyholder Association (APA), a board
              advisor for the American Adjuster Association (AAA), and a board
              member for NTX SNAP. He is dedicated to serving others through his
              non profit work and is well known for his travels with his wife
              Jackie and their 10 children.
            </Typography>
          </div>
          <div className={classes.cardViewWrapper}>
            <Typography variant="p32" className={classes.cardTitleWrapper}>
              Brian
            </Typography>
            <Typography className={classes.cardtextWrapper}>COO</Typography>
            <img
              alt="nts-image"
              src={leadershipImg3}
              className={classes.cardImgWrapper}
            />
            <Typography variant="p14" className={classes.cardContentWrapper}>
              Brian has an extensive background in operations including energy
              production management, wholesale energy and electrical
              distribution. Brian excels in designing systems, processes and
              procedures and enjoys his role as a trainer. He has an
              entrepreneurial background and is very growth-minded. Brian enjoys
              spending time outdoors and boating with his wife and two sons.
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NTSMission;
