import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container } from "reactstrap";
import NTSMerchImg1 from "src/assets/images/nts-merch-1.jpg";
import NTSMerchImg2 from "src/assets/images/nts-merch-2.jpg";
import NTSMerchImg3 from "src/assets/images/nts-merch-3.jpg";
import NTSMerchImg4 from "src/assets/images/nts-merch-4.jpg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";

const NTSMerch = () => {
  return (
    <div className={classes.wrapper}>
      <Container>
        <div className={classes.contentWrapper}>
          <div className={classes.textWrapper}>
            <Typography variant="p48" className={classes.titleWrapper}>
              NTS Merch
            </Typography>
            <Typography variant="p20">
              Grab some NTS merchandise hot off the presses from our new store!
            </Typography>
            <a
              className="text-decoration-none"
              href="https://name-that-shingle.myshopify.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className={classes.linkWrapper}
                buttonText="Checkout the NTS Store"
                variant="link"
                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
              />
            </a>
          </div>
          <div className={classes.imgWrapper}>
            <div>
              <img
                src={NTSMerchImg1}
                className={classes.imgCls}
                alt="NTS_image"
              />
            </div>
            <div>
              <img
                src={NTSMerchImg2}
                className={classes.imgCls}
                alt="NTS_image"
              />
            </div>
            <div>
              <img
                src={NTSMerchImg3}
                className={classes.imgCls}
                alt="NTS_image"
              />
            </div>
            <div>
              <img
                src={NTSMerchImg4}
                className={classes.imgCls}
                alt="NTS_image"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NTSMerch;
