/* eslint-disable max-lines */
import React, { useState } from "react";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Typography from "src/components/Typography";
import { logoutUser } from "src/store/actions/auth";
import { postUserTerms } from "src/store/actions/user";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";

type Props = {
  isOpen: boolean;
  onToggle: () => void;
};

const TermsModal: React.FC<Props> = ({ isOpen, onToggle }) => {
  const [isActive, setIsActive] = useState(false);
  const actions = useStoreActions({ logoutUser, postUserTerms });
  const { loading } = useReducerData("user", "postUserTerms", {
    loading: false,
  });

  const handleReject = () => {
    actions.logoutUser();
    onToggle();
    window.location.href = "/auth/login";
  };

  const handleAccept = () => {
    const formData = new FormData();
    formData.append("terms_accepted", "1");
    actions.postUserTerms(formData);
    onToggle();
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      clientHeight = 0,
    } = e.currentTarget;
    const scrollBottom = scrollHeight - scrollTop < clientHeight + 16;
    if (scrollBottom) {
      setIsActive(true);
    }
  };

  return (
    <Modal
      className={classes.modalWrapper}
      childrenWrapperClassName={classes.wrapper}
      toggle={onToggle}
      backdrop="static"
      keyboard={false}
      isOpen={isOpen}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Typography className={classes.title}>
            Updated Terms & Conditions
          </Typography>
        </div>
        <div className={classes.modalBody}>
          <Typography className={classes.updatedTerms}>
            {!isActive
              ? "We've updated our Terms & Conditions. Please read through the following and click Accept Terms when at the bottom to continue using NTS."
              : "Please read through the following Terms & Conditions and agree to continue using NTS."}
          </Typography>

          <div className={classes.description} onScroll={handleScroll}>
            <Typography className={classes.title}>
              Terms & Conditions
            </Typography>
            <Typography className={classes.subText}>
              By utilizing this website to engage the services of NTSID, LLC
              ("NTSID," "Vendor," or "Us"), You the Customer hereby acknowledges
              that You are entering a binding agreement with Us (the
              "Agreement"); that the following terms and conditions comprise the
              Agreement; that these terms and conditions have been read by You;
              and that they shall be binding on the parties and shall govern the
              rights and obligations of both You and NTSID, to wit:
            </Typography>

            <Typography className={classes.subTitle}>
              Acceptance of Contract:
            </Typography>
            <Typography className={classes.subText}>
              Acceptance of this Agreement is limited to the terms stated
              herein. The terms of this Agreement are applicable solely on a
              "per transaction" basis. These terms and conditions are subject to
              change at any time in the sole discretion to NTSID and, once
              modified, shall be applicable to any future requests for services
              by Customer to NTSID.
            </Typography>
            <Typography className={classes.subTitle}>The Work:</Typography>
            <Typography className={classes.subText}>
              In exchange for the payment made by Customer as set forth by the
              payment section of the NTSID website, NTSID agrees to attempt,
              using commercially reasonable efforts (including but not limited
              to proprietary data, methods, or processes) to identify the
              provenance of the roofing material sample(s) provided by Customer
              and to provide Customer with a written report setting forth the
              findings of its analysis in NTSID's standard report format, as my
              be updated from time to time in NTSID's sole discretion.
            </Typography>
            <Typography className={classes.subTitle}>
              Disclaimers & Indemnity:
            </Typography>
            <ol className={classes.disclaimerList}>
              <li>
                <Typography className={classes.subText}>
                  The accuracy of NTSID's identification of any sample provided
                  by Customer is dependent on information, materials, and/or
                  samples provided by Customer to NTSID. Customer further
                  acknowledges that NTSID is not responsible for and cannot
                  control (i) the condition of sample(s) or damage thereto
                  incurred prior to receipt by NTSID; (ii) any damage
                  attributable to any delivery service or common carrier
                  responsible for transporting the sample(s); (iii) natural
                  degradation of the sample(s) due to age, ordinary wear and
                  tear, or exposure to the elements; or (iv) other damage not
                  attributable to the conduct of NTSID that may impair NTSID's
                  ability to positively identify a sample. Any reports,
                  conclusions, identifications, or analyses provided under this
                  Agreement are intended solely for the informational use of
                  Customer.
                </Typography>
              </li>
              <li>
                <Typography className={classes.subText}>
                  Customer agrees that NTSID shall not be liable for claims,
                  causes of action, costs, damages (property, personal injury,
                  consequential, etc.), or any expenses (including attorney
                  fees) whatsoever attributable to Customer's use, reliance
                  upon, or dissemination of NTSID's reports, conclusions,
                  identifications, or analyses, including for the resolution of
                  legal claims by litigation, negotiation, or alternative
                  dispute resolution.
                </Typography>
              </li>
              <li>
                <Typography className={classes.subText}>
                  Customer agrees that NTSID shall not be liable for any claims,
                  causes of action, costs, damages (property, personal injury,
                  consequential, etc.), or any expenses (including attorney
                  fees) asserted by any third party whatsoever attributable to
                  the reports, conclusions, identifications, or analyses
                  provided by NTSID pursuant to this Agreement. This shall
                  include, but is not limited to the owner(s) of any real
                  property from which any roofing sample(s) analyzed by NTSID is
                  taken. Customer agrees to indemnify NTSID and hold NTSID
                  harmless to the greatest extent allowed by applicable law from
                  any such claims, causes of action, costs, damages, or expenses
                  (including attorney fees).
                </Typography>
              </li>
              <li>
                <Typography className={classes.subText}>
                  NTSID does not provide engineering or architectural services,
                  nor does NTSID make recommendations on suggested repairs or
                  improvements based upon its reports, conclusions,
                  identifications, or analyses.
                </Typography>
              </li>
              <li>
                <Typography className={classes.subText}>
                  All samples, photographs, documents, or other information
                  provided to NTSID by Customer pursuant to this Agreement
                  become the property of NTSID. NTSID makes no warranty or
                  representation as to the time it will retain such samples,
                  photographs, documents, or other information.
                </Typography>
              </li>
            </ol>
            <Typography className={classes.subTitle}>
              Fee Schedule for Additional Services Required of NTSID:
            </Typography>
            <Typography className={classes.subText}>
              Customer agrees that the fee it is paying to NTSID for its initial
              reports, conclusions, identifications, or analyses does not cover
              time or resources spent by NTSID for additional tasks relating to
              this Agreement such as responding to subpoenas, testifying
              (whether by deposition or in court), compiling or producing
              documents pursuant to subpoenas, court orders, or other legal
              process, or the time spent preparing for any of the above.
              Customer agrees to compensate NTSID at a rate of $350 per man-hour
              (billed in 0.1 hour increments) for time spent compiling and/or
              producing responses to subpoenas or other requests for production
              of documents by Customer or its agents. Customer further agrees to
              compensate NTSID at a rate of $500 per man-hour (billed in 0.1
              hour increments) for testimony, whether by deposition or in court.
              Customer further agrees to pay reasonable travel costs and outside
              vendor costs actually incurred, if required to comply with such a
              requests. The above fees are applicable regardless of whether such
              request originates with Customer or an adverse party.
            </Typography>
            <Typography className={classes.subTitle}>
              Notice of Third Party Disputes:
            </Typography>
            <Typography className={classes.subText}>
              Customer shall promptly notify NTSID should it determine that the
              veracity of any of NTSID's conclusions are in dispute by an
              adverse party to any litigation, or if litigation appears imminent
              over a dispute in which NTSID's conclusions would be at issue.
            </Typography>
            <Typography className={classes.subTitle}>
              Dispute Resolution:
            </Typography>
            <Typography className={classes.subText}>
              In the event there is a dispute or claim made against NTSID by
              Customer relating to this Agreement or any work performed or
              contemplated herein, NTSID may at its election demand the dispute
              be referred to mediation and, if unsettled by mediation, to
              binding arbitration using Construction Industry Arbitration Rules
              of the American Arbitration Association. Any such proceedings
              shall solely and exclusively be conducted in Denton County, Texas.
              This Agreement may be pled in bar of any litigation instituted by
              Customer against NTSID for any claims arising under this
              Agreement. However, should litigation be commenced Customer
              stipulates and agrees that the state courts of Denton County,
              Texas shall be the sole and exclusive forum for any such disputes
              not arbitrated, and hereby submits itself to the personal
              jurisdiction of the State of Texas for purposes of any such
              litigation.
            </Typography>
            <Typography className={classes.subTitle}>Legal Costs:</Typography>
            <Typography className={classes.subText}>
              In the event of any suit, action, proceeding, or arbitration
              brought to enforce any term of this Agreement, or to redress a
              material breach thereof, the prevailing party at the conclusion of
              such proceeding shall be entitled to recover from the losing party
              any and all reasonable costs including attorney fees incurred by
              the prevailing party in the prosecution and/or defense of such
              suit, action, proceeding, or arbitration.
            </Typography>
            <Typography className={classes.subTitle}>Force Majeure:</Typography>
            <Typography className={classes.subText}>
              In no event shall NTSID be responsible or liable for any failure
              or delay in the performance of its obligations hereunder arising
              out of or caused by, directly or indirectly, forces beyond its
              control, including, without limitation, strikes, epidemic disease
              outbreaks, work stoppages, accidents, acts of war or terrorism,
              civil or military disturbances, nuclear or natural catastrophes or
              acts of God, loss or malfunctions of utilities, communications or
              computer (software and hardware) services, or interruptions to
              essential supply chains. Upon the occurrence of a "force majeure"
              event, NTSID shall use reasonable efforts which are consistent
              with accepted practices in the roofing industry to resume
              performance as soon as practicable under the circumstances;
              however, if such efforts are impractical and remain so for a
              period of at least 30 days, NTSID may without penalty terminate
              the agreement reflected by this Agreement and shall have no
              further obligation to Customer respecting completion of the Work.
            </Typography>
            <Typography className={classes.subTitle}>
              Entire Agreement:
            </Typography>
            <Typography className={classes.subText}>
              These terms and conditions, together the terms for Customer's
              payment set forth by the NTSID website, shall constitute the
              entire agreement between the parties. The terms and conditions
              that govern the provision of the goods or services described in
              this Agreement are limited to the terms and conditions specified
              herein, and formation of any contract is expressly made
              conditional on Customer's assent to these terms. These terms and
              conditions supersede all prior discussions, Agreements,
              representations, and agreements. NTSID will not be bound by any
              additional, different, or inconsistent terms in acknowledgements,
              invoices, or other documents from Customer or by any course of
              conduct between the parties.
            </Typography>
            <Typography className={classes.subTitle}>Governing Law:</Typography>
            <Typography className={classes.subText}>
              This Agreement shall be construed and interpreted in accordance
              with the laws of the State of Texas.
            </Typography>
            <Typography className={classes.subTitle}>
              Authority and Reliance:
            </Typography>
            <Typography className={classes.subText}>
              NTSID and Customer each warrant and represent that: (i) they have
              relied upon their own judgment regarding the consideration for and
              language of this Agreement; (ii) this Agreement is written in a
              manner that is understandable that they have read all of the
              paragraphs of this Agreement. Each party further acknowledges that
              they are entering into this Agreement freely, knowingly,
              voluntarily, with a full understanding of its terms, and with the
              intent to form a binding contract. Each party warrants and
              represents that the party is not relying on counsel for any other
              party for the performance of any task, provision of any service or
              rendering of any advice for any purpose whatsoever, but instead is
              relying solely and exclusively on the party's own counsel for all
              matters relating to the terms of this Agreement.
            </Typography>
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            buttonText="Accept Terms"
            buttonColor="success"
            onClick={handleAccept}
            disabled={!isActive || loading}
          />
          <Button
            buttonText="Reject Terms"
            buttonColor="danger"
            onClick={handleReject}
            disabled={!isActive || loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TermsModal;
