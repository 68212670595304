import get from "lodash/get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Cell } from "react-table";
import { Params } from "src/api/orders";
import Table from "src/components/Table";
import Typography from "src/components/Typography";
import { column } from "src/pages/Dashboard/constants";
import {
  postActiveCheckoutId,
  removeCheckoutDetails,
} from "src/store/actions/checkout";
import { getAllOrders } from "src/store/actions/order";
import { getSubscriptionPlans } from "src/store/actions/subscription";
import { getCurrentUser } from "src/store/actions/user";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { Reports } from "../OrderCreate/Components/SelectReport/types";
import { PlanProps } from "../Plans/types";
import OrderCTA from "./Components/OrderCTA";
import SubscriptionCard from "./Components/SubscriptionCard";
import TermsModal from "./Components/TermsModal";
import classes from "./styles.module.scss";

const DashBoard = () => {
  const navigate = useNavigate();
  const { data: user, loading: userLoading = false } = useReducerData(
    "user",
    "user",
    {}
  );

  const [sortState, setSortState] = useState<Params>({});
  const [isOpen, setIsOpen] = useState(false);
  const { data: plans } = useReducerData("plans", "subscriptionPlans", {
    data: [],
  });
  const isTermsAccepted = get(user, "terms_accepted", 0) === 1;
  const isPartner = get(user, "is_partner", 0) === 1;

  const { orders, loading } = useReducerData("order", "allOrders", {});
  const actions = useStoreActions({
    getAllOrders,
    removeCheckoutDetails,
    getCurrentUser,
    postActiveCheckoutId,
    getSubscriptionPlans,
  });
  const { data = [] } = orders;
  const { first_name = "" } = user;
  const [hasOrder, setHasOrder] = useState(false);

  useEffect(() => {
    actions.getCurrentUser();
    actions.getSubscriptionPlans();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isTermsAccepted && !userLoading && user?.id) {
      setIsOpen(true);
    }
  }, [user, userLoading, isTermsAccepted]);

  useEffect(() => {
    const fetchOrders = async () => {
      await actions.getAllOrders(sortState);
    };
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortState]);

  useEffect(() => {
    setHasOrder(orders?.data?.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  const handleSort = (id: string) => {
    setSortState({
      ...sortState,
      sort_by: id,
      sort_order: sortState.sort_order === "asc" ? "desc" : "asc",
    });
  };

  const handleSearch = (searchText: string) => {
    const params = {
      ...sortState,
      keyword: searchText,
    };
    setSortState(params);
    actions.getAllOrders(params);
  };
  const handleButtonClick = () => {
    actions.removeCheckoutDetails();
    navigate("/order/create/select-report");
  };

  const handleSubscriptionButton = () => {
    navigate("/plans");
  };

  const getUpdatedUrlBasedOnReportType = (
    url: string,
    reportType: "Shingle ID Report" | "Siding ID Report"
  ) => {
    const report =
      reportType === "Shingle ID Report" ? Reports.Shingle : Reports.Siding;
    navigate(`${url}?report=${report}`);
  };

  const handleTableRowClick = (cell: Cell<any>) => {
    if (cell.row.original.status === "Draft") {
      const reportType = cell.row.original?.report_type || "Shingle ID Report";
      actions.postActiveCheckoutId(cell.row.original.id);
      switch (cell.row.original?.step_on) {
        case 1:
          return getUpdatedUrlBasedOnReportType(
            "/order/create/property",
            reportType
          );

        case 2:
          return getUpdatedUrlBasedOnReportType(
            "/order/create/contact",
            reportType
          );

        case 3:
          return getUpdatedUrlBasedOnReportType(
            "/order/create/checkout",
            reportType
          );

        default:
          return getUpdatedUrlBasedOnReportType(
            "/order/create/property",
            reportType
          );
      }
    } else {
      navigate(`/orders/${cell.row.original.id}`, {
        state: {
          id: cell.row.original.id,
          status: cell.row.original?.status,
        },
      });
    }
  };

  const handleHeaderButton = () => {
    navigate("/orders");
  };

  const getTimeLeft = () => {
    const expiryDate = moment(user?.subscription?.ends_at);
    const expiryDay = expiryDate.date();
    const expiryYear = expiryDate.year();
    const todaysDate = moment();
    const daysLeft = expiryDate.diff(todaysDate, "days");
    const month = moment(user?.subscription?.ends_at).format("MMM");

    return `${daysLeft} days remaining (renews ${month} ${expiryDay}, ${expiryYear})`;
  };

  const getReportsPerCreditsDetails = () => {
    if (user?.subscription?.credits > 0) {
      const reportPerCredits = user?.subscription?.credits;
      return `You have ${reportPerCredits} report credits remaining`;
    } else {
      const findCurrentPlanIndex = plans?.findIndex(
        (plan: PlanProps) => plan?.price_id === user?.subscription?.price_id
      );
      let nextPlan;
      if (findCurrentPlanIndex === 0) {
        nextPlan = plans[plans.length - 1];
      } else {
        nextPlan = plans[findCurrentPlanIndex - 1];
      }

      const name = <span className="text-lowercase">{nextPlan?.name}</span>;
      return (
        <>
          {name} pricing active (${nextPlan?.amount_per_reports} per report)
        </>
      );
    }
  };

  const onToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div>
      <div className={classes.introText}>
        <Typography variant="p20" fontWeight="semibold">
          Welcome, {first_name}!
        </Typography>
      </div>
      {!isPartner && (
        <div className={classes.buttonWrapper}>
          {user?.subscription?.status === "active" ? (
            <SubscriptionCard
              headerText={
                <Typography
                  variant="p13"
                  fontWeight="semibold"
                  className={classes.headerText}
                >
                  Your{" "}
                  <span className={classes.subscriptionName}>
                    {user?.subscription?.name.toLowerCase()}
                  </span>{" "}
                  subscription is active!
                </Typography>
              }
              title={getReportsPerCreditsDetails()}
              footerText={getTimeLeft()}
              handleButtonClick={handleSubscriptionButton}
            />
          ) : (
            <>
              <OrderCTA
                handleButtonClick={handleButtonClick}
                hasOrder={hasOrder}
              />
              <SubscriptionCard handleButtonClick={handleSubscriptionButton} />
            </>
          )}
        </div>
      )}
      <Table
        search
        searchProps={{
          onSearch: handleSearch,
          searchPlaceholder: "Search order",
        }}
        loading={loading}
        handleSort={handleSort}
        data={data}
        columns={column}
        handleRowClick={handleTableRowClick}
        tableHeaderText="Orders"
        showHeaderButton
        handleHeaderButton={handleHeaderButton}
        noDataIndication={
          <div className="d-flex  justify-content-center py-4 w-100 position-absolute">
            No orders to display
          </div>
        }
      />
      {isOpen && <TermsModal isOpen={isOpen} onToggle={onToggle} />}
    </div>
  );
};

export default DashBoard;
