import cx from "classnames";
import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import classes from "./styles.module.scss";
import { Props } from "./types";

const RadioInput: React.FC<Props> = ({
  options = [],
  selectedOption,
  onChange,
  inputGroupClassName = "",
}) => {
  return (
    <FormGroup tag="div" className={cx(classes.wrapper, inputGroupClassName)}>
      {options.map((option, index) => (
        <Label className={classes.label} key={index}>
          <Input
            className={classes.input}
            type="radio"
            name="radioOption"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={onChange}
          />
          {option.label}
        </Label>
      ))}
    </FormGroup>
  );
};

export default RadioInput;
