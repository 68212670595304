import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/Input";
import { toast } from "src/components/Toast/ToastManager";
import { forgotPassword } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";
import { forgotPasswordProps } from "./types";

const DefaultForgotPassword = () => {
  const navigate = useNavigate();
  const actions = useStoreActions({ forgotPassword });
  const { loading } = useReducerData("auth", "passwordReset", {
    loading: false,
  });

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Must be valid email addresses"),
  });

  const handleSubmit = async (
    values: forgotPasswordProps,
    formikHelpers: FormikHelpers<forgotPasswordProps>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status, data } = await actions.forgotPassword(values.email);

    if (status) {
      formikHelpers.resetForm();
      navigate("/auth/forgot-password/check-email");
    } else {
      toast.show({
        content: data.error,
        variant: "danger",
      });
    }
  };

  return (
    <Card
      cardClassName={classes.card}
      cardTitle="Enter your email address below"
      cardFooterContent={
        <div
          className={cx(
            classes.footer,
            "d-flex gap-1 justify-content-center flex-column"
          )}
        >
          <p className="mb-0">Don’t have an account yet?</p>
          <div className={classes.link}>
            <Button
              onClick={() => navigate("/auth/signup")}
              buttonText="Create an account"
              variant="link"
            />
          </div>
        </div>
      }
    >
      <Formik
        onSubmit={(values, formikHelpers) =>
          handleSubmit(values, formikHelpers)
        }
        validationSchema={forgotPasswordSchema}
        initialValues={{
          email: "",
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          dirty,
        }: FormikProps<forgotPasswordProps>) => (
          <Form
            className={cx(classes.forgotPasswordInput, "d-flex flex-column")}
          >
            <FormGroup>
              <Input
                value={values.email}
                placeholder="Email address"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                name="email"
                leftIcon={<i className="fa fa-solid fa-envelope" />}
              />
            </FormGroup>
            <div className={classes.submitButtonWrapper}>
              <Button
                disabled={!isValid || !dirty}
                buttonClassName={classes.submitButton}
                type="submit"
                buttonText="Submit"
                loading={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default DefaultForgotPassword;
