import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import Logo from "src/assets/icons/logo.svg";
import CheckEmail from "./CheckEmail";
import DefaultSignUp from "./DefaultSignUp";
import classes from "./styles.module.scss";

const SignupPage = () => {
  const [tab, setTab] = useState<string>();
  const { params = "" } = useParams();
  const isCheckEmailPage = params?.includes("check-email");

  useEffect(() => {
    setTab(params);
  }, [params]);

  const renderSignUpCard = () => {
    switch (tab) {
      case "check-email":
        return <CheckEmail />;
      default:
        return <DefaultSignUp />;
    }
  };

  return (
    <Container className={classes.container}>
      <div
        className={cx(
          classes.signupHeader,
          "d-flex flex-column  align-items-center justify-content-center",
          { [classes.checkEmailPage]: isCheckEmailPage }
        )}
      >
        <Link to={"/auth/login"}>
          <img alt="logo" src={Logo} />
        </Link>
        {!isCheckEmailPage && (
          <div className={classes.title}>
            <p>Welcome! Let’s get your NTS user account created </p>
          </div>
        )}
      </div>
      {renderSignUpCard()}
    </Container>
  );
};

export default SignupPage;
