import React from "react";
import ConfirmIcon from "src/assets/icons/confirm-icon.svg";
import Card from "src/components/Card";
import classes from "./styles.module.scss";

const CheckEmail = () => {
  return (
    <Card cardClassName={classes.confirmWrapper}>
      <div className={classes.icon}>
        <img src={ConfirmIcon} alt="check-icon" />
      </div>
      <div className={classes.content}>
        <div className={classes.title}>
          <h3 className="mb-0">Your account was created!</h3>
          <h3 className="fw-bold">Please confirm your email address</h3>
        </div>
        <div className={classes.details}>
          <p>
            Check your inbox for an email from info@ntsid.com and click the{" "}
            <span>Confirm my email address</span> link. If you haven’t received
            the email within 10 minutes, you can email{" "}
            <a href="#">info@ntsid.com</a> for assistance.
          </p>
        </div>
      </div>
    </Card>
  );
};

export default CheckEmail;
