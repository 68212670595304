import cx from "classnames";
import React from "react";
import Typography from "../Typography";
import classes from "./styles.module.scss";
type Props = {
  children: React.ReactNode;
  variant?: string;
};

const OrderStatus: React.FC<Props> = ({ children, variant = "" }) => {
  const getClassname = () => {
    switch (variant) {
      case "Awaiting Sample":
        return "awaiting_sample";
      case "Shipment Received":
        return "shipment_received";
      case "Lab Data Entry":
        return "lab_data_entry";
      case "Pending ID":
        return "pending_id";
      case "Pending Approval":
        return "pending_approval";
      case "Executive Review":
        return "executive_review";
      case "Manager Approved":
        return "manager_approved";
      case "Pending Report":
        return "pending_report";
      case "Color Analysis":
        return "color_analysis";
      case "Complete":
        return "complete";
      case "Refunded":
        return "refunded";
      case "Processing":
        return "processing";
      case "Draft":
        return "draft";
      default:
        return "awaiting_sample";
    }
  };

  return (
    <div className={cx(classes.defaultStatus, classes[getClassname()])}>
      <Typography className="mb-0" variant="p14">
        {children}
      </Typography>
    </div>
  );
};

export default OrderStatus;
