import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import Button from "src/components/Button";
import UserDropdown from "src/components/UserDropdown";
import { removeCheckoutDetails } from "src/store/actions/checkout";
import { getCurrentUser } from "src/store/actions/user";
import { useReducerData, useStoreActions } from "src/store/hooks";

import classes from "./styles.module.scss";
import { Props } from "./types";

const Header: React.FC<Props> = ({ handleSidebarToggle }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: user } = useReducerData("user", "user", {});
  const actions = useStoreActions({
    getCurrentUser,
    removeCheckoutDetails,
  });

  const isDashboard = pathname.includes("dashboard");

  useEffect(() => {
    actions.getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrderReport = () => {
    actions.removeCheckoutDetails();
    navigate("/order/create/select-report");
  };

  return (
    <div className={classes.header}>
      <div className={classes.headerItem}>
        <div className={classes.burger} onClick={handleSidebarToggle}>
          <i className="fa fa-bars" />
        </div>
        {!isDashboard ||
          (user?.subscription?.status === "active" && (
            <Button
              buttonText="Order a Report"
              variant="fill"
              buttonColor="secondary"
              onClick={handleOrderReport}
              rightIcon={
                <i
                  className="fa fa-arrow-circle-o-right"
                  color="#2DCE89"
                  aria-hidden="true"
                ></i>
              }
            />
          ))}
      </div>
      <UserDropdown />
    </div>
  );
};

export default Header;
