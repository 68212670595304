import { createTypes } from "./createTypes";

export default createTypes(
  "CHECKOUT_STEP_ONE_STARTED",
  "CHECKOUT_STEP_ONE_SUCCESS",
  "CHECKOUT_STEP_ONE_FAILED",
  "CHECKOUT_STEP_ONE_UPDATE_STARTED",
  "CHECKOUT_STEP_ONE_UPDATE_SUCCESS",
  "CHECKOUT_STEP_ONE_UPDATE_FAILED",
  "CHECKOUT_STEP_TWO_STARTED",
  "CHECKOUT_STEP_TWO_SUCCESS",
  "CHECKOUT_STEP_TWO_FAILED",
  "CHECKOUT_STEP_THREE_STARTED",
  "CHECKOUT_STEP_THREE_SUCCESS",
  "CHECKOUT_STEP_THREE_FAILED",
  "INSURANCE_COMPANY_LIST_STARTED",
  "INSURANCE_COMPANY_LIST_SUCCESS",
  "INSURANCE_COMPANY_LIST_FAILED",
  "SIDING_MATERIAL_TYPES_STARTED",
  "SIDING_MATERIAL_TYPES_SUCCESS",
  "SIDING_MATERIAL_TYPES_FAILED",
  "PROMO_CODE_VALIDATION_STARTED",
  "PROMO_CODE_VALIDATION_SUCCESS",
  "PROMO_CODE_VALIDATION_FAILED",
  "GET_PRODUCTS_STARTED",
  "GET_PRODUCTS_SUCCESS",
  "GET_PRODUCTS_FAILED",
  "REMOVE_CHECKOUT_DETAILS",
  "ACTIVE_CHECKOUT_ID"
);
