import { Request } from "./request";

export type Params = {
  sort_by?: string;
  sort_order?: "asc" | "desc";
  page?: number;
};

export const getAllOrders = async (params: Params) => {
  return Request.call({
    url: "/api/orders",
    method: "GET",
    params: params,
  });
};

export const getOrderById = async (id: string) => {
  return Request.call({
    url: `/api/orders/${id}`,
    method: "GET",
  });
};

export const getShippingLabel = async (id: number) => {
  return Request.call(
    {
      url: `/api/orders/${id}/download_shipping_label`,
      method: "GET",
      responseType: "blob",
    },
    true
  );
};

export const getReportFile = async (
  orderId: number,
  isForPreview?: boolean
) => {
  return Request.call(
    {
      url: `api/orders/${orderId}/report_file${isForPreview ? "?view=1" : ""}`,
      method: "GET",
      responseType: "blob",
    },
    true
  );
};
