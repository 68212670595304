import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import Logo from "src/assets/icons/logo.svg";
import Button from "src/components/Button";
import { isLoggedIn } from "src/utils/utils";
import { navItems } from "./NavItems";
import classes from "./styles.module.scss";

type Props = {
  handleSidebarToggle: () => void;
  handleScrollChange: (id: string) => void;
};

const Header: React.FC<Props> = ({
  handleSidebarToggle,
  handleScrollChange,
}) => {
  const navigate = useNavigate();
  const isLogin = isLoggedIn();

  const handleRedirect = () => {
    navigate("/auth/login");
  };

  return (
    <div className={cx(classes.container)}>
      <Container
        className={cx(
          classes.desktopContainer,
          "d-flex justify-content-between align-items-center"
        )}
      >
        <div className={classes.logoWrapper} onClick={handleRedirect}>
          <img alt="logo" src={Logo} />
        </div>
        <div className={classes.tabsWrapper}>
          {navItems.map(({ name, id }, index) => {
            return (
              <div key={index}>
                {id === "nts-merch" ? (
                  <a
                    className={classes.links}
                    target="_blank"
                    href="https://name-that-shingle.myshopify.com/"
                    rel="noreferrer"
                  >
                    {name}
                  </a>
                ) : (
                  <Link
                    onClick={() => handleScrollChange(id)}
                    className={classes.links}
                    to={"/"}
                  >
                    {name}
                  </Link>
                )}
              </div>
            );
          })}
          <div className={classes.socialLinks}>
            <a
              target="_blank"
              href="https://www.facebook.com/groups/namethatshinglerenamed"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCENVeMu0HnqPUBUoUYgkBpw"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
        <div
          className={cx(
            classes.buttonsWrapper,
            "d-flex justify-content-between align-items-center"
          )}
        >
          {!isLogin ? (
            <Button
              onClick={() => navigate("/auth/login")}
              variant="outline"
              buttonText="Log In"
            />
          ) : (
            <Button
              onClick={() => navigate("/dashboard")}
              variant="outline"
              buttonText="Dashboard"
            />
          )}
          <Button
            buttonClassName={classes.orderButton}
            buttonText="Order a Report"
            onClick={() => navigate("/order/create/select-report")}
            rightIcon={<i className="fa fa-arrow-circle-right" />}
          />
        </div>
      </Container>
      <div className={classes.homePageMobileHeader}>
        <div onClick={handleSidebarToggle} className={classes.hamburger}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <div>
          <Link to={"/"}>
            <img alt="logo" src={Logo} />
          </Link>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Header;
