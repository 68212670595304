import cx from "classnames";
import React from "react";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Typography: React.FC<Props> = ({
  variant = "p24",
  fontWeight = "regular",
  children,
  className = "",
}) => {
  const getTypography = () => {
    switch (variant) {
      case "h1":
        return (
          <h1
            className={cx(classes[variant], classes[fontWeight], {
              [className]: className,
            })}
          >
            {children}
          </h1>
        );
      case "h2":
        return (
          <h2
            className={cx(classes[variant], classes[fontWeight], {
              [className]: className,
            })}
          >
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3
            className={cx(classes[variant], classes[fontWeight], {
              [className]: className,
            })}
          >
            {children}
          </h3>
        );
      case "h4":
        return (
          <h4
            className={cx(classes[variant], classes[fontWeight], {
              [className]: className,
            })}
          >
            {children}
          </h4>
        );
      case "h5":
        return (
          <h5
            className={cx(classes[variant], classes[fontWeight], {
              [className]: className,
            })}
          >
            {children}
          </h5>
        );
      case "h6":
        return (
          <h6
            className={cx(classes[variant], classes[fontWeight], {
              [className]: className,
            })}
          >
            {children}
          </h6>
        );
      default:
        return (
          <p
            className={cx(classes[variant], classes[fontWeight], {
              [className]: className,
            })}
          >
            {children}
          </p>
        );
    }
  };
  return getTypography();
};

export default Typography;
