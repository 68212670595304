import { Dispatch } from "redux";
import {
  ContactInfoProps,
  postUserContactInfo as postUserContactInfoApi,
} from "src/api/home";
import { toast } from "src/components/Toast/ToastManager";
import Types from "../types/home";

export const postUserContactInfo = (data: ContactInfoProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_CONTACT_INFO_POST_STARTED,
    });

    const resp = await postUserContactInfoApi(data);

    if (resp?.status === 1) {
      dispatch({
        type: Types.USER_CONTACT_INFO_POST_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        content: "Message has been sent",
        variant: "success",
      });
    } else {
      dispatch({
        type: Types.USER_CONTACT_INFO_POST_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};
