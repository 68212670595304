import React from "react";
import { useLocation } from "react-router";
import Header from "./Header";
import classes from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const isSignupPage = pathname.includes("signup");
  return (
    <div className={classes.wrapper}>
      {!isSignupPage && <Header pathname={pathname} />}
      {children}
    </div>
  );
};

export default AuthLayout;
