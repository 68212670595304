import { Reducer } from "redux";

import Types from "../types/checkout";

const initialState = {
  loading: false,
  isError: false,
  message: "",
  activeCheckoutId: null,
  insuranceCompanyList: [],
  sidingMaterialTypes: [],
  promoSuccessDetails: {
    loading: false,
    isError: false,
    data: { id: "", name: "", amount: 0 },
  },
  products: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
};

const CheckoutReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.CHECKOUT_STEP_ONE_STARTED:
      return {
        ...state,
        loading: true,
        isError: false,
        message: "",
      };
    case Types.CHECKOUT_STEP_ONE_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        message: "",
        activeCheckoutId: payload?.data?.id,
      };
    case Types.CHECKOUT_STEP_ONE_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        message: payload.message,
      };
    case Types.CHECKOUT_STEP_ONE_UPDATE_STARTED:
      return {
        ...state,
        loading: true,
        isError: false,
        message: "",
      };
    case Types.CHECKOUT_STEP_ONE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        message: "",
        activeCheckoutId: payload?.data?.id,
      };
    case Types.CHECKOUT_STEP_ONE_UPDATE_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        message: payload.message,
      };
    case Types.CHECKOUT_STEP_TWO_STARTED:
      return {
        ...state,
        loading: true,
        isError: false,
        message: "",
      };
    case Types.CHECKOUT_STEP_TWO_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        message: "",
        activeCheckoutId: payload?.data?.id,
      };
    case Types.CHECKOUT_STEP_TWO_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        message: payload.message,
      };
    case Types.CHECKOUT_STEP_THREE_STARTED:
      return {
        ...state,
        loading: true,
        isError: false,
        message: "",
      };
    case Types.CHECKOUT_STEP_THREE_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        message: "",
        activeCheckoutId: payload?.data?.id,
      };
    case Types.CHECKOUT_STEP_THREE_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        message: payload.message,
      };
    case Types.INSURANCE_COMPANY_LIST_STARTED:
      return {
        ...state,
        loading: true,
        isError: false,
        message: "",
      };
    case Types.INSURANCE_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        insuranceCompanyList: payload.data,
      };
    case Types.INSURANCE_COMPANY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        message: payload.message,
      };
    case Types.SIDING_MATERIAL_TYPES_STARTED:
      return {
        ...state,
        loading: true,
        isError: false,
        message: "",
      };
    case Types.SIDING_MATERIAL_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        sidingMaterialTypes: payload.data,
      };
    case Types.SIDING_MATERIAL_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        message: payload.message,
      };
    case Types.PROMO_CODE_VALIDATION_STARTED:
      return {
        ...state,
        promoSuccessDetails: {
          ...state.promoSuccessDetails,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.PROMO_CODE_VALIDATION_SUCCESS:
      return {
        ...state,
        promoSuccessDetails: {
          ...state.promoSuccessDetails,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.PROMO_CODE_VALIDATION_FAILED:
      return {
        ...state,
        promoSuccessDetails: {
          ...state.promoSuccessDetails,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.GET_PRODUCTS_STARTED:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
          isError: false,
        },
      };
    case Types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.GET_PRODUCTS_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.REMOVE_CHECKOUT_DETAILS:
      return initialState;
    default:
      return state;
    case Types.ACTIVE_CHECKOUT_ID:
      return {
        ...state,
        activeCheckoutId: payload.id,
      };
  }
};

export default CheckoutReducer;
