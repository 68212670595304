import { createTypes } from "./createTypes";

export default createTypes(
  "FETCH_ORDERS_STARTED",
  "FETCH_ORDERS_SUCCESS",
  "FETCH_ORDERS_FAILED",
  "FETCH_ORDER_BY_ID_STARTED",
  "FETCH_ORDER_BY_ID_SUCCESS",
  "FETCH_ORDER_BY_ID_FAILED",
  "FETCH_SHIPPING_LABEL_STARTED",
  "FETCH_SHIPPING_LABEL_SUCCESS",
  "FETCH_SHIPPING_LABEL_FAILED",
  "FETCH_REPORT_FILE_STARTED",
  "FETCH_REPORT_FILE_SUCCESS",
  "FETCH_REPORT_FILE_FAILED"
);
