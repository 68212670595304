import React, { useEffect, useState } from "react";
import Modal from "src/components/Modal";
import PreviewFile from "src/components/PreviewFile";
import { getReportFile } from "src/store/actions/order";
import { useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  orderId: number;
}
const PreviewFileModal: React.FC<Props> = ({ isOpen, onToggle, orderId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState("");
  const actions = useStoreActions({ getReportFile });

  useEffect(() => {
    getReportFileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReportFileData = async () => {
    if (isLoading || !orderId) return;
    setIsLoading(true);
    const response: any = await actions.getReportFile(orderId, true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url = window.URL.createObjectURL(new Blob([response.data]));
    setFileData(url);
    setIsLoading(false);
  };

  return (
    <Modal className={classes.modalWrapper} isOpen={isOpen} toggle={onToggle}>
      <div className={classes.closeIcon} onClick={onToggle}>
        <i className="fa fa-close" />
      </div>
      <PreviewFile
        fileUrl={fileData as string | Uint8Array}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default PreviewFileModal;
