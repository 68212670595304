import { Dispatch } from "redux";
import {
  CancelSubscriptionProps,
  SubscriptionSessionProps,
  UpdateSubscriptionEstimateProps,
  UpdateSubscriptionProps,
  cancelSubscription as cancelSubscriptionApi,
  getSubscriptionPlans as getSubscriptionPlansApi,
  getUpdateSubscriptionEstimate as getUpdateSubscriptionEstimateApi,
  postSubscriptionPlanCheckout as postSubscriptionPlanCheckoutApi,
  updateSubscription as updateSubscriptionApi,
} from "src/api/subscription";
import { toast } from "src/components/Toast/ToastManager";
import { UserSubscriptionType } from "src/pages/Plans/Components/PlansCard/types";
import { PlanProps } from "src/pages/Plans/types";
import Types from "../types/subscription";

export const getSubscriptionPlans = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_SUBSCRIPTION_PLAN_STARTED,
    });

    const resp = await getSubscriptionPlansApi();
    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_SUBSCRIPTION_PLAN_SUCCESS,
        payload: { data: resp?.data },
      });
    } else {
      dispatch({
        type: Types.FETCH_SUBSCRIPTION_PLAN_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const postSubscriptionPlanCheckout = (
  data: SubscriptionSessionProps
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.SUBSCRIPTION_PLAN_CHECKOUT_STARTED,
    });

    const resp = await postSubscriptionPlanCheckoutApi(data);

    if (resp?.status === 1) {
      dispatch({
        type: Types.SUBSCRIPTION_PLAN_CHECKOUT_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.SUBSCRIPTION_PLAN_CHECKOUT_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const updateSubscription = (
  data: UpdateSubscriptionProps,
  plan:
    | PlanProps
    | Pick<
        UserSubscriptionType,
        "next_renewal_plan" | "next_renewal_plan_id" | "scheduled_to_cancel"
      >
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_SUBSCRIPTION_STARTED,
    });

    const resp = await updateSubscriptionApi(data);

    if (resp?.status === 1) {
      dispatch({
        type: Types.UPDATE_SUBSCRIPTION_SUCCESS,
        payload: { data: resp, plan },
      });
      toast.show({
        content: resp?.message || "Subscription updated successfully!",
        variant: "success",
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.UPDATE_SUBSCRIPTION_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        content: resp?.message || "Something went wrong!",
        variant: "danger",
        position: "top-right",
      });
    }
    return resp;
  };
};

export const cancelSubscription = (
  params: CancelSubscriptionProps,
  plan: Pick<
    UserSubscriptionType,
    "scheduled_to_cancel" | "next_renewal_plan" | "next_renewal_plan_id"
  >
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.CANCEL_SUBSCRIPTION_STARTED,
    });

    const resp = await cancelSubscriptionApi(params);

    if (resp?.status === 1) {
      dispatch({
        type: Types.CANCEL_SUBSCRIPTION_SUCCESS,
        payload: { data: resp, plan },
      });
      toast.show({
        content: resp?.message || "Subscription cancelled successfully!",
        variant: "success",
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.CANCEL_SUBSCRIPTION_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        content: resp?.message || "Something went wrong!",
        variant: "danger",
        position: "top-right",
      });
    }
    return resp;
  };
};

export const getUpdateSubscriptionEstimate = (
  params: UpdateSubscriptionEstimateProps
) => {
  return async () => {
    const resp = await getUpdateSubscriptionEstimateApi(params);
    if (resp?.status === 1) {
      return resp;
    } else {
      toast.show({
        content: resp?.message || "Something went wrong!",
        variant: "danger",
        position: "top-right",
      });
    }
  };
};
