import { Request } from "./request";

export type PaymentMethodProps = {
  card_last_digit: string;
  expiry: string;
  id: number;
  name_on_card: string;
};

export type AdminPaymentProps = {
  token: string;
  name_on_card: string;
  card_last_digit: string;
  expiry: string;
};

export type UserProfile = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  payment_methods?: PaymentMethodProps[];
};

export type SupportProps = {
  subject: string;
  msg: string;
};

export type GetUserType = {
  user_id: string;
} | null;

export const getUser = async (params: GetUserType) => {
  return Request.call({
    url: "/api/user",
    method: "GET",
    params,
  });
};

export const updateUser = async (data: UserProfile) => {
  return Request.call({
    url: "/api/user",
    method: "PUT",
    data,
  });
};

export const removePaymentMethod = async (id: number) => {
  return Request.call({
    url: `/api/user/remove_payment_method/${id}`,
    method: "POST",
  });
};

export const postSupportRequest = async (data: SupportProps) => {
  return Request.call({
    url: `/api/support_request`,
    method: "POST",
    data,
  });
};

export const addPaymentMethod = async (data: AdminPaymentProps) => {
  return Request.call({
    url: `/api/user/save_payment_method`,
    method: "POST",
    data,
  });
};

export const postUserTerms = async (data: FormData) => {
  return Request.call({
    url: `/api/user/terms_and_conditions`,
    method: "POST",
    data,
  });
};
