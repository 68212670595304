/* eslint-disable max-lines */
import { Reducer } from "redux";

import Types from "../types/admin";

const initialState = {
  recentAdminOrders: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  allAdminOrders: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  updateAdminOrder: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  allCustomers: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  addCustomersCredit: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  orderStatuses: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  exportAllOrder: {
    loading: false,
    isError: false,
    message: "",
    exportOrder: [],
  },
  exportAllCustomers: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  uploadReport: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  emailReport: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  resendVerification: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  deleteCustomer: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  updateCustomer: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
};

const AdminReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.FETCH_RECENT_ADMIN_ORDERS_STARTED:
      return {
        ...state,
        recentAdminOrders: {
          ...state.recentAdminOrders,
          loading: true,
          isError: false,
        },
      };
    case Types.FETCH_RECENT_ADMIN_ORDERS_SUCCESS:
      return {
        ...state,
        recentAdminOrders: {
          ...state.recentAdminOrders,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.FETCH_RECENT_ADMIN_ORDERS_FAILED:
      return {
        ...state,
        recentAdminOrders: {
          ...state.recentAdminOrders,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.FETCH_ALL_ADMIN_ORDERS_STARTED:
      return {
        ...state,
        allAdminOrders: {
          ...state.allAdminOrders,
          loading: true,
          isError: false,
        },
      };
    case Types.FETCH_ALL_ADMIN_ORDERS_SUCCESS:
      return {
        ...state,
        allAdminOrders: {
          ...state.allAdminOrders,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.FETCH_ALL_ADMIN_ORDERS_FAILED:
      return {
        ...state,
        allAdminOrders: {
          ...state.allAdminOrders,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.UPDATE_ADMIN_ORDER_BY_ID_STARTED:
      return {
        ...state,
        updateAdminOrder: {
          ...state.updateAdminOrder,
          loading: true,
          isError: false,
        },
      };
    case Types.UPDATE_ADMIN_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        updateAdminOrder: {
          ...state.updateAdminOrder,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.UPDATE_ADMIN_ORDER_BY_ID_FAILED:
      return {
        ...state,
        updateAdminOrder: {
          ...state.updateAdminOrder,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.FETCH_ALL_CUSTOMERS_STARTED:
      return {
        ...state,
        allCustomers: {
          ...state.allCustomers,
          loading: true,
          isError: false,
        },
      };
    case Types.FETCH_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        allCustomers: {
          ...state.allCustomers,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.FETCH_ALL_CUSTOMERS_FAILED:
      return {
        ...state,
        allCustomers: {
          ...state.allCustomers,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.ADD_CUSTOMERS_CREDIT_STARTED:
      return {
        ...state,
        addCustomersCredit: {
          ...state.addCustomersCredit,
          loading: true,
          isError: false,
        },
      };
    case Types.ADD_CUSTOMERS_CREDIT_SUCCESS:
      return {
        ...state,
        addCustomersCredit: {
          ...state.addCustomersCredit,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.ADD_CUSTOMERS_CREDIT_FAILED:
      return {
        ...state,
        addCustomersCredit: {
          ...state.addCustomersCredit,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.FETCH_ORDER_STATUSES_STARTED:
      return {
        ...state,
        orderStatuses: {
          ...state.orderStatuses,
          loading: true,
          isError: false,
        },
      };
    case Types.FETCH_ORDER_STATUSES_SUCCESS:
      return {
        ...state,
        orderStatuses: {
          ...state.orderStatuses,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.FETCH_ORDER_STATUSES_FAILED:
      return {
        ...state,
        orderStatuses: {
          ...state.orderStatuses,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.EXPORT_ALL_ORDER_STARTED:
      return {
        ...state,
        exportAllOrder: {
          ...state.exportAllOrder,
          loading: true,
          isError: false,
        },
      };
    case Types.EXPORT_ALL_ORDER_SUCCESS:
      return {
        ...state,
        exportAllOrder: {
          ...state.exportAllOrder,
          exportOrder: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.EXPORT_ALL_ORDER_FAILED:
      return {
        ...state,
        exportAllOrder: {
          ...state.exportAllOrder,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.EXPORT_ALL_CUSTOMERS_STARTED:
      return {
        ...state,
        exportAllCustomers: {
          ...state.exportAllCustomers,
          loading: true,
          isError: false,
        },
      };
    case Types.EXPORT_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        exportAllCustomers: {
          ...state.exportAllCustomers,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.EXPORT_ALL_CUSTOMERS_FAILED:
      return {
        ...state,
        exportAllCustomers: {
          ...state.exportAllCustomers,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.UPLOAD_REPORT_STARTED:
      return {
        ...state,
        uploadReport: {
          ...state.uploadReport,
          loading: true,
          isError: false,
        },
      };
    case Types.UPLOAD_REPORT_SUCCESS:
      return {
        ...state,
        uploadReport: {
          ...state.uploadReport,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.UPLOAD_REPORT_FAILED:
      return {
        ...state,
        uploadReport: {
          ...state.uploadReport,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.EMAIL_REPORT_STARTED:
      return {
        ...state,
        emailReport: {
          ...state.emailReport,
          loading: true,
          isError: false,
        },
      };
    case Types.EMAIL_REPORT_SUCCESS:
      return {
        ...state,
        emailReport: {
          ...state.emailReport,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.EMAIL_REPORT_FAILED:
      return {
        ...state,
        emailReport: {
          ...state.emailReport,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.RESEND_VERIFICATION_EMAIL_STARTED:
      return {
        ...state,
        resendVerification: {
          ...state.resendVerification,
          loading: true,
          isError: false,
        },
      };
    case Types.RESEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        resendVerification: {
          ...state.resendVerification,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.RESEND_VERIFICATION_EMAIL_FAILED:
      return {
        ...state,
        resendVerification: {
          ...state.resendVerification,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.DELETE_CUSTOMER_STARTED:
      return {
        ...state,
        deleteCustomer: {
          ...state.deleteCustomer,
          loading: true,
          isError: false,
        },
      };
    case Types.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deleteCustomer: {
          ...state.deleteCustomer,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.DELETE_CUSTOMER_FAILED:
      return {
        ...state,
        deleteCustomer: {
          ...state.deleteCustomer,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.UPDATE_CUSTOMER_STARTED:
      return {
        ...state,
        updateCustomer: {
          ...state.updateCustomer,
          loading: true,
          isError: false,
        },
      };
    case Types.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateCustomer: {
          ...state.updateCustomer,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        updateCustomer: {
          ...state.updateCustomer,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default AdminReducer;
