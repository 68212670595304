import cx from "classnames";
import React from "react";
import { useNavigate } from "react-router";
import { Container, Nav, NavItem, NavLink } from "reactstrap";
import Logo from "src/assets/icons/logo.svg";
import Button from "src/components/Button";
import { navItems } from "./NavItems";
import classes from "./styles.module.scss";

interface Props {
  pathname: string;
}

const Header: React.FC<Props> = ({ pathname }) => {
  const navigate = useNavigate();
  const hideNavElements = !(
    pathname.includes("forgot-password") || pathname.includes("reset-password")
  );

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <Container
      fluid="xl"
      className={cx(
        classes.container,
        "d-flex justify-content-between align-items-center"
      )}
    >
      <div className={classes.logoWrapper} onClick={handleRedirect}>
        <img alt="logo" src={Logo} />
      </div>
      {hideNavElements && (
        <div className={classes.tabsWrapper}>
          <Nav>
            {navItems.map(({ name, id }, index) => (
              <NavItem key={index}>
                {id === "nts-merch" ? (
                  <NavLink
                    target="_blank"
                    href="https://name-that-shingle.myshopify.com/"
                  >
                    {name}
                  </NavLink>
                ) : (
                  <NavLink
                    onClick={() => {
                      navigate("/", {
                        state: {
                          id: id,
                        },
                      });
                    }}
                  >
                    {name}
                  </NavLink>
                )}
              </NavItem>
            ))}
          </Nav>
        </div>
      )}
      <div
        className={cx(
          classes.buttonsWrapper,
          "d-flex justify-content-between align-items-center"
        )}
      >
        {hideNavElements && <Button variant="outline" buttonText="Log In" />}
        <Button
          buttonText="Order a Report"
          onClick={() => navigate("/order/create/select-report")}
          rightIcon={<i className="fa fa-arrow-circle-right" />}
        />
      </div>
    </Container>
  );
};

export default Header;
