import cx from "classnames";
import React from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "src/components/Typography";
import { Reports } from "../SelectReport/types";
import classes from "./styles.module.scss";
import { Props } from "./types";

const MobileProgressBar: React.FC<Props> = ({ step, title }) => {
  const [searchParams] = useSearchParams();
  const report = searchParams.get("report");

  const renderHeaderTitle = () => {
    switch (report) {
      case "Siding":
        return `Order a ${Reports.Siding} ID Report`;
      case "Shingle":
        return `Order a ${Reports.Shingle} ID Report`;
      default:
        return "Order a Report";
    }
  };
  return (
    <div className={classes.progressBarMobile}>
      <Typography fontWeight="semibold" variant="p18">
        {renderHeaderTitle()}
      </Typography>
      <div className={classes.progressBar}>
        <div
          className={cx(classes.mobileBarWrapper, {
            [classes.active]: step === 1,
          })}
        >
          <div className={classes.bar}></div>
          <Typography className="mb-0 mt-1" fontWeight="semibold" variant="p10">
            Property information
          </Typography>
        </div>
        <div
          className={cx(classes.mobileBarWrapper, {
            [classes.active]: step === 2,
          })}
        >
          <div className={classes.bar}></div>
          <Typography className="mb-0 mt-1" fontWeight="semibold" variant="p10">
            Contact information
          </Typography>
        </div>
        <div
          className={cx(classes.mobileBarWrapper, {
            [classes.active]: step === 3,
          })}
        >
          <div className={classes.bar}></div>
          <Typography className="mb-0 mt-1" fontWeight="semibold" variant="p10">
            Shipping & payment
          </Typography>
        </div>
      </div>
      <Typography className="mb-0" fontWeight="bold" variant="p18">
        {step ? `Step ${step}:${title}` : "Select a Report Type"}
      </Typography>
    </div>
  );
};

export default MobileProgressBar;
