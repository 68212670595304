import React from "react";
import ReactDOM from "react-dom";
import Button from "../Button";
import { AlertOptions, AlertPopupProps, CustomAlertOptions } from "./types";
import AlertPopup from "./index";

export class ToastManager {
  private readonly containerRef: HTMLDivElement;
  private alerts: AlertPopupProps[] = [];

  constructor() {
    const body = document.getElementsByTagName("body")[0] as HTMLBodyElement;
    const alertContainer = document.createElement("div") as HTMLDivElement;
    alertContainer.id = "toast-container-main";
    body.insertAdjacentElement("beforeend", alertContainer);
    this.containerRef = alertContainer;
  }

  public show(options: AlertOptions): void {
    const toastId = Math.random().toString(36).substr(2, 9);
    const toast: AlertPopupProps = {
      id: toastId,
      ...options,
      onCancel: () => this.destroy(options.id ?? toastId),
      onConfirm: () => {
        options.onConfirm();
        this.destroy(options.id ?? toastId);
      },
      customButtons: options.customButtons,
    };

    this.alerts = [toast, ...this.alerts];
    this.render();
  }

  public showCustom(options: CustomAlertOptions): void {
    const toastId = Math.random().toString(36).substr(2, 9);
    const toast: AlertPopupProps = {
      id: toastId,
      ...options,
      onCancel: () => this.destroy(options.id ?? toastId),
      onConfirm: () => {
        options.onConfirm();
        this.destroy(options.id ?? toastId);
      },
      customButtons: (
        <div className="d-flex gap-1 gap-lg-3">
          {options.customButtonList.map(
            (
              {
                buttonText = "",
                buttonColor = "primary",
                handleConfirm,
                ...rest
              },
              index
            ) => {
              return (
                <Button
                  key={index}
                  buttonColor={buttonColor}
                  buttonText={buttonText}
                  onClick={() => {
                    handleConfirm();
                    this.destroy(options.id ?? toastId);
                  }}
                  {...rest}
                />
              );
            }
          )}
        </div>
      ),
    };

    this.alerts = [toast];
    this.render();
  }

  public destroy(id: string): void {
    this.alerts = this.alerts.filter(
      (toast: AlertPopupProps) => toast.id !== id
    );
    this.render();
  }

  private render(): void {
    const toastsList = this.alerts.map((toastProps: AlertPopupProps) => (
      <AlertPopup key={toastProps.id} {...toastProps}>
        {toastProps?.children}
      </AlertPopup>
    ));
    ReactDOM.render(toastsList, this.containerRef);
  }
}

export const alertPopup = new ToastManager();
