import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Card from "src/components/Card";
import CheckBox from "src/components/CheckBox";
import Input from "src/components/Input";
import { doSignUp } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { passwordRegex } from "src/utils/constants";
import classes from "./styles.module.scss";
import { SignupInputProps } from "./types";

const DefaultSignUp = () => {
  const navigate = useNavigate();
  const actions = useStoreActions({ doSignUp });
  const { loading } = useReducerData("auth", "register", { loading: false });

  const signUpSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Must be valid email addresses"),
    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, "Password needs to be 10+ characters"),
    privacyPolicy: Yup.boolean().oneOf([true], "Please accept Privacy Policy"),
  });

  const handleSubmit = async (
    values: SignupInputProps,
    formikHelpers: FormikHelpers<SignupInputProps>
  ) => {
    const { email, password, firstName, lastName } = values;
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.doSignUp(data);
    if (status) {
      navigate("/auth/signup/check-email");
      formikHelpers.resetForm();
    }
  };

  return (
    <Card
      cardClassName={classes.card}
      cardTitle="Sign up for an NTS user account"
      cardFooterContent={
        <div
          className={cx(classes.footer, "d-flex gap-1 justify-content-center")}
        >
          <p>Already have an account? </p>
          <Button
            onClick={() => navigate("/auth/login")}
            buttonText="Log in"
            variant="link"
          />
        </div>
      }
    >
      <Formik
        onSubmit={(values, formikHelpers) =>
          handleSubmit(values, formikHelpers)
        }
        validationSchema={signUpSchema}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          privacyPolicy: false,
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          dirty,
        }: FormikProps<SignupInputProps>) => (
          <Form className={cx(classes.signupInputs, "d-flex flex-column")}>
            <FormGroup className={classes.formGroup}>
              <Input
                value={values.firstName}
                placeholder="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName}
                touched={touched.firstName}
                name="firstName"
                leftIcon={<i className="fa fa-solid fa-address-card" />}
              />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Input
                value={values.lastName}
                placeholder="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName}
                touched={touched.lastName}
                name="lastName"
                leftIcon={<i className="fa fa-solid fa-address-card" />}
              />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Input
                value={values.email}
                placeholder="Work Email Address"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                name="email"
                leftIcon={<i className="fa fa-solid fa-envelope" />}
              />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
              <Input
                value={values.password}
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                touched={touched.password}
                name="password"
                type="password"
                leftIcon={<i className="fa fa-solid fa-key" />}
              />
            </FormGroup>
            <FormGroup
              className={cx("text-start mb-4 mt-4", classes.formGroupCheckBox)}
            >
              <CheckBox
                onChange={handleChange}
                checked={values.privacyPolicy}
                onBlur={handleBlur}
                error={errors.privacyPolicy}
                touched={touched.privacyPolicy}
                showOutline
                label={
                  <div className="d-flex gap-1">
                    <p>I agree with the</p>
                    <Button buttonText="Privacy Policy" variant="link" />
                  </div>
                }
                name="privacyPolicy"
              />
            </FormGroup>
            <div>
              <Button
                disabled={!isValid || !dirty}
                type="submit"
                buttonText="Create account"
                loading={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default DefaultSignUp;
