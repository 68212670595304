import { createTypes } from "./createTypes";

export default createTypes(
  "FETCH_RECENT_ADMIN_ORDERS_STARTED",
  "FETCH_RECENT_ADMIN_ORDERS_SUCCESS",
  "FETCH_RECENT_ADMIN_ORDERS_FAILED",
  "FETCH_ALL_ADMIN_ORDERS_STARTED",
  "FETCH_ALL_ADMIN_ORDERS_SUCCESS",
  "FETCH_ALL_ADMIN_ORDERS_FAILED",
  "UPDATE_ADMIN_ORDER_BY_ID_STARTED",
  "UPDATE_ADMIN_ORDER_BY_ID_SUCCESS",
  "UPDATE_ADMIN_ORDER_BY_ID_FAILED",
  "FETCH_ALL_CUSTOMERS_STARTED",
  "FETCH_ALL_CUSTOMERS_SUCCESS",
  "FETCH_ALL_CUSTOMERS_FAILED",
  "ADD_CUSTOMERS_CREDIT_STARTED",
  "ADD_CUSTOMERS_CREDIT_SUCCESS",
  "ADD_CUSTOMERS_CREDIT_FAILED",
  "FETCH_ORDER_STATUSES_STARTED",
  "FETCH_ORDER_STATUSES_SUCCESS",
  "FETCH_ORDER_STATUSES_FAILED",
  "EXPORT_ALL_ORDER_STARTED",
  "EXPORT_ALL_ORDER_SUCCESS",
  "EXPORT_ALL_ORDER_FAILED",
  "UPLOAD_REPORT_STARTED",
  "UPLOAD_REPORT_SUCCESS",
  "UPLOAD_REPORT_FAILED",
  "EMAIL_REPORT_STARTED",
  "EMAIL_REPORT_SUCCESS",
  "EMAIL_REPORT_FAILED",
  "EXPORT_ALL_CUSTOMERS_STARTED",
  "EXPORT_ALL_CUSTOMERS_SUCCESS",
  "EXPORT_ALL_CUSTOMERS_FAILED",
  "RESEND_VERIFICATION_EMAIL_STARTED",
  "RESEND_VERIFICATION_EMAIL_SUCCESS",
  "RESEND_VERIFICATION_EMAIL_FAILED",
  "DELETE_CUSTOMER_STARTED",
  "DELETE_CUSTOMER_SUCCESS",
  "DELETE_CUSTOMER_FAILED",
  "UPDATE_CUSTOMER_STARTED",
  "UPDATE_CUSTOMER_SUCCESS",
  "UPDATE_CUSTOMER_FAILED"
);
