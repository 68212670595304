import cx from "classnames";
import React from "react";
import { Card } from "reactstrap";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";

type Props = {
  title: string;
  price: number;
  description: string;
  buttonText: string;
  handleAction?: () => void;
  active?: boolean;
  linkUrl?: string;
};

const PriceCard: React.FC<Props> = ({
  title = "",
  price = 0,
  description = "",
  buttonText = "",
  active = false,
  handleAction,
  linkUrl = "",
}) => {
  return (
    <Card className={cx(classes.wrapper, { [classes.active]: active })}>
      <Typography
        className={
          title !== "Color Analysis" ? classes.title : classes.colorCardTitle
        }
      >
        {title}
      </Typography>
      <Typography className={classes.price}>
        <sup className={classes.priceWrapper}>$</sup>
        {price}
      </Typography>
      <Typography
        className={cx(classes.description, {
          [classes.reportDescription]: title !== "Shingle ID Report",
        })}
      >
        {description}
      </Typography>
      <div className={classes.linkWrapper}>
        {title !== "Color Analysis" ? (
          <Button
            buttonClassName={classes.customButton}
            buttonText={buttonText}
            onClick={handleAction}
          />
        ) : (
          <a
            className={classes.customLinkButton}
            href={linkUrl}
            target="_blank"
            rel="noreferrer"
          >
            {buttonText}
          </a>
        )}
      </div>
    </Card>
  );
};

export default PriceCard;
