import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import { getSubscriptionPlans } from "src/store/actions/subscription";
import { getClientDetails, getCurrentUser } from "src/store/actions/user";
import { useReducerData, useStoreActions } from "src/store/hooks";
import PlansCard from "./Components/PlansCard";
import classes from "./styles.module.scss";
import { PlanProps } from "./types";

const Plans = () => {
  const actions = useStoreActions({
    getSubscriptionPlans,
    getCurrentUser,
    getClientDetails,
  });

  const [searchParams] = useSearchParams();
  const client_id = searchParams.get("client_id");
  const { data, loading } = useReducerData("plans", "subscriptionPlans", {
    data: [],
  });
  const userReducerType = client_id ? "clientDetails" : "user";
  const { data: user } = useReducerData("user", userReducerType, {
    data: {},
  });

  const [planList, setPlanList] = useState<PlanProps[]>([]);

  useEffect(() => {
    actions.getSubscriptionPlans();
    actions.getCurrentUser();
    if (client_id) {
      actions.getClientDetails({ user_id: client_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.length) {
      const plansClone = [...(data || [])];
      const planList = plansClone?.sort((a: PlanProps, b: PlanProps) =>
        a.amount > b.amount ? 1 : -1
      );
      setPlanList(planList);
    }
  }, [data]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Container className={classes.wrapper}>
      <Typography className={classes.titleText} variant="p16">
        You asked, we listened!
      </Typography>
      <div id="scroll">
        <Typography className={classes.titleWrapper} variant="p32">
          New subscription packages for companies of any size!
        </Typography>
      </div>
      <Typography className={classes.highPlanTitle} variant="p24">
        For our high-volume enterprise customers
      </Typography>
      <div className={classes.planList}>
        {planList.slice(3, 6).map((plan, index) => (
          <div key={index}>
            <PlansCard
              user={user}
              plan={plan}
              isLowerPlan={false}
              clientId={client_id}
            />
          </div>
        ))}
      </div>
      <Typography className={classes.lowerPlanTitle} variant="p24">
        For lower volume customers looking to grow their business
      </Typography>
      <div className={classes.planList}>
        {planList.slice(0, 3).map((plan, index) => (
          <div key={index}>
            <PlansCard
              user={user}
              plan={plan}
              isLowerPlan={true}
              clientId={client_id}
            />
          </div>
        ))}
      </div>
      <Typography className={classes.subscriptionWrapper} variant="p24">
        More information about subscription plans
      </Typography>
      <ol id="plans-terms-and-condition" className={classes.listWrapper}>
        <li className="mb-3">
          Subscriptions renew monthly on the same calendar day as the initial
          purchase of the subscription. Monthly credits do not roll over to
          subsequent months - use them or lose them. Shipping costs are not
          included in the price of subscriptions and are charged separately.
        </li>
        <li className="mb-3">
          Once all account credits have been used in a period, any subsequent
          orders during that time period will automatically be charged at the
          per report price of the next tier. For example, once a Premium
          subscriber has used all 25 report credits in a period, any additional
          orders will be charged at the Enterprise tier price of $125.
        </li>
        <li className="mb-3">
          Subscription tiers can be adjusted at any time. To upgrade or
          downgrade, simply select the plan you want to switch to and confirm.
          Your subscription tier will be adjusted at the end of your current
          billing period.
        </li>
        <li className="mb-4">
          Questions about our subscriptions? Email us at{" "}
          <a
            target="_blank"
            href="mailto:info@ntsid.com"
            rel="noreferrer"
            className={classes.linkWrapper}
          >
            info@ntsid.com
          </a>
          .
        </li>
      </ol>
    </Container>
  );
};

export default Plans;
