import cx from "classnames";
import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { alertPopup } from "src/components/AlertPopup/AlertPopupManager";
import Typography from "src/components/Typography";
import UserDropdown from "src/components/UserDropdown";
import { Reports } from "src/pages/OrderCreate/Components/SelectReport/types";
import { removeCheckoutDetails } from "src/store/actions/checkout";
import { useStoreActions } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

const CheckoutLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const actions = useStoreActions({ removeCheckoutDetails });
  const { width = 0 } = useWindowSize();
  const isTablet = width < 1024;
  const [searchParams] = useSearchParams();
  const report = searchParams.get("report");

  const renderHeaderTitle = () => {
    switch (report) {
      case "Siding":
        return `Order a ${Reports.Siding} ID Report`;
      case "Shingle":
        return `Order a ${Reports.Shingle} ID Report`;
      default:
        return "Order a Report";
    }
  };

  const handleBackToDashboard = () => {
    alertPopup.showCustom({
      warning: true,
      onConfirm: () => navigate("/dashboard"),
      title: "Are you sure you want to exit checkout?",
      children: "You will lose any information you have entered",
      customButtonList: [
        {
          buttonText: "Stay in checkout",
          buttonColor: "info",
          handleConfirm: () => {},
        },
        {
          buttonText: isTablet ? "Exit checkout " : "Yes, exit checkout",
          buttonColor: "danger",
          handleConfirm: () => {
            actions.removeCheckoutDetails();
            navigate("/dashboard");
          },
        },
      ],
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.nav}>
        <div
          className={classes.backToDashboard}
          onClick={handleBackToDashboard}
        >
          <i className="fa fa-long-arrow-left" />
          <Typography className="mb-0" fontWeight="semibold" variant="p14">
            Back to Dashboard
          </Typography>
        </div>
        <Typography
          className={cx("mb-0", classes.orderText)}
          fontWeight="semibold"
          variant="p18"
        >
          {renderHeaderTitle()}
        </Typography>
        <div className={classes.userDropdowns}>
          <UserDropdown />
        </div>
      </div>
      <div className={classes.checkoutContentWrapper}>{children}</div>
    </div>
  );
};

export default CheckoutLayout;
