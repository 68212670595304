import cx from "classnames";
import React from "react";
import {
  CardBody,
  Card as CardComponent,
  CardFooter,
  CardTitle,
} from "reactstrap";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Card: React.FC<Props> = ({
  children,
  cardClassName = "",
  cardTitle = "",
  cardTitleClassName = "",
  cardBodyClassName = "",
  cardFooterContent = "",
  cardFooterClassName = "",
}) => {
  return (
    <CardComponent
      className={cx(classes.card, { [cardClassName]: cardClassName })}
    >
      {cardTitle && (
        <CardTitle
          className={cx(classes.title, {
            [cardTitleClassName]: cardTitleClassName,
          })}
        >
          {cardTitle}
        </CardTitle>
      )}
      <CardBody
        className={cx(classes.cardBody, {
          [cardBodyClassName]: cardBodyClassName,
        })}
      >
        {children}
      </CardBody>
      {cardFooterContent && (
        <CardFooter
          className={cx("bg-transparent p-4 border-top-0", classes.footer, {
            [cardFooterClassName]: cardFooterClassName,
          })}
        >
          {cardFooterContent}
        </CardFooter>
      )}
    </CardComponent>
  );
};

export default Card;
