import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { Container, FormGroup } from "reactstrap";
import * as Yup from "yup";
import ContactUsBg from "src/assets/images/NTS-contact-us-bg.jpg";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { postUserContactInfo } from "src/store/actions/home";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";
import { FormInputProp } from "./types";

const ContactUs = () => {
  const actions = useStoreActions({ postUserContactInfo });
  const loading = useReducerData("home", "loading", false);

  const contactSchema = Yup.object().shape({
    name: Yup.string(),
    email: Yup.string()
      .required("Email is required")
      .email("Must be valid email addresses"),
    msg: Yup.string().required("Message is required"),
  });

  const handleContactDetailSubmit = async (
    values: FormInputProp,
    formikHelpers: FormikHelpers<FormInputProp>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.postUserContactInfo(values);
    if (status) {
      formikHelpers.resetForm();
    }
  };

  return (
    <div
      id="nts-contact"
      className={classes.wrapper}
      style={{
        backgroundImage: ` url(${ContactUsBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container className={classes.container}>
        <div className={classes.content}>
          <Typography className={classes.title}>Contact Us</Typography>
          <Typography className={classes.info}>
            Use the form at right to send us a message, or email us directly at{" "}
            <a target="_blank" href="mailto:info@ntsid.com" rel="noreferrer">
              info@ntsid.com
            </a>{" "}
            .
          </Typography>
          <Typography className={classes.info}>
            After placing your order, send samples to the following address for
            analysis:
          </Typography>
          <Typography className={classes.address}>NTS</Typography>
          <Typography className={classes.address}>
            3800 Haslet-Roanoke Rd Unit 4 Roanoke, Texas 76262
          </Typography>
        </div>
        <div className={classes.form}>
          <Formik
            onSubmit={handleContactDetailSubmit}
            validationSchema={contactSchema}
            initialValues={{
              name: "",
              email: "",
              msg: "",
            }}
          >
            {({
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isValid,
              dirty,
            }: FormikProps<FormInputProp>) => (
              <Form>
                <FormGroup className={classes.formGroup}>
                  <Input
                    label="Your Name"
                    value={values.name}
                    labelClassName={classes.label}
                    placeholder="Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name}
                    touched={touched.name}
                    name="name"
                  />
                </FormGroup>

                <FormGroup className={classes.formGroup}>
                  <Input
                    required
                    label="Your Email Address"
                    value={values.email}
                    labelClassName={classes.label}
                    placeholder="Email address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    touched={touched.email}
                    name="email"
                  />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                  <Input
                    required
                    labelClassName={classes.label}
                    inputClassName={classes.textareaStyling}
                    label="Your message to us"
                    value={values.msg}
                    placeholder="Message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.msg}
                    touched={touched.msg}
                    name="msg"
                    type="textarea"
                  />
                </FormGroup>
                <div className={classes.sendButton}>
                  <Button
                    disabled={!isValid || !dirty}
                    type="submit"
                    buttonText="Send your message"
                    loading={loading}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <Typography className={classes.textWrapper}>
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              className={classes.link}
              target="_blank"
              href="https://policies.google.com/privacy?hl=en-US"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              className={classes.link}
              target="_blank"
              href="https://policies.google.com/terms?hl=en-US"
              rel="noreferrer"
            >
              Terms of Service
            </a>{" "}
            apply.
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
