import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import BreadCrumbs from "src/components/BreadCrumbs";
import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import { getOrderById } from "src/store/actions/order";
import { useReducerData, useStoreActions } from "src/store/hooks";
import ContactInfo from "./Components/ContactInfo";
import OrderInfo from "./Components/OrderInfo";
import PaymentInfo from "./Components/PaymentInfo";
import ReportOrder from "./Components/ReportUser";
import classes from "./styles.module.scss";

const OrderDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const isDashboard = location.pathname.includes("dashboard");
  const actions = useStoreActions({ getOrderById });
  const { loading, order } = useReducerData("order", "orderById", {});
  const { data: user } = useReducerData("user", "user", {});

  useEffect(() => {
    actions.getOrderById(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading && !order) {
    return <Loader />;
  }

  return (
    <>
      {!isDashboard && (
        <div className={classes.breadcrumbWrapper}>
          {order?.property_detail?.order_id && (
            <Typography
              className="mb-0 d-none d-lg-block"
              variant="p20"
              fontWeight="semibold"
            >
              Order {order?.property_detail?.order_id || "Not Provided"}
            </Typography>
          )}
          <BreadCrumbs path={location.pathname} />
        </div>
      )}
      <div className={classes.wrapper}>
        {user?.is_admin === 1 && (
          <div className={classes.reportOrder}>
            <ReportOrder />
          </div>
        )}
        <OrderInfo {...order} />
        <div className={classes.bottomCards}>
          <ContactInfo {...order} />
          <PaymentInfo {...order} />
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
