import { Reducer } from "redux";

import Types from "../types/auth";

const initialState = {
  loading: false,
  isError: false,
  message: "",
  user: null,
  isLoggedIn: false,
  register: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  passwordReset: {
    loading: false,
    isError: false,
    status: null,
    message: "",
    data: {},
  },
};

const AuthReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.USER_SIGNUP_STARTED:
      return {
        ...state,
        register: {
          ...state.register,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.USER_SIGNUP_FAILED:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_LOGIN_STARTED:
      return {
        ...state,
        loading: true,
        isError: false,
        isLoggedIn: false,
        message: "",
      };
    case Types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        message: "",
        isLoggedIn: true,
        user: payload.data,
      };
    case Types.USER_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        isLoggedIn: false,
        message: payload.message,
      };
    case Types.USER_EMAIL_VERIFICATION_STARTED:
      return {
        ...state,
        register: {
          ...state.register,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.USER_EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_RESET_PASSWORD_STARTED:
    case Types.USER_FORGOT_PASSWORD_STARTED:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_RESET_PASSWORD_SUCCESS:
    case Types.USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: false,
          isError: false,
          message: "",
          data: { ...payload.data, email: payload.email },
        },
      };
    case Types.USER_RESET_PASSWORD_FAILED:
    case Types.USER_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;
