import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { Container, FormGroup } from "reactstrap";
import * as Yup from "yup";
import Logo from "src/assets/icons/logo.svg";
import Button from "src/components/Button";
import Card from "src/components/Card";
import CheckBox from "src/components/CheckBox";
import Input from "src/components/Input";
import { doLogin } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";
import { SignInInputProps } from "./types";

const SignIn = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const actions = useStoreActions({ doLogin });
  const loading = useReducerData("auth", "loading", false);

  const signInSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Must be valid email addresses"),
    password: Yup.string().required("Password is required"),
    rememberPassword: Yup.boolean(),
  });

  const handleSubmit = async (
    values: SignInInputProps,
    formikHelpers: FormikHelpers<SignInInputProps>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.doLogin(values.email, values.password);
    if (status) {
      formikHelpers.resetForm();
      if (searchParams.get("create")) {
        window.location.href = "/order/create/select-report";
      } else {
        return navigate("/dashboard");
      }
    }
  };

  return (
    <Container className={classes.container}>
      <div
        className={cx(
          classes.signInHeader,
          "d-flex flex-column  align-items-center justify-content-center"
        )}
      >
        <div className={classes.logo}>
          <Link to={"/auth/login"}>
            <img alt="logo" src={Logo} />
          </Link>
        </div>
        <div className={classes.title}>
          <p>Welcome back! Log into your existing NTS account below </p>
        </div>
      </div>
      <Card
        cardClassName={classes.card}
        cardTitle="Sign in with your existing credentials"
        cardFooterContent={
          <div
            className={cx("d-flex justify-content-center flex-column fw-bold")}
          >
            <p className="mb-0">Don’t have an account yet?</p>
            <div className={cx("text-decoration-underline", classes.link)}>
              <Button
                onClick={() => navigate("/auth/signup")}
                buttonText="Create an account"
                variant="link"
              />
            </div>
          </div>
        }
      >
        <Formik
          onSubmit={(values, formikHelpers) =>
            handleSubmit(values, formikHelpers)
          }
          validationSchema={signInSchema}
          initialValues={{
            email: "",
            password: "",
            rememberPassword: false,
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
          }: FormikProps<SignInInputProps>) => {
            return (
              <Form className={cx(classes.signInInputs, "d-flex flex-column")}>
                <FormGroup className={classes.formGroup}>
                  <Input
                    value={values.email}
                    placeholder="Email address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    touched={touched.email}
                    name="email"
                    leftIcon={<i className="fa fa-solid fa-envelope" />}
                  />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                  <Input
                    value={values.password}
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                    name="password"
                    type="password"
                    leftIcon={<i className="fa fa-solid fa-key" />}
                  />
                </FormGroup>
                <FormGroup
                  className={cx("text-start mt-3", classes.formGroupCheckBox)}
                >
                  <CheckBox
                    onChange={handleChange}
                    checked={values.rememberPassword}
                    touched={touched.rememberPassword}
                    showOutline
                    label={<p className="px-1">Remember me</p>}
                    name="rememberPassword"
                  />
                </FormGroup>
                <div>
                  <Button
                    disabled={!isValid || !dirty}
                    buttonClassName={classes.signInButton}
                    type="submit"
                    buttonText="Sign in"
                    loading={loading}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Card>
      <div className={classes.forgotPassword}>
        <Link to="/auth/forgot-password">
          <p className="mb-0 pb-4">Forgot Password?</p>
        </Link>
      </div>
    </Container>
  );
};

export default SignIn;
