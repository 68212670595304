import React, { useState } from "react";
import { useLocation } from "react-router";
import BreadCrumbs from "src/components/BreadCrumbs";
import { useReducerData } from "src/store/hooks";
import EditProfile from "./Components/EditProfile";
import PaymentMethod from "./Components/PaymentMethod";
import Profile from "./Components/Profile";
import classes from "./styles.module.scss";

const Settings = () => {
  const { data: user } = useReducerData("user", "user", {});
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const toggle = () => setIsEdit(!isEdit);

  return (
    <>
      <div className={classes.breadcrumbWrapper}>
        <BreadCrumbs path={location.pathname} isAdmin={!!user?.is_admin} />
      </div>
      <div className={classes.settingsWrapper}>
        {isEdit ? (
          <EditProfile userData={user} handleBackToProfile={toggle} />
        ) : (
          <Profile userData={user} handleEdit={toggle} />
        )}
        {!user?.is_admin && <PaymentMethod />}
      </div>
    </>
  );
};

export default Settings;
