import cx from "classnames";
import moment from "moment";
import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { emailReport, uploadReport } from "src/store/actions/admin";
import { getOrderById, getReportFile } from "src/store/actions/order";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";

const ReportOrder = () => {
  const [isSending, setIsSending] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [fileKey, setFileKey] = useState(Date.now());

  const { order } = useReducerData("order", "orderById", {});

  const actions = useStoreActions({
    uploadReport,
    emailReport,
    getOrderById,
    getReportFile,
  });

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files && e?.target?.files[0];
    if (file) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("order_id", order?.id?.toString() as string | Blob);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await actions.uploadReport(formData);
      await actions.getOrderById(order?.id as string);
      setFileKey(Date.now());
      setIsUploading(false);
    }
  };

  const handleFileUpload = () => {
    document?.getElementById("fileid")?.click();
  };

  const handleEmailReport = async () => {
    setIsSending(true);
    const emailDetails = {
      order_id: order.id,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await actions.emailReport(emailDetails);
    await actions.getOrderById(order?.id as string);
    setIsSending(false);
  };

  const downloadReportFile = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    const reportFile = await actions.getReportFile(order?.id as number);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url = window.URL.createObjectURL(new Blob([reportFile.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report_file.pdf");
    document.body.appendChild(link);
    link.click();
    setIsDownloading(false);
  };

  return (
    <Card>
      <div className={classes.wrapper}>
        <div className={classes.uploadFile}>
          <div className={classes.leftContent}>
            {order?.report_uploaded_at ? (
              <>
                <Typography variant="p18" className={classes.fileText}>
                  {order?.report_file}
                </Typography>
                <Typography variant="p12" className={classes.fileSubTitle}>
                  Uploaded on{" "}
                  {moment(order?.report_uploaded_at).format("MM/DD/YYYY")} at{" "}
                  {moment(order?.report_uploaded_at).format("h:mma")}
                </Typography>
              </>
            ) : (
              <Typography variant="p18" className={classes.fileText}>
                Click here to upload the shingle or siding ID report
              </Typography>
            )}
          </div>
          <FormGroup className={classes.formGroup}>
            <Input
              key={fileKey}
              inputId="fileid"
              type="file"
              hidden
              onChange={(e) => handleFileInput(e)}
              accept=".xlsx,.xls,.doc,.docx,.pdf"
            />
          </FormGroup>
          <Button
            buttonClassName={cx(
              classes.customButton,
              order?.report_file
                ? classes.updatefileButton
                : classes.customFileButton
            )}
            loading={isUploading}
            onClick={handleFileUpload}
            buttonText={
              isUploading
                ? "Uploading"
                : order?.report_file
                ? "Select New File"
                : "Select File"
            }
          />
        </div>
        {order?.report_file && (
          <div className={classes.emailReport}>
            <div className={classes.leftContent}>
              <Typography variant="p18" className={classes.emailText}>
                {order?.report_sent
                  ? "Report has been delivered"
                  : "This report is ready to be sent!"}
              </Typography>
              <Typography variant="p12" className={classes.emailSubTitle}>
                {order?.report_sent
                  ? `Emailed on ${moment(order?.report_sent).format(
                      "MM/DD/YYYY"
                    )} at 
                  ${moment(order?.report_sent).format("h:mma")}`
                  : "This will send to all emails on this order"}
              </Typography>
            </div>
            <div className={classes.btnContainer}>
              <Button
                buttonClassName={cx(
                  classes.customEmailButton,
                  order?.report_sent
                    ? classes.resentEmailButton
                    : classes.emailButton
                )}
                buttonText={
                  order?.report_sent ? "Resend Email Report" : "Email Report"
                }
                loading={isSending}
                onClick={handleEmailReport}
              />
              {order?.report_sent && (
                <Button
                  buttonClassName={cx(
                    classes.customEmailButton,
                    classes.emailButton
                  )}
                  buttonText={"Download Report"}
                  loading={isDownloading}
                  onClick={downloadReportFile}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ReportOrder;
