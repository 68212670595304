import { Worker } from "@react-pdf-viewer/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// eslint-disable-next-line no-restricted-imports
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line no-restricted-imports
import "font-awesome/css/font-awesome.min.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import "./index.scss";
// eslint-disable-next-line no-restricted-imports
import "@react-pdf-viewer/core/lib/styles/index.css";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PAYMENT_API_KEY || ""
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <Elements stripe={stripePromise}>
              <App />
            </Elements>
          </PersistGate>
        </Provider>
      </Worker>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
