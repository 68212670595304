import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Logo from "src/assets/icons/logo.svg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { isLoggedIn } from "src/utils/utils";
import { navItems } from "../Header/NavItems";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Sidebar: React.FC<Props> = ({
  showSidebar,
  handleSidebarToggle,
  handleScrollChange,
}) => {
  const navigate = useNavigate();
  const isLogin = isLoggedIn();
  return (
    <div className={cx(classes.sidebar, { [classes.isActive]: showSidebar })}>
      <div
        className={cx(classes.icon, { [classes.sidebarBurger]: showSidebar })}
      >
        <img alt="logo" src={Logo} />
        {showSidebar && (
          <div className={classes.closeIcon} onClick={handleSidebarToggle}>
            <i className="fa fa-bars" />
          </div>
        )}
      </div>
      <div className={cx(classes.buttonsWrapperMobile)}>
        <Button
          buttonClassName={classes.orderButton}
          onClick={() => navigate("/order/create/select-report")}
          buttonText="Order a Report"
        />
        {!isLogin ? (
          <Button
            buttonClassName={classes.loginBtn}
            variant="outline"
            onClick={() => navigate("/auth/login")}
            buttonText="Log In"
          />
        ) : (
          <Button
            buttonClassName={classes.loginBtn}
            variant="outline"
            onClick={() => navigate("/dashboard")}
            buttonText="Dashboard"
          />
        )}
      </div>
      <div className={classes.sidebarContent}>
        {navItems.map(({ name, id }, index) => {
          return (
            <div key={index}>
              {id === "nts-merch" ? (
                <a
                  className={cx(classes.sidebarItem)}
                  target="_blank"
                  href="https://name-that-shingle.myshopify.com/"
                  rel="noreferrer"
                  onClick={() => {
                    handleSidebarToggle();
                  }}
                >
                  <Typography
                    variant="p18"
                    fontWeight={"regular"}
                    className="mb-0"
                  >
                    {name}
                  </Typography>
                </a>
              ) : (
                <Link
                  onClick={() => {
                    handleScrollChange(id);
                    handleSidebarToggle();
                  }}
                  className={cx(classes.sidebarItem)}
                  key={index}
                  to={"/"}
                >
                  <Typography
                    variant="p18"
                    fontWeight={"regular"}
                    className="mb-0"
                  >
                    {name}
                  </Typography>
                </Link>
              )}
            </div>
          );
        })}
        <div className={classes.socialLinks}>
          <a
            target="_blank"
            href="https://www.facebook.com/groups/namethatshinglerenamed"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
            <Typography variant="p18" fontWeight={"regular"} className="mb-0">
              Facebook
            </Typography>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCENVeMu0HnqPUBUoUYgkBpw"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
            <Typography variant="p18" fontWeight={"regular"} className="mb-0">
              Youtube
            </Typography>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
