import { Dispatch } from "redux";
import {
  AdminPaymentProps,
  GetUserType,
  SupportProps,
  UserProfile,
  addPaymentMethod as addPaymentMethodApi,
  getUser as getUserApi,
  postSupportRequest as postSupportRequestApi,
  postUserTerms as postUserTermsApi,
  removePaymentMethod as removePaymentMethodApi,
  updateUser as updateUserApi,
} from "src/api/user";
import { toast } from "src/components/Toast/ToastManager";
import Types from "../types/user";

export const getCurrentUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_FETCH_STARTED,
    });

    const resp = await getUserApi(null);
    if (resp?.status === 1) {
      dispatch({
        type: Types.USER_FETCH_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.USER_FETCH_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const updateProfile = (data: UserProfile) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_UPDATE_STARTED,
    });

    const resp = await updateUserApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.USER_UPDATE_SUCCESS,
        payload: { ...resp, data },
      });
      toast.show({
        content: resp.message,
        variant: "success",
      });
    } else {
      const { details, error } = resp.data;
      const [message] = details.email;

      dispatch({
        type: Types.USER_UPDATE_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: error,
        content: message,
        variant: "danger",
      });
    }
    return resp;
  };
};

export const removePaymentMethod = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_REMOVE_PAYMENT_METHOD_STARTED,
    });

    const resp = await removePaymentMethodApi(id);
    if (resp?.status === 1) {
      dispatch({
        type: Types.USER_REMOVE_PAYMENT_METHOD_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        content: resp.message,
        variant: "success",
        showIndicationText: true,
        size: "lg",
      });
    } else {
      dispatch({
        type: Types.USER_REMOVE_PAYMENT_METHOD_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const postSupportRequest = (data: SupportProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.USER_POST_SUPPORT_REQUEST_STARTED,
    });

    const resp = await postSupportRequestApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.USER_POST_SUPPORT_REQUEST_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        content: "Your request submitted successfully",
        variant: "success",
        showIndicationText: true,
        size: "md",
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.USER_POST_SUPPORT_REQUEST_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        content: resp.data.error,
        variant: "danger",
        showIndicationText: true,
        size: "md",
        position: "top-right",
      });
    }
  };
};

export const addPaymentMethod = (data: AdminPaymentProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.ADD_PAYMENT_METHOD_STARTED,
    });

    const resp = await addPaymentMethodApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.ADD_PAYMENT_METHOD_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        title: resp?.message,
        variant: "success",
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.ADD_PAYMENT_METHOD_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: resp?.data?.error,
        variant: "danger",
        position: "top-right",
      });
    }
    return resp;
  };
};

export const getClientDetails = (params: GetUserType) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_CLIENT_DETAILS_STARTED,
    });

    const resp = await getUserApi(params);

    if (resp?.status === 1) {
      dispatch({
        type: Types.GET_CLIENT_DETAILS_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.GET_CLIENT_DETAILS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const postUserTerms = (formData: FormData) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.POST_USER_TERMS_STARTED,
    });

    const resp = await postUserTermsApi(formData);

    if (resp?.status === 1) {
      dispatch({
        type: Types.POST_USER_TERMS_SUCCESS,
        payload: { data: { terms_accepted: 1 } },
      });
    } else {
      dispatch({
        type: Types.POST_USER_TERMS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};
