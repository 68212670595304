import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Footer from "./Footer";

import Header from "./Header";
import Sidebar from "./Sidebar";
import classes from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

const PublicLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const state = location.state as { id: string };
  const [showSidebar, setShowSidebar] = useState(false);
  const [scrollTo, setScrollTo] = useState("");
  const handleSidebarToggle = () => setShowSidebar(!showSidebar);
  const handleScrollChange = (id: string) => setScrollTo(id);

  useEffect(() => {
    const element = document.getElementById(scrollTo);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [scrollTo, state]);

  useEffect(() => {
    state && setScrollTo(state?.id);
  }, [state]);

  return (
    <div className={classes.wrapper}>
      <Header
        handleScrollChange={handleScrollChange}
        handleSidebarToggle={handleSidebarToggle}
      />
      <Sidebar
        showSidebar={showSidebar}
        handleScrollChange={handleScrollChange}
        handleSidebarToggle={handleSidebarToggle}
      />
      {children}
      <Footer />
    </div>
  );
};

export default PublicLayout;
