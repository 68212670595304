import { createTypes } from "./createTypes";

export default createTypes(
  "USER_FETCH_STARTED",
  "USER_FETCH_SUCCESS",
  "USER_FETCH_FAILED",
  "USER_UPDATE_STARTED",
  "USER_UPDATE_SUCCESS",
  "USER_UPDATE_FAILED",
  "USER_REMOVE_PAYMENT_METHOD_STARTED",
  "USER_REMOVE_PAYMENT_METHOD_SUCCESS",
  "USER_REMOVE_PAYMENT_METHOD_FAILED",
  "USER_POST_SUPPORT_REQUEST_STARTED",
  "USER_POST_SUPPORT_REQUEST_SUCCESS",
  "USER_POST_SUPPORT_REQUEST_FAILED",
  "ADD_PAYMENT_METHOD_STARTED",
  "ADD_PAYMENT_METHOD_SUCCESS",
  "ADD_PAYMENT_METHOD_FAILED",
  "GET_CLIENT_DETAILS_STARTED",
  "GET_CLIENT_DETAILS_SUCCESS",
  "GET_CLIENT_DETAILS_FAILED",
  "POST_USER_TERMS_STARTED",
  "POST_USER_TERMS_SUCCESS",
  "POST_USER_TERMS_FAILED"
);
