export const navItems = [
  {
    id: "nts-report",
    name: "NTS Report Sample",
    link: "/",
  },
  {
    id: "nts-faq",
    name: "FAQ",
    link: "",
  },
  {
    id: "nts-pricing",
    name: "Pricing",
    link: "",
  },
  {
    id: "nts-mission",
    name: "Company Info",
    link: "",
  },
  {
    id: "nts-merch",
    name: "Merch",
    link: "",
  },
  {
    id: "nts-contact",
    name: "Contact Us",
    link: "",
  },
];
