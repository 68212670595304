import { createTypes } from "./createTypes";

export default createTypes(
  "USER_SIGNUP_STARTED",
  "USER_SIGNUP_SUCCESS",
  "USER_SIGNUP_FAILED",
  "USER_LOGIN_STARTED",
  "USER_LOGIN_SUCCESS",
  "USER_LOGIN_FAILED",
  "USER_EMAIL_VERIFICATION_STARTED",
  "USER_EMAIL_VERIFICATION_SUCCESS",
  "USER_EMAIL_VERIFICATION_FAILED",
  "USER_RESET_PASSWORD_STARTED",
  "USER_RESET_PASSWORD_SUCCESS",
  "USER_RESET_PASSWORD_FAILED",
  "USER_FORGOT_PASSWORD_STARTED",
  "USER_FORGOT_PASSWORD_SUCCESS",
  "USER_FORGOT_PASSWORD_FAILED",
  "USER_LOGOUT_SUCCESS"
);
