import React from "react";
import { useNavigate } from "react-router";
import { Container } from "reactstrap";
import Typography from "src/components/Typography";
import { Reports } from "src/pages/OrderCreate/Components/SelectReport/types";
import PriceCard from "./PriceCard";
import classes from "./styles.module.scss";

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <div id="nts-pricing" className={classes.wrapper}>
      <Container className={classes.container}>
        <Typography className={classes.title} fontWeight="bold">
          Pricing
        </Typography>
        <Typography className={classes.description}>
          NTS proudly serves customers across the US with superior customer
          service at reasonable prices
        </Typography>
        <div className={classes.priceCards}>
          {/* <PriceCard
            title="Color Analysis"
            price={49}
            description="Available for current/available shingles only
(after the initial report is complete)"
            buttonText="Add Color Analysis"
            linkUrl="https://ntsid.com/color-analysis"
          /> */}
          <PriceCard
            active
            title="Shingle ID Report"
            price={159}
            description="Optional prepaid shipping for an additional $25"
            buttonText="Order a Report Now"
            handleAction={() =>
              navigate(`/order/create/property?report=${Reports.Shingle}`)
            }
          />
          <PriceCard
            active
            title="Siding ID Report"
            price={159}
            description="Optional prepaid shipping for an additional $25"
            buttonText="Order a Report Now"
            handleAction={() =>
              navigate(`/order/create/property?report=${Reports.Siding}`)
            }
          />
          {/* <PriceCard
            title="Tile Report"
            price={199}
            description="Tile photos only - no mailed in samples 
            "
            buttonText="Order Tile Report"
            linkUrl="https://ntsid.com/tile-reports"
          /> */}
        </div>
        <Typography className={classes.newServiceText}>
          New services coming soon!
        </Typography>
        <Typography className={classes.description}>
          The NTS team is working hard on some exciting new service offerings.
        </Typography>
      </Container>
    </div>
  );
};

export default Pricing;
