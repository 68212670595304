import { useJsApiLoader } from "@react-google-maps/api";
import cx from "classnames";
import React from "react";
import Loader from "src/components/Loader";
import Typography from "src/components/Typography";
import MobileProgressBar from "src/pages/OrderCreate/Components/MobileProgressBar";
import OrderSummary from "src/pages/OrderCreate/Components/OrderSummary";
import { useReducerData } from "src/store/hooks";
import SubscriptionSummary from "./Components/SubscriptionSummary";
import classes from "./styles.module.scss";
import { Props } from "./types";

const OrderCreate: React.FC<Props> = ({
  children,
  title,
  step,
  content,
  bgImage,
  generateLabel = true,
}) => {
  const mapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";
  const { data: user } = useReducerData("user", "user", {});
  const isCredit = user?.subscription?.credits > 0;
  const isSubscriber = user?.subscription?.status === "active";
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: mapApiKey,
    libraries: ["places"],
  });

  if (!isLoaded) {
    return (
      <div style={{ position: "relative" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={classes.mainWrapper}>
      <MobileProgressBar step={step} title={title} />
      <div
        className={classes.progressSidebar}
        style={
          step
            ? { backgroundImage: `url(${bgImage})` }
            : {
                background: `linear-gradient(167deg, #336FCD 23.91%, rgba(31, 118, 254, 0.87) 86.57%), url(${bgImage})`,
              }
        }
      >
        <Typography fontWeight="light" variant="p16" className="mb-1">
          {step ? `STEP ${step}` : "GET STARTED"}
        </Typography>
        <Typography fontWeight="semibold" variant="p28">
          {title}
        </Typography>
        <div className={classes.progressBar}>
          <div
            className={cx(classes.bar, { [classes.active]: step === 1 })}
          ></div>
          <div
            className={cx(classes.bar, { [classes.active]: step === 2 })}
          ></div>
          <div
            className={cx(classes.bar, { [classes.active]: step === 3 })}
          ></div>
        </div>
        <Typography variant="p16">{content}</Typography>
        {step === 3 && (
          <div className={classes.summaryWrapper}>
            <OrderSummary generateLabel={generateLabel} />
            {isSubscriber && <SubscriptionSummary hasCredit={isCredit} />}
          </div>
        )}
      </div>
      <div className={classes.formWrapper}>{children}</div>
    </div>
  );
};

export default OrderCreate;
