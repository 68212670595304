import moment from "moment";
import React from "react";
import Typography from "src/components/Typography";
import { useReducerData } from "src/store/hooks";
import classes from "./styles.module.scss";
import { Props } from "./types";

const OrderCTA: React.FC<Props> = ({ handleButtonClick, hasOrder }) => {
  const { orders } = useReducerData("order", "allOrders", {});

  return (
    <div className={classes.wrapper} onClick={handleButtonClick}>
      <div>
        <Typography
          className={classes.headerText}
          variant="p13"
          fontWeight="semibold"
        >
          Get started quickly
        </Typography>
        <Typography
          variant="p20"
          fontWeight="bold"
          className={classes.titleText}
        >
          Order an NTS Shingle or Siding Report now
        </Typography>
        <Typography className={classes.date} variant="p14" fontWeight="light">
          {hasOrder
            ? `Last order on ${moment(orders?.data[0]?.created_at).format(
                "MM/DD/YYYY"
              )}`
            : "You haven’t ordered any reports yet"}
        </Typography>
      </div>
      <div className={classes.orderButton}>
        <button>
          <i className="fa fa-arrow-circle-right" />
        </button>
      </div>
    </div>
  );
};

export default OrderCTA;
