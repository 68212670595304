import React, { useState } from "react";
import { useNavigate } from "react-router";
import selectReportBg from "src/assets/images/select-report-bg.png";
import Button from "src/components/Button";
import ButtonGroup from "src/components/ButtonGroup";
import CheckoutSidebar from "src/pages/OrderCreate";
import classes from "./styles.module.scss";
import { Reports } from "./types";

const reportsData = [
  { label: "Shingle ID Report", value: Reports.Shingle },
  { label: "Siding ID Report", value: Reports.Siding },
];

const SelectReport: React.FC = () => {
  const [selectedReport, setSelectedReport] = useState<Reports | null>(null);
  const navigate = useNavigate();

  const handleSelectReport = (value: Reports) => {
    setSelectedReport(value);
  };

  return (
    <CheckoutSidebar
      title="Order a Report"
      step={0}
      bgImage={selectReportBg}
      content="Tell us about the type of report you are ordering today."
    >
      <div className={classes.container}>
        <div className={classes.upperContainer}>
          <div className={classes.title}>
            First, tell us which type of report you'd like to order
          </div>
          <div className={classes.reportContainer}>
            <label className={classes.label}>Report Type</label>
            <ButtonGroup className={classes.buttonGroup}>
              {reportsData.map((report) => (
                <Button
                  key={report.label}
                  onClick={() => handleSelectReport(report.value)}
                  type="button"
                  buttonText={report.label}
                  buttonColor={
                    report.value === selectedReport ? "primary" : "secondary"
                  }
                />
              ))}
            </ButtonGroup>
          </div>
        </div>
        <div className={classes.nextStep}>
          <Button
            disabled={!selectedReport}
            buttonText="Continue to Step 1"
            rightIcon={<i className="fa fa-arrow-circle-o-right" />}
            onClick={() =>
              navigate(`/order/create/property?report=${selectedReport}`)
            }
          />
        </div>
      </div>
    </CheckoutSidebar>
  );
};

export default SelectReport;
