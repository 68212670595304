/* eslint-disable max-lines */
import { Dispatch } from "redux";

import {
  AllOrderParams,
  CustomerProps,
  EmailReportParams,
  FileReportParams,
  OrderUpdateParams,
  Params,
  addCustomersCredit as addCustomersCreditApi,
  deleteCustomer as deleteCustomerApi,
  emailReport as emailReportApi,
  exportAllCustomers as exportAllCustomersApi,
  exportAllOrder as exportAllOrderApi,
  getAllAdminOrders as getAllAdminOrdersApi,
  getAllCustomers as getAllCustomersApi,
  getOrderStatuses as getOrderStatusesApi,
  getRecentAdminOrders as getRecentAdminOrdersApi,
  updateAdminOrder as updateAdminOrderApi,
  updateCustomer as updateCustomerApi,
  uploadReport as uploadReportApi,
  verifyUser as verifyUserApi,
} from "src/api/admin";
import { toast } from "src/components/Toast/ToastManager";
import { ExportOptionValue } from "src/utils/constants";
import Types from "../types/admin";

export const getRecentAdminOrders = (params: Params) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_RECENT_ADMIN_ORDERS_STARTED,
    });
    const resp = await getRecentAdminOrdersApi(params);

    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_RECENT_ADMIN_ORDERS_SUCCESS,
        payload: { data: resp.data },
      });
    } else {
      dispatch({
        type: Types.FETCH_RECENT_ADMIN_ORDERS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getAllAdminOrders = (params: AllOrderParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_ALL_ADMIN_ORDERS_STARTED,
    });
    const resp = await getAllAdminOrdersApi(params);

    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_ALL_ADMIN_ORDERS_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.FETCH_ALL_ADMIN_ORDERS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const uploadReport = (data: FileReportParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPLOAD_REPORT_STARTED,
    });
    const resp = await uploadReportApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.UPLOAD_REPORT_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        content: "Report uploaded successfully!",
        variant: "success",
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.UPLOAD_REPORT_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const emailReport = (data: EmailReportParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.EMAIL_REPORT_STARTED,
    });
    const resp = await emailReportApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.EMAIL_REPORT_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        content: "The report was emailed to the customer!",
        variant: "success",
        showIndicationText: true,
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.EMAIL_REPORT_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getOrderStatuses = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_ORDER_STATUSES_STARTED,
    });
    const resp = await getOrderStatusesApi();

    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_ORDER_STATUSES_SUCCESS,
        payload: { data: resp.data },
      });
    } else {
      dispatch({
        type: Types.FETCH_ORDER_STATUSES_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getAllCustomers = (params: Params) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_ALL_CUSTOMERS_STARTED,
    });
    const resp = await getAllCustomersApi(params);

    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_ALL_CUSTOMERS_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.FETCH_ALL_CUSTOMERS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const addCustomersCredit = (subscription_id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.ADD_CUSTOMERS_CREDIT_STARTED,
    });
    const resp = await addCustomersCreditApi(subscription_id);

    if (resp?.status === 1) {
      dispatch({
        type: Types.ADD_CUSTOMERS_CREDIT_SUCCESS,
        payload: { data: resp.data },
      });
      toast.show({
        title: resp.message,
        variant: "success",
        position: "top-right",
        size: "lg",
        showIndicationText: true,
      });
    } else {
      dispatch({
        type: Types.ADD_CUSTOMERS_CREDIT_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        content: resp.data.error,
        variant: "danger",
      });
    }
    return resp;
  };
};

export const updateAdminOrder = (id: number, data: OrderUpdateParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_ADMIN_ORDER_BY_ID_STARTED,
    });
    const resp = await updateAdminOrderApi(id, data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.UPDATE_ADMIN_ORDER_BY_ID_SUCCESS,
        payload: { data: resp.data },
      });
    } else {
      dispatch({
        type: Types.UPDATE_ADMIN_ORDER_BY_ID_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: resp?.message,
        variant: "danger",
      });
    }
    return resp;
  };
};

export const exportAllOrder = (value: ExportOptionValue) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.EXPORT_ALL_ORDER_STARTED,
    });

    const resp = await exportAllOrderApi(value);
    if (resp?.status === 1) {
      dispatch({
        type: Types.EXPORT_ALL_ORDER_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.EXPORT_ALL_ORDER_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const exportAllCustomers = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.EXPORT_ALL_CUSTOMERS_STARTED,
    });

    const resp = await exportAllCustomersApi();
    if (resp?.status === 1) {
      dispatch({
        type: Types.EXPORT_ALL_CUSTOMERS_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.EXPORT_ALL_CUSTOMERS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const verifyUser = (userId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.RESEND_VERIFICATION_EMAIL_STARTED,
    });

    const resp = await verifyUserApi(userId);
    if (resp?.status === 1) {
      dispatch({
        type: Types.RESEND_VERIFICATION_EMAIL_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        title: resp?.message,
        variant: "success",
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.RESEND_VERIFICATION_EMAIL_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: resp?.data?.error,
        variant: "danger",
        position: "top-right",
      });
    }
    return resp;
  };
};

export const deleteCustomer = (user_id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_CUSTOMER_STARTED,
    });

    const resp = await deleteCustomerApi(user_id);
    if (resp?.status === 1) {
      dispatch({
        type: Types.DELETE_CUSTOMER_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        title: resp?.message,
        variant: "success",
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.DELETE_CUSTOMER_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: resp?.data?.error,
        variant: "danger",
        position: "top-right",
      });
    }
    return resp;
  };
};

export const updateCustomer = (user_id: number, data: CustomerProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_CUSTOMER_STARTED,
    });

    const resp = await updateCustomerApi(user_id, data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.UPDATE_CUSTOMER_SUCCESS,
        payload: { data: resp },
      });
      toast.show({
        title: resp?.message,
        variant: "success",
        position: "top-right",
      });
    } else {
      dispatch({
        type: Types.UPDATE_CUSTOMER_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: resp?.data?.error,
        variant: "danger",
        position: "top-right",
      });
    }
    return resp;
  };
};
