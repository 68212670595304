import {
  faAt,
  faBuilding,
  faLocationDot,
  faSquarePhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Card from "src/components/Card";
import Typography from "src/components/Typography";
import { OrderDetailsProps } from "src/pages/OrderDetails/types";
import classes from "./styles.module.scss";

const ContactInfo: React.FC<OrderDetailsProps> = (data) => {
  return (
    <Card>
      <div className={classes.contactInfo}>
        <Typography className="text-start" fontWeight="semibold" variant="p18">
          Contact Information
        </Typography>
        <div className={classes.contactInfoContent}>
          <div className="d-flex gap-3">
            <FontAwesomeIcon icon={faUser} />
            <div>
              <Typography
                className="text-start mb-0 text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                Representative Name
              </Typography>
              <Typography
                className="text-start mb-0"
                fontWeight="semibold"
                variant="p16"
              >
                {data?.rep_name || (
                  <span className={classes.providedLabel}>Not Provided</span>
                )}
              </Typography>
            </div>
          </div>
          <div className="d-flex gap-3">
            <FontAwesomeIcon icon={faBuilding} />
            <div>
              <Typography
                className="text-start mb-0 text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                Company Name
              </Typography>
              <Typography
                className="text-start mb-0"
                fontWeight="semibold"
                variant="p16"
              >
                {data?.company_name || (
                  <span className={classes.providedLabel}>Not Provided</span>
                )}
              </Typography>
            </div>
          </div>
          <div className="d-flex gap-3">
            <FontAwesomeIcon icon={faAt} />
            <div>
              <Typography
                className="text-start mb-0 text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                Representative Email
              </Typography>
              <Typography
                className="text-start mb-0 text-break"
                fontWeight="semibold"
                variant="p16"
              >
                {data?.rep_email || (
                  <span className={classes.providedLabel}>Not Provided</span>
                )}
              </Typography>
            </div>
          </div>
          <div className="d-flex gap-3">
            <FontAwesomeIcon icon={faLocationDot} />
            <div>
              <Typography
                className="text-start mb-0  text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                Company address
              </Typography>
              <Typography
                className="text-start mb-0"
                fontWeight="semibold"
                variant="p16"
              >
                {data?.company_address || (
                  <span className={classes.providedLabel}>Not Provided</span>
                )}
              </Typography>
            </div>
          </div>
          <div className="d-flex gap-3">
            <FontAwesomeIcon icon={faSquarePhone} />
            <div>
              <Typography
                className="text-start mb-0  text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                Representative Phone
              </Typography>
              <Typography
                className="text-start mb-0"
                fontWeight="semibold"
                variant="p16"
              >
                {data?.rep_phone || (
                  <span className={classes.providedLabel}>Not Provided</span>
                )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ContactInfo;
