import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormGroup } from "reactstrap";
import GoogleAutoCompleteInput from "src/components/GoogleAutoCompleteInput";
import Input from "src/components/Input";
import Select from "src/components/Select";
import { AddressProps } from "../CheckoutForm/types";
import { stateOptions } from "../PropertyForm/constants";
import { OptionType } from "../PropertyForm/types";
import classes from "./styles.module.scss";
import { Props } from "./types";

const options = {
  fields: [
    "address_components",
    "geometry",
    "name",
    "formatted_address",
    "adr_address",
  ],
  types: ["street_address"],
  componentRestrictions: { country: "us" },
};

const FormikAddress: React.FC<Props> = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  inputNames,
  inputAddressPlaceholder,
  inputAddressLabel,
  inputId = "",
  googleAutoComplete = false,
}) => {
  // eslint-disable-next-line react/no-multi-comp
  const DropdownIndicator = () => (
    <span className={classes.caret}>
      <FontAwesomeIcon icon={faCaretDown} />
    </span>
  );

  return (
    <div className={classes.formWrapper}>
      <FormGroup className={classes.formGroup}>
        {googleAutoComplete && (
          <GoogleAutoCompleteInput
            inputGroupClassName={classes.inputGroupStyle}
            required={true}
            labelClassName={classes.label}
            label={inputAddressLabel}
            options={options}
            value={values.address}
            onPlaceSelected={(value: {
              address_components:
                | google.maps.GeocoderAddressComponent[]
                | undefined;
              formatted_address: string;
            }) => {
              if (setFieldTouched) {
                setFieldTouched(inputNames.city, false, true);
                setFieldTouched(inputNames.state, false, true);
                setFieldTouched(inputNames.zip_code, false, true);
              }
              const labelObj = {
                city: inputNames.city,
                state: inputNames.state,
                zipcode: inputNames.zip_code,
              };

              const address:
                | google.maps.GeocoderAddressComponent[]
                | undefined = value.address_components;
              address?.forEach((addressValue: AddressProps) => {
                addressValue.types.forEach((type) => {
                  if (setFieldValue) {
                    switch (type) {
                      case "locality":
                        return setFieldValue(
                          labelObj.city,
                          addressValue?.long_name
                        );
                      case "administrative_area_level_1": {
                        const state = stateOptions.find((el) =>
                          addressValue.short_name.includes(el.value)
                        );
                        return setFieldValue(
                          labelObj.state,
                          state as OptionType
                        );
                      }
                      case "postal_code":
                        return setFieldValue(
                          labelObj.zipcode,
                          addressValue?.long_name
                        );
                      default:
                        return;
                    }
                  }
                });
              });

              const addressVal: string[] = [];
              address?.map((el) => {
                if (
                  el.types[0] === "street_number" ||
                  el.types[0] === "route"
                ) {
                  addressVal.push(el.short_name);
                }
              });
              if (setFieldValue) {
                setFieldValue(inputNames.address, addressVal.join(" "));
              }
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors?.address as unknown as string}
            touched={touched?.address}
            name={inputNames.address}
          />
        )}
        {!googleAutoComplete && (
          <Input
            inputId={inputId}
            inputGroupClassName={classes.inputGroupStyle}
            required={true}
            labelClassName={classes.label}
            label={inputAddressLabel}
            value={values?.address}
            isAutoComplete={googleAutoComplete && "new-address"}
            placeholder={inputAddressPlaceholder}
            onChange={(value) => {
              if (setFieldValue) {
                setFieldValue(inputNames.address, value.target.value);
              }
            }}
            onBlur={handleBlur}
            error={errors?.address as unknown as string}
            touched={touched?.address}
            name={inputNames.address}
          />
        )}
      </FormGroup>
      <div className={classes.locationDetails}>
        <FormGroup className={classes.formGroup}>
          <Input
            inputGroupClassName={classes.inputGroupStyle}
            labelClassName={classes.label}
            value={values?.city}
            placeholder="City"
            onChange={handleChange}
            onBlur={handleBlur}
            isAutoComplete={googleAutoComplete && "new-address"}
            error={errors.city as unknown as string}
            touched={touched?.city}
            name={inputNames.city}
          />
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <Select
            components={{
              DropdownIndicator: DropdownIndicator,
            }}
            name={inputNames.state}
            placeholder="State"
            value={values?.state}
            error={errors?.state as string}
            touched={touched?.state as unknown as boolean}
            leftIconClass={classes.icon}
            onBlur={() => {
              if (setFieldTouched) {
                setFieldTouched(inputNames.state, true);
              }
            }}
            onChange={(selectedOption) => {
              if (setFieldValue) {
                setFieldValue(inputNames.state, selectedOption);
              }
            }}
            options={stateOptions}
          />
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <Input
            inputGroupClassName={classes.inputGroupStyle}
            labelClassName={classes.label}
            value={values?.zip_code}
            isAutoComplete={googleAutoComplete && "new-address"}
            placeholder="Zip Code"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.zip_code as unknown as string}
            touched={touched?.zip_code}
            name={inputNames.zip_code}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default FormikAddress;
