import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Logo from "src/assets/icons/logo.svg";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";
import { Props } from "./types";

const sidebarItems = [
  {
    id: 1,
    name: "Dashboard",
    slug: "admin/dashboard",
    icon: <i className="fa fa-th-large" />,
  },
  {
    id: 2,
    name: "Orders",
    slug: "admin/orders",
    icon: (
      <svg
        width="22"
        height="17"
        viewBox="0 0 15 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.76025 0.25H0.885254V18.25H14.3853V5.875H8.76025V0.25ZM9.88525 0.25V4.75H14.3853L9.88525 0.25ZM10.4478 10.9375C10.4478 11.5703 10.272 12.168 9.95557 12.6602L11.3267 14.0312L11.9243 14.6289L10.729 15.8242L10.1313 15.2266L8.76025 13.8555C8.26807 14.1719 7.67041 14.3125 7.07275 14.3125C5.20947 14.3125 3.69775 12.8008 3.69775 10.9375C3.69775 9.07422 5.20947 7.5625 7.07275 7.5625C8.93604 7.5625 10.4478 9.07422 10.4478 10.9375ZM7.07275 12.625C7.98682 12.625 8.76025 11.8867 8.76025 10.9375C8.76025 10.0234 7.98682 9.25 7.07275 9.25C6.12354 9.25 5.38525 10.0234 5.38525 10.9375C5.38525 11.8867 6.12354 12.625 7.07275 12.625Z"
          fill="#212529"
        />
      </svg>
    ),
  },
  {
    id: 3,
    name: "Customers",
    slug: "admin/customers",
    icon: (
      <svg
        width="22"
        height="17"
        viewBox="0 0 22 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.25 0.375H8.75V1.60547V5.4375L6.46484 2.27344C3.82812 3.53906 2 6.24609 2 9.375V11.625H20V9.30469C19.9648 6.21094 18.1367 3.53906 15.5 2.27344L13.25 5.4375V1.60547V0.375ZM0.875 13.5938C0.875 13.5938 3.6875 16.125 11 16.125C18.3125 16.125 21.125 13.5938 21.125 13.5938V12.75H0.875V13.5938Z"
          fill="#525F7F"
        />
      </svg>
    ),
  },
  {
    id: 4,
    name: "Settings",
    slug: "admin/settings",
    icon: <i className="fa fa-cog" />,
  },
];

const Sidebar: React.FC<Props> = ({ showSidebar, handleSidebarToggle }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState<number>(1);

  useEffect(() => {
    const initialTab = sidebarItems.find((el) =>
      pathname.includes(el.slug)
    )?.id;

    setActiveTab(initialTab || 1);
  }, [pathname]);

  return (
    <div className={cx(classes.sidebar, { [classes.isActive]: showSidebar })}>
      <div
        className={cx(classes.icon, { [classes.sidebarBurger]: showSidebar })}
      >
        <img alt="logo" src={Logo} />
        {showSidebar && (
          <div className={classes.closeIcon} onClick={handleSidebarToggle}>
            <i className="fa fa-bars" />
          </div>
        )}
      </div>
      <div className={classes.sidebarContent}>
        {sidebarItems.map(({ id, name, icon, slug }) => {
          return (
            <div
              key={id}
              onClick={() => {
                setActiveTab(id);
                handleSidebarToggle();
                navigate(`/${slug}`);
              }}
              className={cx(classes.sidebarItem, {
                [classes.active]: id === activeTab,
              })}
            >
              <span>{icon}</span>
              <Typography
                variant="p18"
                fontWeight={id === activeTab ? "bold" : "regular"}
                className="mb-0"
              >
                {name}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
