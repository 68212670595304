import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import Logo from "src/assets/icons/logo.svg";
import CheckEmail from "./CheckEmail";
import DefaultForgotPassword from "./DefaultForgotPassword";
import classes from "./styles.module.scss";

const ForgotPassword = () => {
  const [tab, setTab] = useState<string>();
  const { params = "" } = useParams();

  useEffect(() => {
    setTab(params);
  }, [params]);

  const renderForgotPasswordCard = () => {
    switch (tab) {
      case "check-email":
        return <CheckEmail />;
      default:
        return <DefaultForgotPassword />;
    }
  };

  return (
    <Container className={classes.container}>
      <div
        className={cx(
          classes.forgotPasswordWrapper,
          "d-flex flex-column  align-items-center justify-content-center"
        )}
      >
        <div className={classes.logo}>
          <Link to={"/auth/login"}>
            <img alt="logo" src={Logo} />
          </Link>
        </div>
        <div className={classes.title}>
          <h3>Forgot your password?</h3>
          <p>
            Enter your email address below and we’ll send you instructions on
            how to reset it.
          </p>
        </div>
      </div>
      {renderForgotPasswordCard()}
    </Container>
  );
};

export default ForgotPassword;
