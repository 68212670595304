/* eslint-disable max-lines */
import {
  faEdit,
  faTrash,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AllOrderParams } from "src/api/admin";
import cardDollar from "src/assets/icons/card-dollar.svg";
import { ReactComponent as TermsAccepted } from "src/assets/icons/terms_accepted.svg";
import { alertPopup } from "src/components/AlertPopup/AlertPopupManager";
import Button from "src/components/Button";
import Table from "src/components/Table";
import Typography from "src/components/Typography";
import {
  addCustomersCredit,
  deleteCustomer,
  exportAllCustomers,
  getAllCustomers,
  verifyUser,
} from "src/store/actions/admin";
import { useReducerData, useStoreActions } from "src/store/hooks";
import CustomerEditForm from "./Components/CustomerEditForm";
import classes from "./styles.module.scss";
import { CustomerInfo, RowProps } from "./types";
const Customers = () => {
  const navigate = useNavigate();
  const {
    data: { data },
    loading,
  } = useReducerData("admin", "allCustomers", {
    data: { data: [] },
  });

  const { loading: resendEmailLoading } = useReducerData(
    "admin",
    "resendVerification",
    {
      loading: false,
    }
  );

  const {
    data: { meta: allCustomersMeta },
  } = useReducerData("admin", "allCustomers", { data: { meta: [] } });

  const [sortState, setSortState] = useState<AllOrderParams>({
    page: 1,
    sort_by: "company_name",
    sort_order: "asc",
  });
  const [searchString, setSearchString] = useState<string | null>();
  const [openFormModal, setOpenFormModal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(
    null
  );
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });

  const actions = useStoreActions({
    getAllCustomers,
    addCustomersCredit,
    exportAllCustomers,
    verifyUser,
    deleteCustomer,
  });

  useEffect(() => {
    actions.getAllCustomers(sortState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortState]);

  const handleSort = (id: string) => {
    setSortState({
      ...sortState,
      sort_by: id,
      sort_order: sortState.sort_order === "asc" ? "desc" : "asc",
    });
  };

  const handleAddCredit = (row: RowProps) => {
    alertPopup.showCustom({
      warning: true,
      onConfirm: () => {},
      title: `Are you sure you want to add 1 credit to ${
        row?.company_name || "company name"
      }?`,
      children: "This action can not be undone",
      customButtonList: [
        {
          buttonText: "Yes, add credit",
          buttonColor: "success",
          handleConfirm: async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { status } = await actions.addCustomersCredit(
              row?.subscription_id
            );
            if (status) {
              actions.getAllCustomers(sortState);
            }
          },
        },
        {
          buttonText: "Don’t add credit",
          buttonColor: "danger",
          handleConfirm: () => {},
        },
      ],
    });
  };

  const handleHeaderButton = async () => {
    const reports = await actions.exportAllCustomers();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url = window.URL.createObjectURL(new Blob([reports.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "customers.xlsx");
    document.body.appendChild(link);
    link.click();
  };

  const handleEmailSend = async (email: string, id: number | null) => {
    setSelectedCustomerId(id);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.verifyUser(id);
    if (status === 1) {
      actions.getAllCustomers(sortState);
    }
  };

  const handleCustomerDelete = (userId: number) => {
    alertPopup.showCustom({
      warning: true,
      onConfirm: () => {},
      title: `Are you sure you want to delete this customer ?`,
      children: "This action can not be undone",
      customButtonList: [
        {
          buttonText: "Yes, delete customer",
          buttonColor: "success",
          handleConfirm: async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { status } = await actions.deleteCustomer(userId);
            if (status === 1) {
              actions.getAllCustomers(sortState);
            }
          },
        },
        {
          buttonText: "Cancel",
          buttonColor: "danger",
          handleConfirm: () => {},
        },
      ],
    });
  };

  const onPageChange = async (page: number) => {
    setSortState({ ...sortState, page: page });
  };

  const handleSearch = (searchText: string) => {
    let page = sortState.page;
    if (searchText !== searchString) {
      page = 1;
    }
    setSearchString(searchText);
    const params = {
      ...sortState,
      page,
      keyword: searchText,
    };
    setSortState(params);
    actions.getAllCustomers(params);
  };

  return (
    <>
      <CustomerEditForm
        isOpen={openFormModal}
        onToggle={() => setOpenFormModal(!openFormModal)}
        customerInfo={customerInfo}
        sortState={sortState}
      />
      <div className={classes.customerWrapper}>
        {
          <Table
            search
            searchProps={{
              onSearch: handleSearch,
              searchPlaceholder: "Search Customer",
            }}
            showPagination={
              allCustomersMeta?.per_page < allCustomersMeta?.total
            }
            paginationOption={{
              currentPage: allCustomersMeta?.current_page,
              itemPerPage: allCustomersMeta?.per_page,
              totalItems: allCustomersMeta?.total,
              from: allCustomersMeta?.from,
              to: allCustomersMeta?.to,
            }}
            onPageChange={onPageChange}
            handleSort={handleSort}
            data={data}
            loading={loading}
            showHeaderButton
            showHeaderButtonText="Export all Customers"
            handleHeaderButton={handleHeaderButton}
            columns={[
              {
                Header: "COMPANY NAME",
                accessor: "company_name",
                // eslint-disable-next-line react/no-multi-comp
                Cell: (props) => {
                  return (
                    <div className={"d-flex align-items-center"}>
                      <Typography
                        className={cx("mb-0", classes.companyName)}
                        variant="p16"
                      >
                        {props?.row?.original?.company_name ? (
                          props?.row?.original?.company_name
                        ) : (
                          <span className={classes.noData}>n/a</span>
                        )}
                      </Typography>
                      {props?.row?.original?.terms_accepted && (
                        <span>
                          <TermsAccepted style={{ minWidth: "10px" }} />
                        </span>
                      )}
                    </div>
                  );
                },
              },
              {
                Header: "REP NAME",
                accessor: "rep_name",
                // eslint-disable-next-line react/no-multi-comp
                Cell: (props) => {
                  return (
                    <Typography className="mb-0" variant="p16">
                      {props?.row?.original?.rep_name}
                    </Typography>
                  );
                },
              },
              {
                Header: "REP PHONE",
                accessor: "rep_phone",
                // eslint-disable-next-line react/no-multi-comp
                Cell: ({ value }) => {
                  return (
                    <Typography className="mb-0" variant="p16">
                      {value ? (
                        value
                      ) : (
                        <span className={classes.noData}>n/a</span>
                      )}
                    </Typography>
                  );
                },
              },
              {
                Header: "SUBSCRIPTION DETAILS",
                accessor: "subscription_details",
                // eslint-disable-next-line react/no-multi-comp
                Cell: ({ value, row }) => {
                  return (
                    <div className={classes.subscriptionDetails}>
                      <Typography className="mb-0" variant="p16">
                        {value ? (
                          value
                        ) : (
                          <span className={classes.noData}>
                            Not a subscriber
                          </span>
                        )}
                      </Typography>
                      {value && (
                        <div className={classes.buttonWrapper}>
                          <Button
                            size="sm"
                            leftIcon={
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7 0.1875C3.36328 0.1875 0.4375 3.11328 0.4375 6.75C0.4375 10.3867 3.36328 13.3125 7 13.3125C10.6367 13.3125 13.5625 10.3867 13.5625 6.75C13.5625 3.11328 10.6367 0.1875 7 0.1875ZM7 12C4.10156 12 1.75 9.64844 1.75 6.75C1.75 3.85156 4.10156 1.5 7 1.5C9.89844 1.5 12.25 3.85156 12.25 6.75C12.25 9.64844 9.89844 12 7 12ZM9.625 6.09375H7.65625V4.125C7.65625 3.76953 7.35547 3.46875 7 3.46875C6.64453 3.46875 6.34375 3.76953 6.34375 4.125V6.09375H4.375C4.01953 6.09375 3.71875 6.39453 3.71875 6.75C3.71875 7.10547 4.01953 7.40625 4.375 7.40625H6.34375V9.375C6.34375 9.73047 6.64453 10.0312 7 10.0312C7.35547 10.0312 7.65625 9.73047 7.65625 9.375V7.40625H9.625C9.98047 7.40625 10.2812 7.10547 10.2812 6.75C10.2812 6.39453 9.98047 6.09375 9.625 6.09375Z"
                                  fill="white"
                                />
                              </svg>
                            }
                            buttonText="Add Credit"
                            onClick={() => {
                              handleAddCredit(row.original);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                },
              },
              {
                Header: "ACTIONS",
                accessor: "email_verified",
                // eslint-disable-next-line react/no-multi-comp
                Cell: ({ row }) => {
                  return (
                    <>
                      <div
                        className={cx(
                          classes.actionIcons,
                          "d-flex align-items-center gap-3 justify-content-end"
                        )}
                      >
                        {!!row?.original?.subscription_id && (
                          <div
                            className={classes.orderButton}
                            onClick={() =>
                              navigate(
                                `/admin/plans?client_id=${row?.original?.id}`
                              )
                            }
                          >
                            <button>
                              <img alt="dollar icons" src={cardDollar} />
                            </button>
                          </div>
                        )}
                        {!row?.original?.email_verified && (
                          <div
                            onClick={() =>
                              handleEmailSend(
                                row?.original?.email,
                                row?.original?.id
                              )
                            }
                            className={cx({
                              [classes.disable]:
                                resendEmailLoading &&
                                selectedCustomerId === row?.original?.id,
                            })}
                          >
                            <FontAwesomeIcon
                              className={classes.email}
                              icon={faUserCheck}
                            />
                          </div>
                        )}
                        <div
                          onClick={() => {
                            setCustomerInfo({
                              ...customerInfo,
                              first_name: row?.original?.first_name || "",
                              last_name: row?.original?.last_name || "",
                              email: row?.original?.email || "",
                              phone: row?.original?.phone || "",
                              id: row?.original?.id || null,
                            });
                            setOpenFormModal(true);
                          }}
                        >
                          <FontAwesomeIcon
                            className={classes.edit}
                            icon={faEdit}
                          />
                        </div>
                        <div
                          onClick={() =>
                            handleCustomerDelete(row?.original?.id)
                          }
                        >
                          <FontAwesomeIcon
                            className={classes.delete}
                            icon={faTrash}
                          />
                        </div>
                      </div>
                    </>
                  );
                },
              },
            ]}
            handleRowClick={() => {}}
            tableHeaderText="All Customers"
          />
        }
      </div>
    </>
  );
};

export default Customers;
