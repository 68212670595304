import cx from "classnames";
import React from "react";
import { Spinner } from "reactstrap";
import classes from "./styles.module.scss";

type LoaderProps = {
  loaderClass?: string;
};

const Loader: React.FC<LoaderProps> = ({ loaderClass }) => {
  return (
    <div className={cx(classes.loaderWrapper, loaderClass)}>
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default Loader;
