import { Request } from "./request";

export type ContactInfoProps = {
  name: string;
  email: string;
  msg: string;
};

export const postUserContactInfo = async (data: ContactInfoProps) => {
  return Request.call({
    url: "/api/contact/inquiries",
    method: "POST",
    data,
  });
};
