import {
  faAt,
  faFileLines,
  faHouseChimneyCrack,
  faHouseChimneyWindow,
  faPersonShelter,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useState } from "react";
import Button from "src/components/Button";
import Card from "src/components/Card";
import OrderStatus from "src/components/OrderStatus";
import Typography from "src/components/Typography";
import { OrderDetailsProps } from "src/pages/OrderDetails/types";
import { getReportFile } from "src/store/actions/order";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { getFullAddress } from "src/utils/utils";
import PreviewFileModal from "../PreviewFileModal";
import StreetView from "../StreetView";
import classes from "./styles.module.scss";

const OrderInfo: React.FC<OrderDetailsProps> = (data) => {
  const { data: user } = useReducerData("user", "user", {});
  const [showReportFileModal, setShowReportFileModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const isAdmin = user?.is_admin === 1;
  const isSiding = data.report_type === "Siding ID Report";
  const reportType =
    data.report_type === "Siding ID Report" ? "Siding" : "Shingle";
  const actions = useStoreActions({ getReportFile });
  const onToggle = () => {
    setShowReportFileModal((prev) => !prev);
  };

  const downloadReportFile = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    const reportFile = await actions.getReportFile(data.id as number);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url = window.URL.createObjectURL(new Blob([reportFile.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report_file.pdf");
    document.body.appendChild(link);
    link.click();
    setIsDownloading(false);
  };

  return (
    <Card>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.orderInfo}>
            <div className="d-flex align-items-start gap-2 mb-3 flex-column-reverse flex-lg-row align-items-lg-center justify-content-between">
              <div className="d-flex gap-1 align-items-center">
                <Typography className="mb-0" variant="p20" fontWeight="medium">
                  Order{" "}
                  {data?.property_detail?.order_id || (
                    <span className={classes.providedLabel}>Not Provided</span>
                  )}
                </Typography>
                <OrderStatus
                  variant={
                    isAdmin ? data?.order_status?.name : data?.report_status
                  }
                >
                  {isAdmin ? data?.order_status?.name : data?.report_status}
                </OrderStatus>
                <OrderStatus
                  variant={
                    isAdmin ? data?.order_status?.name : data?.report_status
                  }
                >
                  {reportType}
                </OrderStatus>
              </div>
              {data?.report_sent && !isAdmin && (
                <div className="d-flex gap-1">
                  <Button
                    buttonClassName={cx(classes.orderAction)}
                    size="sm"
                    buttonText={"View"}
                    onClick={onToggle}
                  />
                  <Button
                    buttonClassName={cx(classes.orderAction)}
                    size="sm"
                    buttonText={"Download"}
                    loading={isDownloading}
                    onClick={downloadReportFile}
                  />
                </div>
              )}
            </div>
            <Typography
              className="text-start mb-0"
              fontWeight="bold"
              variant="p32"
            >
              {data?.property_detail?.address || "Not Provided"} ,{" "}
              {data.property_detail?.city || "Not Provided"}{" "}
              {data.property_detail?.state || "Not Provided"}{" "}
              {data.property_detail?.zip_code || "Not Provided"}
            </Typography>
          </div>
          <div className={classes.propertyInfo}>
            <Typography
              className={cx(classes.propertyInfoText, "text-start")}
              fontWeight="semibold"
              variant="p18"
            >
              Property Information
            </Typography>
            <div className={classes.propertyContent}>
              <div className="d-flex gap-3">
                <FontAwesomeIcon icon={faHouseChimneyWindow} />
                <div>
                  <Typography
                    className="text-start mb-0 text-uppercase"
                    variant="p12"
                    fontWeight="medium"
                  >
                    {isSiding ? "Siding Material Type" : "Property Type"}
                  </Typography>
                  <Typography
                    className={cx("text-start mb-0", classes.propertyType)}
                    fontWeight="semibold"
                    variant="p16"
                  >
                    {(isSiding
                      ? data?.property_detail?.siding_material_type
                      : data?.property_detail?.property_type) || (
                      <span className={classes.providedLabel}>
                        Not Provided
                      </span>
                    )}
                  </Typography>
                </div>
              </div>
              <div className="d-flex gap-3">
                <FontAwesomeIcon icon={faPersonShelter} />
                <div>
                  <Typography
                    className="text-start mb-0 text-uppercase"
                    variant="p12"
                    fontWeight="medium"
                  >
                    Property Owner
                  </Typography>
                  <Typography
                    className="text-start mb-0"
                    fontWeight="semibold"
                    variant="p16"
                  >
                    {data?.property_detail?.owner_name || (
                      <span className={classes.providedLabel}>
                        Not Provided
                      </span>
                    )}
                  </Typography>
                </div>
              </div>
              <div className="d-flex gap-3">
                <FontAwesomeIcon icon={faRulerCombined} />
                <div>
                  <Typography
                    className="text-start mb-0 text-uppercase"
                    variant="p12"
                    fontWeight="medium"
                  >
                    {isSiding ? "Home" : "Roof"} Sqft
                  </Typography>
                  <Typography
                    className="text-start mb-0"
                    fontWeight="semibold"
                    variant="p16"
                  >
                    {(isSiding
                      ? data?.property_detail?.home_square
                      : data?.property_detail?.roof_square) || (
                      <span className={classes.providedLabel}>
                        Not Provided
                      </span>
                    )}
                  </Typography>
                </div>
              </div>
              <div className="d-flex gap-3">
                <FontAwesomeIcon icon={faAt} />
                <div>
                  <Typography
                    className="text-start mb-0  text-uppercase"
                    variant="p12"
                    fontWeight="medium"
                  >
                    property owner email
                  </Typography>
                  <Typography
                    className="text-start mb-0 text-break"
                    fontWeight="semibold"
                    variant="p16"
                  >
                    {data?.property_detail?.owner_email || (
                      <span className={classes.providedLabel}>
                        Not Provided
                      </span>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.insuranceInfo}>
            <Typography
              className={cx(classes.insuranceInfoText, "text-start")}
              fontWeight="semibold"
              variant="p18"
            >
              Insurance Information
            </Typography>
            <div className={classes.insuranceInfoContent}>
              <div className="d-flex gap-3">
                <FontAwesomeIcon icon={faHouseChimneyCrack} />
                <div>
                  <Typography
                    className="text-start mb-0 text-uppercase"
                    variant="p12"
                    fontWeight="medium"
                  >
                    insurance co
                  </Typography>
                  <Typography
                    className="text-start mb-0"
                    fontWeight="semibold"
                    variant="p16"
                  >
                    {data?.property_detail?.other_insurance_company
                      ? data?.property_detail?.other_insurance_company
                      : data?.property_detail?.insurance_company?.name || (
                          <span className={classes.providedLabel}>
                            Not Provided
                          </span>
                        )}
                  </Typography>
                </div>
              </div>
              <div className="d-flex gap-3">
                <FontAwesomeIcon icon={faFileLines} />
                <div>
                  <Typography
                    className="text-start mb-0 text-uppercase"
                    variant="p12"
                    fontWeight="medium"
                  >
                    insurance claim #
                  </Typography>
                  <Typography
                    className="text-start mb-0"
                    fontWeight="semibold"
                    variant="p16"
                  >
                    {data?.property_detail?.insurance_claim_number || (
                      <span className={classes.providedLabel}>
                        Not Provided
                      </span>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.map}>
          {data?.property_detail?.address && (
            <StreetView
              propertyAddress={getFullAddress(data?.property_detail)}
            />
          )}
        </div>
      </div>
      {showReportFileModal && (
        <PreviewFileModal
          isOpen={showReportFileModal}
          onToggle={onToggle}
          orderId={data?.id || 0}
        />
      )}
    </Card>
  );
};

export default OrderInfo;
