import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "src/assets/icons/profile-icon.png";
import { logoutUser } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import Dropdown from "../Dropdown";
import SupportModal from "./SupportModal";
import classes from "./styles.module.scss";

const dropdownItems = [
  { value: "settings", label: "Settings", icon: <i className="fa fa-cog" /> },
  {
    value: "support",
    label: "Support",
    icon: <i className="fa fa-info-circle" />,
  },
  {
    value: "logout",
    label: "Log Out",
    icon: <i className="fa fa-sign-out" />,
    divider: true,
  },
];

const UserDropdown = () => {
  const navigate = useNavigate();
  const actions = useStoreActions({ logoutUser });

  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const { data: user } = useReducerData("user", "user", {});
  const { first_name = "", last_name = "" } = user;

  const handleChange = (label: string, value: string) => {
    if (value === "logout") {
      actions.logoutUser();
      window.location.href = "/auth/login";
      return;
    } else if (value === "settings") {
      if (user.is_admin) {
        navigate("/admin/settings");
      } else {
        navigate("/settings");
      }
    } else if (value === "support") {
      setIsSupportModalOpen(true);
    }
  };

  const element = (
    <div className={classes.userProfile}>
      <div className={classes.userImage}>
        <img alt="logo" src={ProfileIcon} />
      </div>
      <div className={classes.username}>
        {first_name} {last_name}
      </div>
    </div>
  );
  return (
    <>
      {isSupportModalOpen && (
        <SupportModal
          isOpen={isSupportModalOpen}
          onToggle={() => setIsSupportModalOpen(!isSupportModalOpen)}
        />
      )}
      <Dropdown
        justifyText="start"
        dropdownToggleButtonClass={classes.dropdownToggleButton}
        dropdownHeaderClass={classes.userDropdown}
        dropdownItemClass={classes.userDropdownItem}
        options={dropdownItems}
        headerText="WELCOME!"
        text={element}
        handleChange={handleChange}
      />
    </>
  );
};

export default UserDropdown;
