import {
  faCreditCardAlt,
  faLocationDot,
  faSquarePhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ShippingIcon from "src/assets/icons/shipping-icon.svg";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Typography from "src/components/Typography";
import { OrderDetailsProps } from "src/pages/OrderDetails/types";
import { getShippingLabel } from "src/store/actions/order";
import { useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";

const PaymentInfo: React.FC<OrderDetailsProps> = (data) => {
  const [loading, setLoading] = useState(false);
  const actions = useStoreActions({ getShippingLabel });

  const handleDownloadShippingLabel = async () => {
    if (loading) return;
    setLoading(true);
    const shippingLabel = await actions.getShippingLabel(
      data?.property_detail?.order_id as number
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const url = window.URL.createObjectURL(new Blob([shippingLabel.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "shipping_label.png");
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  };

  return (
    <Card>
      <div className={classes.paymentInfo}>
        <Typography className="text-start" fontWeight="semibold" variant="p18">
          Shipping & Payment Information
        </Typography>
        <div className={classes.paymentInfoContent}>
          <div className={classes.shippingDetails}>
            <div className="d-flex gap-3">
              <FontAwesomeIcon icon={faCreditCardAlt} />
              <div>
                <Typography
                  className="text-start mb-0 text-uppercase"
                  variant="p12"
                  fontWeight="medium"
                >
                  Payment information
                </Typography>
                <Typography
                  className="text-start mb-0"
                  fontWeight="semibold"
                  variant="p16"
                >
                  Visa **** **** ****
                  {data?.payment_details?.card_last_digit || (
                    <span className={classes.providedLabel}>Not Provided</span>
                  )}
                </Typography>
              </div>
            </div>
            <div className="d-flex gap-3">
              <FontAwesomeIcon icon={faUser} />
              <div>
                <Typography
                  className="text-start mb-0 text-uppercase"
                  variant="p12"
                  fontWeight="medium"
                >
                  Contact Name
                </Typography>
                <Typography
                  className="text-start mb-0"
                  fontWeight="semibold"
                  variant="p16"
                >
                  {data?.shipping_details?.contact_name || (
                    <span className={classes.providedLabel}>Not Provided</span>
                  )}
                </Typography>
              </div>
            </div>
            <div className="d-flex gap-3">
              <FontAwesomeIcon icon={faSquarePhone} />
              <div>
                <Typography
                  className="text-start mb-0  text-uppercase"
                  variant="p12"
                  fontWeight="medium"
                >
                  Contact Phone
                </Typography>
                <Typography
                  className="text-start mb-0"
                  fontWeight="semibold"
                  variant="p16"
                >
                  {data?.shipping_details?.contact_number || (
                    <span className={classes.providedLabel}>Not Provided</span>
                  )}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.shippingAddress}>
            <div className="d-flex gap-2">
              <FontAwesomeIcon icon={faLocationDot} />
              <div>
                <Typography
                  className="text-start mb-0 text-uppercase"
                  variant="p12"
                  fontWeight="medium"
                >
                  Shipping from address
                </Typography>
                <Typography
                  className="text-start mb-0"
                  fontWeight="semibold"
                  variant="p16"
                >
                  {data?.shipping_details?.shipping_address &&
                  data?.shipping_details?.shipping_city &&
                  data?.shipping_details?.shipping_state &&
                  data?.shipping_details?.shipping_zipcode ? (
                    `${data?.shipping_details?.shipping_address}, ${data?.shipping_details?.shipping_city} ${data?.shipping_details?.shipping_state} ${data?.shipping_details?.shipping_zipcode}`
                  ) : (
                    <span className={classes.providedLabel}>Not Provided</span>
                  )}
                </Typography>
              </div>
            </div>
            <div className="d-flex gap-3">
              <img alt="label" src={ShippingIcon} />
              <div className={classes.link}>
                <Typography
                  className="text-start mb-0 text-uppercase"
                  variant="p12"
                  fontWeight="medium"
                >
                  Prepaid shipping
                </Typography>
                <Typography
                  className="text-start mb-0"
                  fontWeight="semibold"
                  variant="p16"
                >
                  {data?.shipping_label ? (
                    <Button
                      disabled={loading}
                      buttonText="Reprint label"
                      variant="link"
                      onClick={handleDownloadShippingLabel}
                      rightIcon={<i className="fa fa-external-link" />}
                    />
                  ) : (
                    <Typography
                      className={classes.label}
                      variant="p16"
                      fontWeight="medium"
                    >
                      Not purchased
                    </Typography>
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PaymentInfo;
