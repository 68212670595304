import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import React from "react";
import classes from "./styles.module.scss";
import { Props } from "./types";

export type eventProps = {
  elementType?: string;
  complete?: boolean;
};

export type StripeErrorProps = {
  number: eventProps | undefined;
  date: eventProps | undefined;
  code: eventProps | undefined;
};

const StripeForm: React.FC<Props> = ({
  error,
  setError,
  validatePaymentForm,
  numberOptions = null,
  expiryOptions = null,
  cvcOptions = null,
  onInputChange,
}) => {
  const customCardStyle = {
    base: {
      fontSize: "14px",
      "::placeholder": {
        color: "#ADB5BD",
        fontSize: "14px",
      },
    },
    invalid: {
      iconColor: "#333333",
      color: "#333333",
    },
    complete: {
      iconColor: "#333333",
    },
  };

  const handleChange = async (
    event:
      | stripeJs.StripeCardCvcElementChangeEvent
      | stripeJs.StripeCardNumberElementChangeEvent
      | stripeJs.StripeCardExpiryElementChangeEvent
  ) => {
    onInputChange && onInputChange(event);
    switch (event.elementType) {
      case "cardNumber":
        return setError({
          ...error,
          number: event.empty ? "" : event.error?.message,
        });
      case "cardExpiry":
        return setError({
          ...error,
          date: event.empty ? "" : event.error?.message,
        });
      case "cardCvc":
        return setError({
          ...error,
          code: event.empty ? "" : event.error?.message,
        });
      default:
        return error;
    }
  };

  return (
    <>
      <CardNumberElement
        className={classes.numberInput}
        options={{
          placeholder: "Credit card number",
          style: customCardStyle,
          ...(numberOptions ? numberOptions : {}),
        }}
        onChange={(event) => {
          handleChange(event);
        }}
        onBlur={() => validatePaymentForm("cardNumber")}
      />
      <p className={classes.errorMessage}>{error.number}</p>
      <div className={classes.cvcExpiry}>
        <div>
          <CardExpiryElement
            className={classes.codeInput}
            options={{
              placeholder: "MM/YY",
              style: customCardStyle,
              ...(expiryOptions ? expiryOptions : {}),
            }}
            onChange={(event) => {
              handleChange(event);
            }}
            onBlur={() => validatePaymentForm("cardExpiry")}
          />
          <p className={classes.errorMessage}>{error.date}</p>
        </div>
        <div>
          <CardCvcElement
            className={classes.codeInput}
            options={{
              placeholder: "CVC",
              style: customCardStyle,
              ...(cvcOptions ? cvcOptions : {}),
            }}
            onChange={(event) => {
              handleChange(event);
            }}
            onBlur={() => validatePaymentForm("cardCvc")}
          />
          <p className={classes.errorMessage}>{error.code}</p>
        </div>
      </div>
    </>
  );
};

export default StripeForm;
