import { Reducer } from "redux";

import Types from "../types/subscription";

const initialState = {
  subscriptionPlans: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  subscriptionPlanCheckout: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  updateSubscription: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  cancelSubscription: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
};

const SubscriptionPlanReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.FETCH_SUBSCRIPTION_PLAN_STARTED:
      return {
        ...state,
        subscriptionPlans: {
          ...state.subscriptionPlans,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        subscriptionPlans: {
          ...state.subscriptionPlans,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.FETCH_SUBSCRIPTION_PLAN_FAILED:
      return {
        ...state,
        subscriptionPlans: {
          ...state.subscriptionPlans,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.SUBSCRIPTION_PLAN_CHECKOUT_STARTED:
      return {
        ...state,
        subscriptionPlanCheckout: {
          ...state.subscriptionPlanCheckout,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.SUBSCRIPTION_PLAN_CHECKOUT_SUCCESS:
      return {
        ...state,
        subscriptionPlanCheckout: {
          ...state.subscriptionPlanCheckout,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.SUBSCRIPTION_PLAN_CHECKOUT_FAILED:
      return {
        ...state,
        subscriptionPlanCheckout: {
          ...state.subscriptionPlanCheckout,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.UPDATE_SUBSCRIPTION_STARTED:
      return {
        ...state,
        updateSubscription: {
          ...state.updateSubscription,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updateSubscription: {
          ...state.updateSubscription,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.UPDATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        updateSubscription: {
          ...state.updateSubscription,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.CANCEL_SUBSCRIPTION_STARTED:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    default:
      return state;
  }
};

export default SubscriptionPlanReducer;
