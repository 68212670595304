import { Dispatch } from "redux";
import {
  CheckoutInputDetails,
  ContactFormType,
  PromoCodeProps,
  PropertyFormType,
  getInsuranceCompanies as getInsuranceCompaniesApi,
  getProducts as getProductsApi,
  getTypesOfSidingMaterial as getTypesOfSidingMaterialApi,
  postCheckoutDetails as postCheckoutDetailsApi,
  postCheckoutPromoCode as postCheckoutPromoCodeApi,
  postContactDetails as postContactDetailsApi,
  postPropertyDetails as postPropertyDetailsApi,
  updatePropertyDetails as updatePropertyDetailsApi,
} from "src/api/checkout";
import { toast } from "src/components/Toast/ToastManager";
import Types from "../types/checkout";

export const postPropertyDetails = (data: PropertyFormType) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.CHECKOUT_STEP_ONE_STARTED,
    });

    const resp = await postPropertyDetailsApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.CHECKOUT_STEP_ONE_SUCCESS,
        payload: {
          data: {
            ...resp,
          },
        },
      });
    } else {
      dispatch({
        type: Types.CHECKOUT_STEP_ONE_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const updatePropertyDetails = (id: number, data: PropertyFormType) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.CHECKOUT_STEP_ONE_UPDATE_STARTED,
    });

    const resp = await updatePropertyDetailsApi(id, data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.CHECKOUT_STEP_ONE_UPDATE_SUCCESS,
        payload: {
          data: {
            ...resp,
          },
        },
      });
    } else {
      dispatch({
        type: Types.CHECKOUT_STEP_ONE_UPDATE_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: resp?.data?.error,
        variant: "danger",
      });
    }
    return resp;
  };
};

export const postContactDetails = (data: ContactFormType) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.CHECKOUT_STEP_TWO_STARTED,
    });

    const resp = await postContactDetailsApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.CHECKOUT_STEP_TWO_SUCCESS,
        payload: { data: { ...resp } },
      });
    } else {
      dispatch({
        type: Types.CHECKOUT_STEP_TWO_FAILED,
        payload: {
          message: resp?.message,
        },
      });
      toast.show({
        title: resp?.data?.error,
        variant: "danger",
      });
    }
    return resp;
  };
};

export const removeCheckoutDetails = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.REMOVE_CHECKOUT_DETAILS,
    });
  };
};

export const postActiveCheckoutId = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.ACTIVE_CHECKOUT_ID,
      payload: { id: id },
    });
  };
};

export const postCheckoutDetails = (data: CheckoutInputDetails) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.CHECKOUT_STEP_THREE_STARTED,
    });

    const resp = await postCheckoutDetailsApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.CHECKOUT_STEP_THREE_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.CHECKOUT_STEP_THREE_FAILED,
        payload: {
          message: resp?.message,
        },
      });

      toast.show({
        title: resp?.data?.error,
        variant: "danger",
        size: "lg",
      });
    }
    return resp;
  };
};

export const getProducts = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_PRODUCTS_STARTED,
    });

    const resp = await getProductsApi();
    if (resp?.status === 1) {
      dispatch({
        type: Types.GET_PRODUCTS_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.GET_PRODUCTS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const postCheckoutPromoCode = (data: PromoCodeProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.PROMO_CODE_VALIDATION_STARTED,
    });

    const resp = await postCheckoutPromoCodeApi(data);
    if (resp?.status === 1) {
      dispatch({
        type: Types.PROMO_CODE_VALIDATION_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.PROMO_CODE_VALIDATION_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getInsuranceCompanyList = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.INSURANCE_COMPANY_LIST_STARTED,
    });

    const resp = await getInsuranceCompaniesApi();
    const formattedOptions = Object.keys(resp).map((key) => ({
      value: resp[key].id,
      label: resp[key].name,
    }));
    formattedOptions.splice(-1);
    if (resp) {
      dispatch({
        type: Types.INSURANCE_COMPANY_LIST_SUCCESS,
        payload: { data: formattedOptions },
      });
    } else {
      dispatch({
        type: Types.INSURANCE_COMPANY_LIST_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getTypesOfSidingMaterial = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.SIDING_MATERIAL_TYPES_STARTED,
    });

    const resp = await getTypesOfSidingMaterialApi();
    const formattedOptions = Object.keys(resp).map((key) => ({
      value: resp[key].id,
      label: resp[key].name,
    }));
    formattedOptions.splice(-1);
    if (resp) {
      dispatch({
        type: Types.SIDING_MATERIAL_TYPES_SUCCESS,
        payload: { data: formattedOptions },
      });
    } else {
      dispatch({
        type: Types.SIDING_MATERIAL_TYPES_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};
