/* eslint-disable max-lines */
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useMemo, useState } from "react";
import { CancelSubscriptionProps } from "src/api/subscription";
import { alertPopup } from "src/components/AlertPopup/AlertPopupManager";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { PlanProps } from "src/pages/Plans/types";
import {
  cancelSubscription,
  getUpdateSubscriptionEstimate,
  updateSubscription,
} from "src/store/actions/subscription";
import { useReducerData, useStoreActions } from "src/store/hooks";
import PlanDetailsModal from "../PlanDetailsModal";
import classes from "./styles.module.scss";
import { PropsType } from "./types";

const PlansCard: React.FC<PropsType> = ({
  plan,
  isLowerPlan,
  user,
  clientId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [userSelectedPlan, setUserSelectedPlan] = useState<PlanProps | null>(
    null
  );
  const [loader, setLoader] = useState(false);
  const [planId, setPlanId] = useState<number | null>(null);
  const { loading: cancelSubscriptionLoader } = useReducerData(
    "plans",
    "cancelSubscription",
    {
      loading: false,
    }
  );
  const [getEstimateLoader, setGetEstimateLoader] = useState(false);
  const { subscription: userSubscription } = user || { subscription: null };
  const actions = useStoreActions({
    updateSubscription,
    cancelSubscription,
    getUpdateSubscriptionEstimate,
  });
  const isUserAlreadyPurchasedPlan = useMemo(
    () => userSubscription?.status === "active",
    [userSubscription]
  );
  const isUserActivePlan = useMemo(
    () => plan?.price_id === userSubscription?.price_id,
    [plan, userSubscription]
  );
  const nextRenewalId = userSubscription?.next_renewal_plan_id;
  const scheduledToCancel = useMemo(
    () => userSubscription?.scheduled_to_cancel === 1,
    [userSubscription]
  );
  const isNextRenewalPlan = useMemo(
    () => nextRenewalId === plan?.id,
    [nextRenewalId, plan]
  );

  const buttonText = useMemo(() => {
    switch (true) {
      case isUserAlreadyPurchasedPlan && isUserActivePlan:
        return "Your current subscription plan";
      case isUserAlreadyPurchasedPlan && isNextRenewalPlan:
        return "Next scheduled plan";
      case isUserAlreadyPurchasedPlan:
        return "Switch to this plan";
      default:
        return "Select this plan";
    }
  }, [isUserAlreadyPurchasedPlan, isUserActivePlan, isNextRenewalPlan]);

  const handleSelectPlans = async (
    plan: PlanProps,
    isHigherUpgrade: boolean,
    estimate: number
  ) => {
    if (
      plan.price_id !== userSubscription?.price_id &&
      !isUserActivePlan &&
      isUserAlreadyPurchasedPlan
    ) {
      const data = isHigherUpgrade
        ? {
            ...plan,
            next_renewal_plan: null,
            next_renewal_plan_id: null,
            scheduled_to_cancel: 0,
          }
        : {
            next_renewal_plan: plan.name,
            next_renewal_plan_id: plan.id,
            scheduled_to_cancel: 0,
          };
      alertPopup.showCustom({
        info: true,
        style: { width: "604px" },
        onConfirm: () => {},
        title: `Are you sure you would like to switch to ${plan.name}?`,
        children: isHigherUpgrade
          ? `This will take effect immediately and will reset your billing period. You will be charged $${estimate}.`
          : "This will take effect on your next billing period.",
        customButtonList: [
          {
            buttonText: "Confirm",
            buttonColor: "success",
            handleConfirm: async () => {
              setPlanId(plan.id);
              setLoader(true);
              await actions.updateSubscription(
                {
                  plan_id: `${plan.id}`,
                  ...(clientId ? { user_id: clientId } : {}),
                },
                data
              );
              setLoader(false);
            },
          },
          {
            buttonText: "Not now",
            buttonColor: "secondary",
            variant: "outline",
            handleConfirm: () => {},
          },
        ],
      });
    }
    if (isUserActivePlan) return;
    setUserSelectedPlan(plan);
    setOpenModal(true);
  };

  const getFeatureList = (id: number) => {
    switch (id) {
      case 1:
        return [
          "Access to searchable shingle database",
          "Submit digital photos of shingle samples",
          "Add team members to your account",
        ];
      case 2:
        return [
          "Access to searchable shingle database",
          "Submit digital photos of shingle samples",
          "Add team members to your account",
        ];
      case 3:
        return [
          "Submit digital photos of shingle samples",
          "Add team members to your account",
        ];
      case 4:
        return ["Add team members to your account"];
      case 5:
        return ["Add team members to your account"];
      case 6:
        return ["Add team members to your account"];
      default:
        return [];
    }
  };

  const handleCancel = async () => {
    const params: CancelSubscriptionProps = clientId
      ? { user_id: clientId }
      : {};

    if (scheduledToCancel) {
      params.undo = true;
    }

    await actions.cancelSubscription(params, {
      scheduled_to_cancel: scheduledToCancel ? 0 : 1,
      next_renewal_plan: null,
      next_renewal_plan_id: null,
    });
  };

  const handleCancelSubscription = async () => {
    alertPopup.showCustom({
      warning: true,
      onConfirm: () => {},
      title: scheduledToCancel
        ? "Are you sure you would like to undo cancellation?"
        : `Are you sure you would like to cancel the subscription?`,
      customButtonList: [
        {
          buttonText: "Confirm",
          buttonColor: "success",
          handleConfirm: handleCancel,
        },
        {
          buttonText: "Not now",
          buttonColor: "secondary",
          variant: "outline",
          handleConfirm: () => {},
        },
      ],
    });
  };

  const handleUpdateSubscription = async (plan: PlanProps) => {
    const isHigherUpgrade =
      (plan?.amount ?? 0) > (userSubscription?.amount ?? 0);
    let estimate = 0;
    if (isHigherUpgrade && isUserAlreadyPurchasedPlan) {
      setGetEstimateLoader(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resp: any = await actions.getUpdateSubscriptionEstimate({
        plan_id: plan.id,
        ...(clientId ? { user_id: clientId } : {}),
      });
      setGetEstimateLoader(false);
      estimate = resp?.estimate_amount ?? 0;
    }
    await handleSelectPlans(plan, isHigherUpgrade, estimate);
  };

  return (
    <>
      {openModal && !isUserAlreadyPurchasedPlan && (
        <PlanDetailsModal
          user={user}
          isOpen={openModal}
          onToggle={() => setOpenModal(!openModal)}
          selectedPlan={userSelectedPlan}
        />
      )}
      <div className={classes.wrapper}>
        <div className={classes.subWrapper}>
          <Typography
            className={cx(
              classes.title,
              isLowerPlan ? classes.lowerPlanTitle : classes.cardTitle
            )}
          >
            {plan?.name}
          </Typography>
          <Typography className={cx("mb-0", classes.priceWrapper)}>
            <sup className={classes.price}>$</sup>
            {plan?.amount?.toLocaleString()}
          </Typography>
          <Typography
            variant="p13"
            className={cx("mb-4", classes.monthTextWrapper)}
          >
            {" "}
            per {plan?.interval}
          </Typography>
          <div className={classes.planFeatureWrapper}>
            <div className={classes.lineWrapper}></div>
            <Typography
              variant="p16"
              fontWeight="medium"
              className={cx("mt-2", "mb-2")}
            >
              {plan?.reports_count} reports
            </Typography>
            <div className={classes.lineWrapper}></div>
            <Typography
              variant="p16"
              fontWeight="medium"
              className={cx("mt-2", "mb-2")}
            >
              ${plan?.amount_per_reports} per report
            </Typography>
            <div className={classes.lineWrapper}></div>
            <Typography
              variant="p16"
              fontWeight="bold"
              className={cx("mt-2", "mb-2", classes.discount, {
                [classes.noDiscountWrapper]: !plan?.discounts_pecentage,
              })}
            >
              {plan?.discounts_pecentage || "-"}% discount
            </Typography>
            <div className={classes.lineWrapper}></div>
          </div>
          <Typography variant="p14" className={classes.features}>
            Plus access to new features coming soon:
          </Typography>
          <div className={classes.featureList}>
            {getFeatureList(plan?.id).map((featureText, i) => (
              <Typography variant="p14" key={i} fontWeight="medium">
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>{" "}
                {featureText}
              </Typography>
            ))}
          </div>
        </div>
        <Button
          onClick={() => handleUpdateSubscription(plan)}
          loading={(planId === plan.id && loader) || getEstimateLoader}
          disabled={
            (isNextRenewalPlan || !!scheduledToCancel) && !isUserActivePlan
          }
          className={cx(
            isLowerPlan ? classes.lowerbuttonWrapper : classes.buttonWrapper,
            classes.button,
            {
              [classes.subscribedUser]: isUserAlreadyPurchasedPlan,
              [classes.userActivePlan]: isUserActivePlan,
              [classes.disabled]:
                (isNextRenewalPlan || !!scheduledToCancel) && !isUserActivePlan,
            }
          )}
          buttonText={buttonText}
        />
        {isUserActivePlan && (
          <Button
            loading={cancelSubscriptionLoader}
            buttonText={
              scheduledToCancel ? "Undo Cancellation" : "Cancel Subscription"
            }
            buttonColor="danger"
            variant="outline"
            buttonClassName={cx(classes.cancelSubscription, classes.button)}
            onClick={handleCancelSubscription}
          />
        )}
      </div>
    </>
  );
};

export default PlansCard;
