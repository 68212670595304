import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { AlertPopupProps } from "./types";

const AlertPopup: React.FC<AlertPopupProps> = ({
  onConfirm,
  title = "",
  ...rest
}) => {
  return <ReactBSAlert onConfirm={onConfirm} title={title} {...rest} />;
};

export default AlertPopup;
