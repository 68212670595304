import moment from "moment";
import React from "react";
import { Column } from "react-table";
import OrderStatus from "src/components/OrderStatus";
import Typography from "src/components/Typography";
import { Reports } from "../OrderCreate/Components/SelectReport/types";
import classes from "./styles.module.scss";

export const column: readonly Column<any>[] = [
  {
    Header: "ORDER #",
    accessor: "address",
    Cell: (props) => {
      return (
        <Typography className={classes.address} variant="p16" fontWeight="bold">
          {props?.row?.original?.property_detail?.address},{" "}
          {props?.row?.original?.property_detail?.state || ""}{" "}
          {props?.row?.original?.property_detail?.city}{" "}
          {props?.row?.original?.property_detail?.zip_code}
        </Typography>
      );
    },
  },
  {
    Header: "Ordered On",
    accessor: "created_at",
    // eslint-disable-next-line react/no-multi-comp
    Cell: (props) => {
      return (
        <span className={classes.reportType}>
          {moment(props?.row?.original?.created_at).format("MM/DD/YYYY")}
        </span>
      );
    },
  },
  {
    Header: "Status",
    accessor: "report_status",
    // eslint-disable-next-line react/no-multi-comp
    Cell: ({ value }) => {
      return <OrderStatus variant={value}>{value || "-"}</OrderStatus>;
    },
  },
  {
    Header: "Type",
    accessor: "report_type",
    // eslint-disable-next-line react/no-multi-comp
    Cell: ({ value }) => {
      const report =
        value === "Siding ID Report" ? Reports.Siding : Reports.Shingle;

      return (
        <Typography className={classes.reportType}>{report || "-"}</Typography>
      );
    },
  },
];
