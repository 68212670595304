import React, { useState } from "react";
import { Container } from "reactstrap";
import FAQBg from "src/assets/images/NTS-faq-bg.jpg";
import Accordion from "src/components/Accordion";
import Typography from "src/components/Typography";
import { accordionList } from "./constants";
import classes from "./styles.module.scss";

const FAQ = () => {
  const [open, setOpen] = useState("");

  const handleOpen = (id: string) => {
    if (id === open) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  return (
    <div
      id="nts-faq"
      className={classes.wrapper}
      style={{
        backgroundImage: ` url(${FAQBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container className={classes.container}>
        <div className={classes.content}>
          <Typography className={classes.title}>
            Frequently Asked Questions
          </Typography>
          <Typography className={classes.description}>
            Please email us at{" "}
            <a target="_blank" href="mailto:info@ntsid.com" rel="noreferrer">
              info@ntsid.com
            </a>{" "}
            if you cannot find an answer to your question.
          </Typography>
        </div>
        <div className={classes.accordionWrapper}>
          <Accordion
            accordionItems={accordionList}
            open={open}
            toggle={handleOpen}
          />
        </div>
      </Container>
    </div>
  );
};

export default FAQ;
