import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import { alertPopup } from "src/components/AlertPopup/AlertPopupManager";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import Input from "src/components/Input";
import Modal from "src/components/Modal";
import Typography from "src/components/Typography";
import FormikAddress from "src/pages/OrderCreate/Components/FormikAddress";
import { OptionType } from "src/pages/OrderCreate/Components/PropertyForm/types";
import { PlanProps } from "src/pages/Plans/types";
import { postSubscriptionPlanCheckout } from "src/store/actions/subscription";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { telephoneNumberRegex } from "src/utils/constants";
import { getFormattedNumber } from "src/utils/utils";
import { User } from "../PlansCard/types";
import classes from "./styles.module.scss";
import { FormProps } from "./types";

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  selectedPlan: PlanProps | null;
  user: User;
};

const PlanDetailsModals: React.FC<Props> = ({
  isOpen = false,
  onToggle,
  user,
  selectedPlan,
}) => {
  const { loading } = useReducerData("plans", "subscriptionPlanCheckout", {
    loading: false,
  });
  const actions = useStoreActions({ postSubscriptionPlanCheckout });

  const checkoutSchema = Yup.object().shape({
    rep_phone: Yup.string()
      .required("Phone number is required")
      .matches(
        telephoneNumberRegex,
        "Should be valid phone number (i.e. XXX-XXX-XXXX)"
      ),
    rep_name: Yup.string().required("Representative name is required"),
    name: Yup.string().required("Company name is required"),
    address: Yup.string().required("Company address is required"),
    city: Yup.string().required("Please enter city name"),
    zip_code: Yup.string().required("Please enter zip code"),
    state: Yup.object()
      .required("Please select state")
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
    terms: Yup.boolean().oneOf([true], "Please accept Privacy Policy"),
  });

  const handleSubmit = async (values: FormProps) => {
    const subscriptionData = {
      company: {
        ...values,
        state: values.state.value,
      },
      price_id: selectedPlan?.price_id || "",
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status, data } = await actions.postSubscriptionPlanCheckout(
      subscriptionData
    );
    if (status === 1) {
      window.location.href = data?.checkout_url;
    } else {
      alertPopup.showCustom({
        info: true,
        onConfirm: () => {},
        title:
          "Please contact NTS support at info@ntsid.com to modify your plan",
        children: "Would you like to email NTS support now? ",
        customButtonList: [
          {
            buttonText: "Email NTS support",
            buttonColor: "success",
            handleConfirm: () => {
              window.location.href = "mailto:info@ntsid.com?subject=Assistance";
            },
          },
          {
            buttonText: "Not now",
            buttonColor: "secondary",
            variant: "outline",
            handleConfirm: () => {},
          },
        ],
      });
    }
  };

  const handleLink = () => {
    const element = document.getElementById("plans-terms-and-condition");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <Modal className={classes.modalWrapper} isOpen={isOpen} toggle={onToggle}>
      <div>
        <Typography className="text-center" fontWeight="semibold" variant="p24">
          Great, let’s get your company setup on our{" "}
          <span className="text-capitalize">
            {selectedPlan?.name?.toLowerCase()}
          </span>{" "}
          Plan
        </Typography>
        <Typography className="text-center" fontWeight="light" variant="p14">
          Please enter your company information below
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={checkoutSchema}
          initialValues={{
            name: "",
            address: "",
            city: "",
            state: null as unknown as OptionType,
            zip_code: "",
            rep_name: `${user?.first_name || ""} ${user?.last_name || ""}`,
            rep_phone: user?.phone || "",
            terms: false,
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<FormProps>) => (
            <Form className={cx(classes.form, "d-flex flex-column")}>
              <FormGroup className={classes.formGroup}>
                <Input
                  inputGroupClassName={classes.inputGroupStyle}
                  required={true}
                  labelClassName={classes.label}
                  label="Company Name"
                  value={values.name}
                  placeholder="Enter company name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name}
                  touched={touched.name}
                  name="name"
                />
              </FormGroup>
              <FormikAddress
                inputId="subscription-form"
                inputNames={{
                  address: "address",
                  city: "city",
                  state: "state",
                  zip_code: "zip_code",
                }}
                inputAddressPlaceholder="Enter company address"
                inputAddressLabel="Company Address"
                values={{
                  address: values.address,
                  city: values.city,
                  state: values.state,
                  zip_code: values.zip_code,
                }}
                touched={{
                  address: touched.address,
                  city: touched.city,
                  state: touched.state,
                  zip_code: touched.zip_code,
                }}
                errors={{
                  address: errors.address,
                  city: errors.city,
                  state: errors.state,
                  zip_code: errors.zip_code,
                }}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
              <div className={classes.repDetails}>
                <FormGroup className={classes.formGroup}>
                  <Input
                    inputGroupClassName={classes.inputGroupStyle}
                    required={true}
                    labelClassName={classes.label}
                    label="Representative Name"
                    value={values.rep_name}
                    placeholder="Enter representative name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.rep_name}
                    touched={touched.rep_name}
                    name="rep_name"
                  />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                  <Input
                    inputGroupClassName={classes.inputGroupStyle}
                    required={true}
                    labelClassName={classes.label}
                    label="Representative Phone"
                    value={values.rep_phone}
                    placeholder="555-555-5555"
                    onChange={(value) => {
                      setFieldValue("rep_phone", getFormattedNumber(value));
                    }}
                    onBlur={handleBlur}
                    error={errors.rep_phone}
                    touched={touched.rep_phone}
                    name="rep_phone"
                  />
                </FormGroup>
              </div>

              <FormGroup
                className={cx("text-start", classes.formGroupCheckBox)}
              >
                <CheckBox
                  checkBoxClassName="mb-0"
                  onChange={handleChange}
                  checked={values.terms}
                  onBlur={handleBlur}
                  error={errors.terms}
                  touched={touched.terms}
                  showOutline
                  label={
                    <Typography variant="p14" className="mb-0">
                      I agree to the{" "}
                      <Button
                        buttonText=" NTS subscription terms & conditions"
                        variant="link"
                        onClick={() => {
                          onToggle();
                          setTimeout(() => {
                            handleLink();
                          }, 100);
                        }}
                      />
                    </Typography>
                  }
                  name="terms"
                />
              </FormGroup>

              <div className={classes.actionButtons}>
                <Button
                  type="submit"
                  buttonText="Switch Plans"
                  variant="outline"
                  buttonColor="secondary"
                  onClick={onToggle}
                />
                <Button
                  loading={loading}
                  disabled={!isValid || !dirty}
                  type="submit"
                  buttonColor="success"
                  buttonText="Continue to Payment"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PlanDetailsModals;
