import { faAddressCard, faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import PhoneIcon from "src/assets/icons/phone-icon.svg";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { getCurrentUser, updateProfile } from "src/store/actions/user";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { telephoneNumberRegex } from "src/utils/constants";
import { getFormattedNumber } from "src/utils/utils";
import classes from "./styles.module.scss";
import { EditProfileInputProps, Props } from "./types";

const EditProfile: React.FC<Props> = ({ userData, handleBackToProfile }) => {
  const { loading } = useReducerData("user", "user", {
    loading: false,
  });
  const actions = useStoreActions({ updateProfile, getCurrentUser });

  const editUserProfileSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Must be valid email addresses"),
    phone: Yup.string().matches(
      telephoneNumberRegex,
      "Number needs to be in the format XXX-XXX-XXXX"
    ),
  });

  const handleSubmit = async (values: EditProfileInputProps) => {
    const { email, phone, firstName, lastName } = values;
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
    };
    await actions.updateProfile(data);
    handleBackToProfile();
  };

  return (
    <Card cardClassName={classes.profileEditWrapper}>
      <Typography className="text-start" fontWeight="semibold" variant="p24">
        My Profile
      </Typography>
      <div>
        <Formik
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={editUserProfileSchema}
          initialValues={{
            firstName: userData.first_name || "",
            lastName: userData.last_name || "",
            email: userData.email || "",
            phone: userData.phone || "",
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
            setFieldValue,
          }: FormikProps<EditProfileInputProps>) => (
            <Form className={cx("d-flex flex-column")}>
              <FormGroup>
                <Input
                  label="First name"
                  required
                  inputGroupClassName={classes.inputStyle}
                  labelClassName={classes.profileInputLabel}
                  value={values.firstName}
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.firstName}
                  touched={touched.firstName}
                  name="firstName"
                  leftIcon={<FontAwesomeIcon icon={faAddressCard} />}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  label="Last name"
                  required
                  inputGroupClassName={classes.inputStyle}
                  labelClassName={classes.profileInputLabel}
                  value={values.lastName}
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName}
                  touched={touched.lastName}
                  name="lastName"
                  leftIcon={<FontAwesomeIcon icon={faAddressCard} />}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  label={
                    userData?.is_admin ? "Email Address" : "Work Email Address"
                  }
                  required
                  inputGroupClassName={classes.inputStyle}
                  labelClassName={classes.profileInputLabel}
                  value={values.email}
                  placeholder={
                    userData?.is_admin ? "Email Address" : "Work Email Address"
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  touched={touched.email}
                  name="email"
                  leftIcon={<FontAwesomeIcon icon={faAt} />}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  label="Phone number"
                  value={values.phone}
                  inputGroupClassName={classes.inputStyle}
                  labelClassName={classes.profileInputLabel}
                  placeholder="555-555-5555"
                  onChange={(value) => {
                    setFieldValue("phone", getFormattedNumber(value));
                  }}
                  onBlur={handleBlur}
                  error={errors.phone}
                  touched={touched.phone}
                  name="phone"
                  leftIcon={
                    <img
                      alt="PhoneIcon"
                      src={PhoneIcon}
                      width={16}
                      height={16}
                    />
                  }
                />
              </FormGroup>
              <div className={classes.actionButton}>
                <Button
                  disabled={!isValid || !dirty}
                  type="submit"
                  className={classes.saveButton}
                  buttonText="Save Changes"
                  loading={loading}
                />
                <Button
                  type="submit"
                  buttonText="Cancel"
                  buttonColor="secondary"
                  onClick={handleBackToProfile}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default EditProfile;
