import { Request } from "./request";

export type CompanyDetailProps = {
  name: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  rep_name: string;
  rep_phone: string;
};

export type SubscriptionSessionProps = {
  company: CompanyDetailProps;
  price_id: string;
};

export type UpdateSubscriptionProps = {
  plan_id: string;
};

export type CancelSubscriptionProps = {
  user_id?: string;
  undo?: boolean;
} | null;

export type UpdateSubscriptionEstimateProps = {
  plan_id: number;
  user_id?: string;
};

export const getSubscriptionPlans = async () => {
  return Request.call({
    url: "/api/plans",
    method: "GET",
  });
};

export const postSubscriptionPlanCheckout = async (
  data: SubscriptionSessionProps
) => {
  return Request.call({
    url: "/api/checkout/session",
    method: "POST",
    data,
  });
};

export const updateSubscription = async (data: UpdateSubscriptionProps) => {
  return Request.call({
    url: "/api/subscription/update",
    method: "POST",
    data,
  });
};

export const cancelSubscription = async (params: CancelSubscriptionProps) => {
  return Request.call({
    url: "/api/subscription/cancel",
    method: "POST",
    params,
  });
};

export const getUpdateSubscriptionEstimate = async (
  params: UpdateSubscriptionEstimateProps
) => {
  return Request.call({
    url: "/api/subscription/update/estimate",
    method: "POST",
    params,
  });
};
