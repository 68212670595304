import { Viewer } from "@react-pdf-viewer/core";
import cx from "classnames";
import Loader from "../Loader";
import classes from "./styles.module.scss";
import { PreviewFileProps } from "./types";

const PreviewFile: React.FC<PreviewFileProps> = ({ fileUrl, isLoading }) => {
  return (
    <div
      className={cx(classes.fileViewer, {
        [classes.loaderContainer]: isLoading,
      })}
    >
      {isLoading && !fileUrl ? <Loader /> : <Viewer fileUrl={fileUrl} />}
    </div>
  );
};

export default PreviewFile;
