import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container } from "reactstrap";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";

const footerLinks = [
  {
    link: "/",
    label: "Home",
  },
  {
    link: "/order/create/select-report",
    label: "Order a Report",
  },
  {
    link: "/",
    label: "Shipping Instructions",
  },
  {
    link: "https://name-that-shingle.myshopify.com/",
    label: "NTS Merchandise",
  },
  {
    link: "/terms",
    label: "Terms & Conditions",
  },
];

const Footer = () => {
  return (
    <footer className={classes.footerWrapper}>
      <Container className={classes.container}>
        {footerLinks.map(({ label, link }, index) => {
          return (
            <a
              className={classes.links}
              key={index}
              href={link}
              {...(index === 3 ? { target: "_blank", rel: "noreferrer" } : {})}
            >
              {label}
            </a>
          );
        })}
        <div className={classes.socialLinks}>
          <a
            target="_blank"
            href="https://www.facebook.com/groups/namethatshinglerenamed"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCENVeMu0HnqPUBUoUYgkBpw"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
      </Container>
      <Typography variant="p10" className={classes.copyRightText}>
        Copyright © 2022 NTS - All Rights Reserved.
      </Typography>
    </footer>
  );
};

export default Footer;
