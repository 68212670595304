import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Container } from "reactstrap";
import NTSReportImg1 from "src/assets/images/NTS-sample-report-Page1.jpg";
import NTSReportImg2 from "src/assets/images/NTS-sample-report-Page2.jpg";
import NTSReportImg3 from "src/assets/images/NTS-sample-report-Page3.jpg";
import NTSReportImg4 from "src/assets/images/NTS-sample-report-Page4.jpg";
import NTSReportImg5 from "src/assets/images/NTS-sample-report-Page5.jpg";
import NTSReportImg6 from "src/assets/images/NTS-sample-report-Page6.jpg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";

const ReportSample = () => {
  const [PDFPage, setPDFPage] = useState(1);

  const handleReportImage = () => {
    switch (PDFPage) {
      case 1:
        return NTSReportImg1;
      case 2:
        return NTSReportImg2;
      case 3:
        return NTSReportImg3;
      case 4:
        return NTSReportImg4;
      case 5:
        return NTSReportImg5;
      case 6:
        return NTSReportImg6;
      default:
        return NTSReportImg1;
    }
  };

  return (
    <div id="nts-report" className={classes.wrapper}>
      <Container className={classes.mainWrapper}>
        <div className={classes.PDFWrapper}>
          <img
            src={handleReportImage()}
            alt="NTS-image"
            className={classes.imgWrapper}
          />
          <div className={classes.btnWrapper}>
            <Button
              buttonText="Prev"
              buttonClassName={
                PDFPage === 1 ? classes.leftBtn : classes.diabledLeftBtn
              }
              disabled={PDFPage === 1}
              onClick={() => setPDFPage(PDFPage - 1)}
              leftIcon={
                <span className={classes.arrowIconWrapper}>
                  <FontAwesomeIcon icon={faArrowCircleLeft} />
                </span>
              }
            />
            <Typography variant="p16" className={classes.pdfPageTextWrapper}>
              {PDFPage}/6
            </Typography>
            <Button
              buttonText="Next"
              disabled={PDFPage === 6}
              buttonClassName={
                PDFPage === 6 ? classes.rightBtn : classes.diabledRightBtn
              }
              onClick={() => setPDFPage(PDFPage + 1)}
              rightIcon={
                <span className={classes.arrowIconWrapper}>
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </span>
              }
            />
          </div>
          <div className={classes.pdfTextWrapper}>
            <a
              href="/files/NTS Sample Report 2024.pdf"
              download="NTS Sample Report 2024.pdf"
            >
              Download sample PDF
            </a>
          </div>
        </div>
        <div className={classes.ReportWrapper}>
          <Typography variant="p32" className={classes.titleWrapper}>
            What's included in an NTS Report?
          </Typography>
          <Typography className={classes.textWrapper}>
            Our reports are objective identifications that are based on the
            specifications set forth by the manufacturer.
          </Typography>
          <Typography className={classes.descriptionWrapper}>
            Each positive ID will show whether the product is available or no
            longer in production. Various supporting documents (from the product
            manufacturer and other sources) are included with each report:
          </Typography>
          <div>
            <ol className={classes.listWrapper}>
              <li>
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                Product identification
              </li>
              <li>
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                Shingle type
              </li>
              <li>
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                Sample fading
              </li>
              <li>
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                Dimensions
              </li>
              <li>
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                Production status
              </li>
              <li>
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                Compatibility
              </li>
              <li>
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                Manufacturer information
              </li>
              <li>
                <span className={classes.iconWrapper}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                Supporting documentation
              </li>
            </ol>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ReportSample;
