import cx from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Cell } from "react-table";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Params } from "src/api/admin";
import { alertPopup } from "src/components/AlertPopup/AlertPopupManager";
import OrderStatus from "src/components/OrderStatus";
import Table from "src/components/Table";
import Typography from "src/components/Typography";
import {
  getOrderStatuses,
  getRecentAdminOrders,
  updateAdminOrder,
} from "src/store/actions/admin";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { OrderTableProps, StatusProps } from "../AdminOrders/types";
import classes from "./styles.module.scss";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [sortState, setSortState] = useState<Params>({});
  const actions = useStoreActions({
    getRecentAdminOrders,
    getOrderStatuses,
    updateAdminOrder,
  });
  const { data: orderStatuses } = useReducerData("admin", "orderStatuses", []);
  const { data: recentOrder, loading } = useReducerData(
    "admin",
    "recentAdminOrders",
    []
  );

  useEffect(() => {
    actions.getOrderStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    actions.getRecentAdminOrders(sortState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortState]);

  const handleSort = (id: string) => {
    setSortState({
      ...sortState,
      sort_by: id,
      sort_order: sortState.sort_order === "asc" ? "desc" : "asc",
    });
  };

  const handleHeaderButton = () => {
    navigate("/admin/orders");
  };

  const handleOrderStatus = (statusList: StatusProps, row: OrderTableProps) => {
    const statusData = {
      status_id: statusList.id.toString(),
    };
    alertPopup.showCustom({
      warning: true,
      onConfirm: () => {},
      title: `Are you sure you want to change the status of order ${row?.order_id}?`,
      children: "This new status will be visible to the customer",
      customButtonList: [
        {
          buttonText: "Yes, change status",
          buttonColor: "success",
          handleConfirm: async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { status } = await actions.updateAdminOrder(
              row?.order_id,
              statusData
            );
            if (status) {
              actions.getRecentAdminOrders(sortState);
            }
          },
        },
        {
          buttonText: "Cancel",
          buttonColor: "secondary",
          handleConfirm: () => {},
        },
      ],
    });
  };

  const handleTableRowClick = (cell: Cell<any>) => {
    navigate(`/admin/orders/${cell.row.original.order_id}`, {
      state: {
        id: cell.row.original.order_id,
        status: cell.row.original?.status,
      },
    });
  };

  const handleSearch = (searchText: string) => {
    const params = {
      ...sortState,
      order_id: searchText,
    };
    setSortState(params);
    actions.getRecentAdminOrders(params);
  };

  return (
    <div>
      <div className={classes.wrapper}>
        {
          <Table
            search
            searchProps={{
              onSearch: handleSearch,
              searchPlaceholder: "Search by order id",
            }}
            tableHeaderClassname={classes.tableHeader}
            handleSort={handleSort}
            data={recentOrder}
            loading={loading}
            handleRowClick={handleTableRowClick}
            noDataIndication={
              <div className="d-flex  justify-content-center py-4">
                No data found
              </div>
            }
            columns={[
              {
                Header: "ORDER #",
                accessor: "order_id",
                // eslint-disable-next-line react/no-multi-comp
                Cell: (props) => {
                  return (
                    <Typography className="mb-0" variant="p16">
                      {props?.row?.original?.order_id}
                    </Typography>
                  );
                },
              },
              {
                Header: "PROPERTY ADDRESS",
                accessor: "property_address",
                // eslint-disable-next-line react/no-multi-comp
                Cell: (props) => {
                  return props?.row?.original?.property_address ? (
                    <Typography className={classes.address} variant="p16">
                      {props?.row?.original?.property_address}
                    </Typography>
                  ) : (
                    <span className={classes.noData}>n/a</span>
                  );
                },
              },
              {
                Header: "PARTNER",
                accessor: "partner",
                // eslint-disable-next-line react/no-multi-comp
                Cell: (props) => {
                  return (
                    <Typography className={classes.address} variant="p16">
                      {props?.value}
                    </Typography>
                  );
                },
              },
              {
                Header: "ORDERED ON",
                accessor: "ordered_on",
                // eslint-disable-next-line react/no-multi-comp
                Cell: ({ row }) => {
                  return (
                    <span>
                      {moment(row?.values?.ordered_on).format("MM/DD/YYYY")}
                    </span>
                  );
                },
              },
              {
                Header: "TRACKING",
                accessor: "tracking",
                // eslint-disable-next-line react/no-multi-comp
                Cell: ({ row }) => {
                  return (
                    <span className={classes.trackingNo}>
                      {row?.values?.tracking ? (
                        <a
                          target="_blank"
                          className={classes.linkWrapper}
                          rel="noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          href={`https://www.ups.com/track?trackingNumber=${row?.values?.tracking}`}
                        >
                          {row?.values?.tracking}{" "}
                          <i className="fa fa-external-link" />
                        </a>
                      ) : (
                        "Not purchased"
                      )}
                    </span>
                  );
                },
              },
              {
                Header: "Status",
                accessor: "status",
                // eslint-disable-next-line react/no-multi-comp
                Cell: ({ row }) => {
                  return (
                    <>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.stopPropagation()}
                        >
                          <OrderStatus variant={row?.original?.status}>
                            {row?.original?.status}
                          </OrderStatus>
                        </DropdownToggle>
                        <DropdownMenu
                          container="body"
                          style={{
                            marginTop: "4px",
                          }}
                          className={cx(
                            "dropdown-menu-arrow",
                            classes.dropdown
                          )}
                        >
                          {orderStatuses.length > 0 &&
                            orderStatuses?.map(
                              (statusList: StatusProps, index: number) => (
                                <DropdownItem
                                  className={classes.dropdownItem}
                                  key={index}
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    marginBottom: "12px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleOrderStatus(
                                      statusList,
                                      row?.original
                                    );
                                  }}
                                >
                                  {statusList.name}
                                </DropdownItem>
                              )
                            )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </>
                  );
                },
              },
              {
                Header: "Type",
                accessor: "report_type",
                // eslint-disable-next-line react/no-multi-comp
                Cell: ({ row }) => {
                  const reportType =
                    row?.original?.report_type === "Siding ID Report"
                      ? "Siding"
                      : "Shingle";
                  return row?.original?.report_type ? (
                    <Typography className={classes.address} variant="p16">
                      {reportType}
                    </Typography>
                  ) : (
                    <span className={classes.noData}>n/a</span>
                  );
                },
              },
            ]}
            tableHeaderText="Recent Orders"
            showHeaderButton
            handleHeaderButton={handleHeaderButton}
          />
        }
      </div>
    </div>
  );
};

export default AdminDashboard;
