import cx from "classnames";
import React from "react";
import { Modal as BaseModal } from "reactstrap";
import classes from "./styles.module.scss";

import { Props } from "./types";

const Modal: React.FC<Props> = ({
  isOpen,
  toggle,
  children,
  className,
  childrenWrapperClassName = "",
  ...rest
}) => {
  return (
    <BaseModal
      className={cx("modal-dialog-centered", className)}
      isOpen={isOpen}
      toggle={toggle}
      {...rest}
    >
      <div
        className={cx(classes.modalWrapper, {
          [childrenWrapperClassName]: childrenWrapperClassName,
        })}
      >
        {children}
      </div>
    </BaseModal>
  );
};

export default Modal;
