import React from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Accordion as BaseAccordion,
} from "reactstrap";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Accordion: React.FC<Props> = ({ open, accordionItems, toggle }) => {
  return (
    <BaseAccordion
      className={classes.wrapper}
      open={open}
      {...{
        toggle,
      }}
    >
      {accordionItems.map(({ id, header, content }) => {
        return (
          <AccordionItem className={classes.accordionItem} key={id}>
            <AccordionHeader className={classes.header} targetId={id}>
              {header}
            </AccordionHeader>
            <AccordionBody className={classes.body} accordionId={id}>
              {content}
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </BaseAccordion>
  );
};

export default Accordion;
