import cx from "classnames";
import React, { useEffect, useState } from "react";
import { getCurrentUser } from "src/store/actions/user";
import { useStoreActions } from "src/store/hooks";
import Header from "./Header";
import Sidebar from "./Sidebar";
import classes from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

const AdminLayout: React.FC<Props> = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const actions = useStoreActions({ getCurrentUser });

  useEffect(() => {
    actions.getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSidebarToggle = () => setShowSidebar(!showSidebar);
  return (
    <div className={classes.container}>
      <Sidebar
        showSidebar={showSidebar}
        handleSidebarToggle={handleSidebarToggle}
      />
      <div
        className={cx(classes.mainContainer, {
          [classes.hideContainer]: showSidebar,
        })}
      >
        <Header handleSidebarToggle={handleSidebarToggle} />
        <div
          style={{
            background:
              "linear-gradient(#1F76FE 160px, #F0F3F6 160px) no-repeat",
          }}
          className={classes.content}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
