import React from "react";
import { DropdownIndicatorProps, components } from "react-select";

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => (
  <components.DropdownIndicator {...props}>
    <i className="fa fa-caret-down" />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
