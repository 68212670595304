import { Request } from "./request";

export type PropertyFormType = {
  property_type: string;
  owner_name: string;
  owner_email: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  roof_square: string;
  insurance_company_id: number;
  insurance_claim_number: string;
};

export type AdditionalRecipients = {
  recipient_name: string;
  recipient_email: string;
};

export type ContactFormType = {
  order_id: number;
  rep_name: string;
  rep_email: string;
  rep_phone: string;
  company_name: string;
  company_address: string;
  company_city: string;
  company_state: string;
  company_zip_code: string;
  additional_recipients: AdditionalRecipients[];
};

export type ShippingDetailsProps = {
  generate_label: boolean;
  contact_name: string;
  contact_number: string;
  shipping_address: string;
  shipping_city: string;
  shipping_zipcode: string;
  shipping_state: string;
};

export type PaymentDetailsProps = {
  save: boolean;
  token: string;
  name_on_card: string;
  card_last_digit: string;
  expiry: string;
};

export type CheckoutInputDetails = {
  order_id: number;
  shipping_details: ShippingDetailsProps;
  payment_details: PaymentDetailsProps;
};

export type PromoCodeProps = {
  promo_code: string;
};

export const postPropertyDetails = async (data: PropertyFormType) => {
  return Request.call({
    url: "/api/orders/step-1",
    method: "Post",
    data,
  });
};

export const updatePropertyDetails = async (
  id: number,
  data: PropertyFormType
) => {
  return Request.call({
    url: `/api/orders/step-1/${id}`,
    method: "Post",
    data,
  });
};

export const postContactDetails = async (data: ContactFormType) => {
  return Request.call({
    url: "/api/orders/step-2",
    method: "Post",
    data,
  });
};

export const postCheckoutDetails = async (data: CheckoutInputDetails) => {
  return Request.call({
    url: "/api/orders/step-3",
    method: "Post",
    data,
  });
};

export const postCheckoutPromoCode = async (data: PromoCodeProps) => {
  return Request.call({
    url: "/api/check/couponcode",
    method: "Post",
    data,
  });
};

export const getProducts = async () => {
  return Request.call({
    url: "/api/product",
    method: "GET",
  });
};

export const getInsuranceCompanies = async () => {
  return Request.call({
    url: "/api/insurance-companies",
    method: "GET",
  });
};

export const getTypesOfSidingMaterial = async () => {
  return Request.call({
    url: "/api/siding-material-types",
    method: "GET",
  });
};
