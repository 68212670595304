export const passwordRegex = /^.{9,}\S$/;

export const telephoneNumberRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

export type ExportOptionValue = {
  report_type: "Shingle ID Report" | "Siding ID Report" | "all";
  format: "xlsx" | "csv";
};

export type OrderExportOption = {
  label: string;
  value: ExportOptionValue;
};

export const exportOrderOptions: OrderExportOption[] = [
  {
    label: "Siding ID Report (XLSX)",
    value: { report_type: "Siding ID Report", format: "xlsx" },
  },
  {
    label: "Siding ID Report (CSV)",
    value: { report_type: "Siding ID Report", format: "csv" },
  },
  {
    label: "Shingle ID Report (XLSX)",
    value: { report_type: "Shingle ID Report", format: "xlsx" },
  },
  {
    label: "Shingle ID Report (CSV)",
    value: { report_type: "Shingle ID Report", format: "csv" },
  },
  { label: "All (XLSX)", value: { report_type: "all", format: "xlsx" } },
  { label: "All (CSV)", value: { report_type: "all", format: "csv" } },
];
