import { faAddressCard, faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React from "react";
import PhoneIcon from "src/assets/icons/phone-icon.svg";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Profile: React.FC<Props> = ({ userData, handleEdit }) => {
  return (
    <Card cardClassName={classes.profileWrapper}>
      <div>
        <div className={classes.profileHeader}>
          <Typography
            className="text-start mb-0"
            fontWeight="semibold"
            variant="p24"
          >
            My Profile
          </Typography>
          <Button buttonText="Edit" size="small" onClick={handleEdit} />
        </div>
        <div className={classes.profileContent}>
          <div className="d-flex gap-3">
            <FontAwesomeIcon icon={faAddressCard} />
            <div>
              <Typography
                className="text-start mb-0 text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                First Name
              </Typography>
              <Typography
                className={cx("text-start mb-0", {
                  [classes.notProvided]: !userData?.first_name,
                })}
                fontWeight="semibold"
                variant="p16"
              >
                {userData?.first_name || "Not Provided"}
              </Typography>
            </div>
          </div>
          <div className="d-flex gap-3">
            <FontAwesomeIcon icon={faAddressCard} />
            <div>
              <Typography
                className="text-start mb-0 text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                Last Name
              </Typography>
              <Typography
                className={cx("text-start mb-0", {
                  [classes.notProvided]: !userData?.first_name,
                })}
                fontWeight="semibold"
                variant="p16"
              >
                {userData?.last_name || "Not Provided"}
              </Typography>
            </div>
          </div>
          <div className="d-flex gap-3">
            <FontAwesomeIcon icon={faAt} />
            <div>
              <Typography
                className="text-start mb-0 text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                {userData?.is_admin ? "Email Address" : "Work Email Address"}
              </Typography>
              <Typography
                className={cx("text-start mb-0 text-break", {
                  [classes.notProvided]: !userData?.email,
                })}
                fontWeight="semibold"
                variant="p16"
              >
                {userData?.email || "Not Provided"}
              </Typography>
            </div>
          </div>
          <div className="d-flex gap-3">
            <img alt="PhoneIcon" src={PhoneIcon} width={35} height={35} />
            <div>
              <Typography
                className="text-start mb-0  text-uppercase"
                variant="p12"
                fontWeight="medium"
              >
                Phone Number
              </Typography>
              <Typography
                className={cx("text-start mb-0", {
                  [classes.notProvided]: !userData?.phone,
                })}
                fontWeight="semibold"
                variant="p16"
              >
                {userData?.phone || "Not Provided"}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Profile;
