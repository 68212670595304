import { Dispatch } from "redux";

import {
  Params,
  getAllOrders as getAllOrdersApi,
  getOrderById as getOrderByIdApi,
  getReportFile as getReportFileApi,
  getShippingLabel as getShippingLabelApi,
} from "src/api/orders";
import Types from "../types/order";

export const getAllOrders = (params: Params) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_ORDERS_STARTED,
    });
    const resp = await getAllOrdersApi(params);
    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_ORDERS_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.FETCH_ORDERS_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getOrderById = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_ORDER_BY_ID_STARTED,
    });

    const resp = await getOrderByIdApi(id);
    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_ORDER_BY_ID_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.FETCH_ORDER_BY_ID_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return { data: resp };
  };
};

export const getShippingLabel = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_SHIPPING_LABEL_STARTED,
    });

    const resp = await getShippingLabelApi(id);
    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_SHIPPING_LABEL_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.FETCH_SHIPPING_LABEL_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getReportFile = (orderId: number, isForPreview?: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_REPORT_FILE_STARTED,
    });

    const resp = await getReportFileApi(orderId, isForPreview);
    if (resp?.status === 1) {
      dispatch({
        type: Types.FETCH_REPORT_FILE_SUCCESS,
        payload: { data: resp },
      });
    } else {
      dispatch({
        type: Types.FETCH_REPORT_FILE_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};
