import cx from "classnames";
import React, { useState } from "react";

import Button from "src/components/Button";
import Card from "src/components/Card";
import { forgotPassword } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";

const CheckEmail = () => {
  const [disabled, setDisabled] = useState(false);
  const actions = useStoreActions({ forgotPassword });
  const { data, loading } = useReducerData("auth", "passwordReset", {
    loading: false,
  });

  const handleResend = async () => {
    if (data?.email) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setDisabled(true);
      await actions.forgotPassword(data?.email);
      setDisabled(false);
    }
  };

  return (
    <Card cardClassName={classes.card}>
      <div
        className={cx(
          classes.checkEmailContent,
          "d-flex justify-content-center flex-column align-items-center"
        )}
      >
        <p className="mb-0">
          Check your email for the reset password link. If you did not receive
          the email, you can request another below.
        </p>
        <Button
          onClick={handleResend}
          buttonClassName={classes.resendButton}
          buttonText="Resend Link"
          loading={loading}
          disabled={disabled}
        />
      </div>
    </Card>
  );
};

export default CheckEmail;
