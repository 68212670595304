import { ExportOptionValue } from "src/utils/constants";
import { Request } from "./request";

export type Params = {
  sort_by?: string;
  sort_order?: "asc" | "desc";
  order_id?: string;
};

export type AllOrderParams = {
  page: number;
  sort_by?: string;
  sort_order?: "asc" | "desc";
  order_id?: string;
};

export type OrderUpdateParams = {
  status_id: string;
};

export type AddCreditParams = {
  subscription_id: string;
};

export type FileReportParams = {
  order_id: number;
  file: FormData;
};

export type EmailReportParams = {
  order_id: number;
};

export type CustomerProps = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
};

export const getRecentAdminOrders = async (params: Params) => {
  return Request.call({
    url: "/api/admin/orders/recent",
    method: "GET",
    params: params,
  });
};

export const getAllAdminOrders = async (params: AllOrderParams) => {
  return Request.call({
    url: "/api/admin/orders/all",
    method: "GET",
    params,
  });
};

export const getOrderStatuses = async () => {
  return Request.call({
    url: "/api/admin/orders/statuses",
    method: "GET",
  });
};

export const addCustomersCredit = async (subscription_id: number) => {
  return Request.call({
    url: "/api/admin/customers/add-credit",
    method: "PUT",
    data: { subscription_id },
  });
};

export const getAllCustomers = async (params: Params) => {
  return Request.call({
    url: `/api/admin/customers`,
    method: "GET",
    params: params,
  });
};

export const updateAdminOrder = async (id: number, data: OrderUpdateParams) => {
  return Request.call({
    url: `/api/admin/orders/${id}`,
    method: "PUT",
    data,
  });
};

export const exportAllOrder = async ({
  report_type,
  format,
}: ExportOptionValue) => {
  return Request.call(
    {
      url: `/api/admin/orders/export/all`,
      method: "GET",
      responseType: "blob",
      params: report_type === "all" ? { format } : { report_type, format },
    },
    true
  );
};

export const exportAllCustomers = async () => {
  return Request.call(
    {
      url: `/api/admin/customers/export`,
      method: "GET",
      responseType: "blob",
    },
    true
  );
};

export const uploadReport = async (data: FileReportParams) => {
  return Request.call({
    url: `/api/admin/orders/upload_report`,
    method: "POST",
    data,
  });
};

export const emailReport = async (data: EmailReportParams) => {
  return Request.call({
    url: `/api/admin/orders/send_report`,
    method: "POST",
    data,
  });
};
export const updateCustomer = async (user_id: number, data: CustomerProps) => {
  return Request.call({
    url: `/api/admin/customers/${user_id}`,
    method: "PUT",
    data,
  });
};

export const deleteCustomer = async (user_id: number) => {
  return Request.call({
    url: `/api/admin/customers/${user_id}`,
    method: "DELETE",
  });
};

export const verifyUser = async (user_id: string) => {
  return Request.call({
    url: `/api/admin/customers/verify`,
    method: "POST",
    data: { user_id },
  });
};
