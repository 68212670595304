import cx from "classnames";
import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Dropdown: React.FC<Props> = ({
  color,
  text,
  options,
  caret,
  dropdownClasses = "",
  dropdownHeaderClass = "",
  dropdownItemClass = "",
  dropdownToggleButtonClass = "",
  justifyText = "center",
  size,
  active = "",
  end = false,
  disabled,
  handleChange,
  headerText = "",
  ...rest
}) => {
  return (
    <>
      <UncontrolledDropdown
        className={cx(classes.dropDownStyling, {
          [dropdownClasses]: dropdownClasses,
        })}
      >
        <DropdownToggle
          className={cx(classes.dropdownToggle, {
            [dropdownToggleButtonClass]: dropdownToggleButtonClass,
          })}
          caret={caret}
          color={color}
          size={size}
          {...rest}
        >
          {text}
        </DropdownToggle>
        <DropdownMenu
          className={cx("dropdown-menu-arrow", classes.dropdownMenu)}
          end={end}
        >
          {headerText && (
            <DropdownItem
              className={cx({
                [dropdownHeaderClass]: dropdownHeaderClass,
              })}
              header
            >
              {headerText}
            </DropdownItem>
          )}
          {options.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {item?.divider && (
                  <DropdownItem className="opacity-50" divider />
                )}
                <DropdownItem
                  className={cx(
                    classes.dropdownItem,
                    { [dropdownItemClass]: dropdownItemClass },
                    `d-flex justify-content-${justifyText} align-items-center`
                  )}
                  disabled={!!disabled?.includes(item.value)}
                  active={active !== null && item.value === active}
                  onClick={() => handleChange(item.label, item.value)}
                >
                  {item?.icon && (
                    <div className={classes.icon}>{item?.icon}</div>
                  )}
                  {item.label}
                </DropdownItem>
              </React.Fragment>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default Dropdown;
