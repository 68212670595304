const shingleShippingInstructions =
  "https://www.dropbox.com/scl/fi/l3revvxdaevy3xwqprm82/Shipping-Instructions.pdf?dl=0&rlkey=lz27798j0qhtfoj25aw4r472q";

const sidingShippingInstructions =
  "https://www.dropbox.com/scl/fi/jfqi20a0jyu2h8v8czviw/NTS-Shipping-Instructions-2024.pdf?rlkey=3zzojewb7cx2fe33oldcaa30p&e=2&st=2hjhe8zi&dl=0";

export const accordionList = [
  {
    id: "1",
    header: "What is the cost per report?",
    content: <p>$159 per report plus shipping</p>,
  },
  {
    id: "2",
    header: "How do I send a sample?",
    content: (
      <>
        <p>
          After ordering your NTS report, you will receive an email from
          noreply@ntsid.com with mailing instructions. Please carefully fold
          your sample, include your printed order number, and place everything
          into a package to mail to NTS
        </p>
        <p>
          If you complete your order and do not receive an email, please look in
          your "Spam Folder" and/or send us an email at{" "}
          <a target="_blank" href="mailto:info@ntsid.com" rel="noreferrer">
            info@ntsid.com
          </a>{" "}
          or{" "}
          <p>
            view our detailed shipping instructions:{" "}
            <a
              href={shingleShippingInstructions}
              target="_blank"
              rel="noreferrer"
            >
              Shingle
            </a>{" "}
            or{" "}
            <a
              href={sidingShippingInstructions}
              target="_blank"
              rel="noreferrer"
            >
              Siding
            </a>
            .
          </p>
        </p>
      </>
    ),
  },
  {
    id: "3",
    header: "Does NTS perform color analysis?",
    content: (
      // <p>
      //   After the initial report is completed, NTS will perform color analysis
      //   on current/available shingles by request for an additional fee. Please
      //   send all requests to{" "}
      //   <a target="_blank" rel="noreferrer" href="mailto:info@ntsid.com">
      //     info@ntsid.com
      //   </a>
      // </p>
      <p>Color Analysis is not available at this time.</p>
    ),
  },
  {
    id: "4",
    header: "What material does NTS identify?",
    content: (
      <p>
        Currently NTS identifies asphalt shingles and siding. More material
        identification reports coming.
      </p>
    ),
  },
  {
    id: "5",
    header: "Does NTS offer Bulk Discounts?",
    content: (
      <p>
        <strong>Yes</strong>, NTS offers report bulk subscriptions. See more and
        purchase a bulk report subscription on the NTS dashboard.
      </p>
    ),
  },
];
