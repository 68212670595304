import cx from "classnames";
import React from "react";
import Select from "react-select";
import DropdownIndicator from "./DropdownIndicator";
import classes from "./styles.module.scss";
import { Props } from "./types";

const RSelect: React.FC<Props> = ({
  getOptionLabel,
  getOptionValue,
  placeholder,
  onChange,
  isClearable,
  options = [],
  touched,
  error,
  label,
  components,
  leftIcon,
  rightIcon,
  leftIconClass,
  rightIconClass,
  name = "",
  ...rest
}) => {
  return (
    <>
      {label && <label className="form-control-label mb-1">{label}</label>}
      <div
        className={cx(
          {
            [classes.isInvalid]: touched && error,
            [classes.hasLeftIcon]: leftIcon,
          },
          classes.selectStyling
        )}
      >
        {leftIcon && (
          <span className={cx(classes.leftIcon, leftIconClass)}>
            {leftIcon}
          </span>
        )}
        <Select
          name={name}
          className={classes.reactSelect}
          classNamePrefix="rselect"
          isClearable={isClearable}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onChange={onChange}
          options={options}
          components={{
            DropdownIndicator,
            ...components,
          }}
          {...rest}
        />
        {rightIcon && (
          <span className={cx(classes.rightIcon, rightIconClass)}>
            {rightIcon}
          </span>
        )}
      </div>
      {error && touched && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </>
  );
};

export default RSelect;
