/* eslint-disable max-lines */
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import omit from "lodash/omit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import CheckoutFormOneImage from "src/assets/images/checkout-step-one-image.jpg";
import Button from "src/components/Button";
import ButtonGroup from "src/components/ButtonGroup";
import CheckBox from "src/components/CheckBox";
import Input from "src/components/Input";
import Select from "src/components/Select";
import Typography from "src/components/Typography";
import CheckoutSidebar from "src/pages/OrderCreate";
import {
  getInsuranceCompanyList,
  getTypesOfSidingMaterial,
  postPropertyDetails,
  updatePropertyDetails,
} from "src/store/actions/checkout";
import { getOrderById } from "src/store/actions/order";
import { useReducerData, useStoreActions } from "src/store/hooks";
import FormikAddress from "../FormikAddress";
import { Reports } from "../SelectReport/types";
import { stateOptions } from "./constants";
import classes from "./styles.module.scss";
import { FormProps, OptionType, PropertyType } from "./types";

const PropertyForm = () => {
  const navigate = useNavigate();
  const id = useReducerData("checkout", "activeCheckoutId", null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [propertyDetails, setPropertyDetails] = useState<PropertyType | null>(
    null
  );
  const actions = useStoreActions({
    postPropertyDetails,
    getInsuranceCompanyList,
    updatePropertyDetails,
    getOrderById,
    getTypesOfSidingMaterial,
  });

  const companyList = useReducerData("checkout", "insuranceCompanyList", []);
  const sidingMaterialTypes = useReducerData(
    "checkout",
    "sidingMaterialTypes",
    []
  );
  const [propertyType, setPropertyType] = useState<string>(
    propertyDetails?.property_type || ""
  );
  const [selectedInsuranceCompany, setSelectedCompany] =
    useState<OptionType | null>(null);

  const selectedCompany = companyList.find(
    (company: { value: number }) =>
      propertyDetails?.insurance_company_id === company?.value
  );

  const selectedSidingMaterialType = sidingMaterialTypes.find(
    (sidingMaterialType: { label: string }) =>
      propertyDetails?.siding_material_type === sidingMaterialType?.label
  );

  const selectedState = stateOptions.find(
    ({ value }) => propertyDetails?.state === value
  );

  const isSidingReport = searchParams.get("report") === Reports.Siding;

  const checkoutSchema = Yup.object().shape({
    owner_email: Yup.string().email("Must be valid email addresses"),
    owner_name: Yup.string(),
    address: Yup.string().required("Please enter the property address"),
    insurance_claim_number: Yup.string(),
    siding_material_type: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
    email_copy_to_owner: Yup.boolean(),
    roof_square: Yup.string(),
    home_square: Yup.string(),
    city: Yup.string().required("Please enter city name"),
    zip_code: Yup.string().required("Please enter zip code"),
    other_insurance_company: Yup.string().when([], {
      is: () => selectedInsuranceCompany?.label !== "Other",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Company name is required"),
    }),
    state: Yup.object()
      .required("Please select state")
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
    insurance_company_id: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
  });

  useEffect(() => {
    const fetchOrder = async () => {
      if (id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { data } = await actions.getOrderById(id as string);
        setPropertyDetails(data?.property_detail);
        setPropertyType(data?.property_detail?.property_type);
      }
    };
    const getCompanies = async () => {
      actions.getInsuranceCompanyList();
    };
    if (isSidingReport) {
      actions.getTypesOfSidingMaterial();
    }
    getCompanies();
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // eslint-disable-next-line react/no-multi-comp
  const DropdownIndicator = () => (
    <span className={classes.caret}>
      <FontAwesomeIcon icon={faCaretDown} />
    </span>
  );

  const handleSubmit = async (values: FormProps) => {
    if (loading) return;
    setLoading(true);

    const finalValues = {
      ...values,
      report_type: isSidingReport ? "Siding ID Report" : "Shingle ID Report",
      insurance_claim_number: values?.insurance_claim_number || "",
      siding_material_type: values?.siding_material_type?.label || "",
      insurance_company_id: values?.insurance_company_id?.value || "",
      state: values?.state?.value || "",
      property_type: propertyType,
    };

    const propertyData = isSidingReport
      ? omit(finalValues, ["roof_square", "property_type"])
      : omit(finalValues, ["home_square", "siding_material_type"]);

    if (id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { status } = await actions.updatePropertyDetails(id, propertyData);
      setLoading(false);
      if (status === 1) {
        navigate(
          `/order/create/contact?report=${
            isSidingReport ? Reports.Siding : Reports.Shingle
          }`
        );
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { status } = await actions.postPropertyDetails(propertyData);
      setLoading(false);
      if (status === 1) {
        navigate(
          `/order/create/contact?report=${
            isSidingReport ? Reports.Siding : Reports.Shingle
          }`
        );
      }
    }
  };

  return (
    <CheckoutSidebar
      title="Property information"
      step={1}
      bgImage={CheckoutFormOneImage}
      content={`Tell us about the property in question. This information will be included in the final ${
        isSidingReport ? "siding" : "shingle"
      } identification report.`}
    >
      <div className={classes.wrapper}>
        <Typography
          fontWeight="bold"
          variant="p18"
          className={cx(classes.titleWrapper, "text-start")}
        >
          Let’s get started with information about the property{" "}
        </Typography>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={checkoutSchema}
          initialValues={{
            owner_email: propertyDetails?.owner_email || "",
            owner_name: propertyDetails?.owner_name || "",
            address: propertyDetails?.address || "",
            other_insurance_company:
              propertyDetails?.other_insurance_company || "",
            insurance_claim_number:
              propertyDetails?.insurance_claim_number || "",
            siding_material_type:
              selectedSidingMaterialType || (null as unknown as OptionType),
            email_copy_to_owner: propertyDetails?.email_copy_to_owner || false,
            roof_square: propertyDetails?.roof_square || "",
            home_square: propertyDetails?.home_square || "",
            city: propertyDetails?.city || "",
            state: selectedState || (null as unknown as OptionType),
            insurance_company_id:
              selectedCompany || (null as unknown as OptionType),
            zip_code: propertyDetails?.zip_code || "",
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
            setFieldTouched,
            setFieldValue,
          }: FormikProps<FormProps>) => (
            <Form className={cx(classes.form, "d-flex flex-column")}>
              <FormikAddress
                inputId="property-form-address"
                inputNames={{
                  address: "address",
                  city: "city",
                  state: "state",
                  zip_code: "zip_code",
                }}
                inputAddressPlaceholder="Enter property address"
                inputAddressLabel="Property Address"
                values={{
                  address: values.address,
                  city: values.city,
                  state: values.state,
                  zip_code: values.zip_code,
                }}
                touched={{
                  address: touched.address,
                  city: touched.city,
                  state: touched.state,
                  zip_code: touched.zip_code,
                }}
                errors={{
                  address: errors.address,
                  city: errors.city,
                  state: errors.state,
                  zip_code: errors.zip_code,
                }}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
              <div className={classes.formGroup}>
                <div className={classes.buttonWrapper}>
                  {isSidingReport ? (
                    <div>
                      <Select
                        components={{
                          DropdownIndicator: DropdownIndicator,
                        }}
                        name={propertyDetails?.siding_material_type}
                        placeholder="Select siding material"
                        label="Type of Siding Material"
                        value={values?.siding_material_type}
                        error={errors?.siding_material_type as string}
                        touched={
                          touched?.siding_material_type as unknown as boolean
                        }
                        leftIconClass={classes.icon}
                        onBlur={() => {
                          if (setFieldTouched) {
                            setFieldTouched("siding_material_type", true);
                          }
                        }}
                        onChange={(selectedOption) => {
                          if (setFieldValue) {
                            setFieldValue(
                              "siding_material_type",
                              selectedOption
                            );
                          }
                        }}
                        options={sidingMaterialTypes}
                      />
                    </div>
                  ) : (
                    <div>
                      <Typography
                        fontWeight="medium"
                        variant="p14"
                        className={classes.label}
                      >
                        Property Type <span>*</span>
                      </Typography>
                      <ButtonGroup className={classes.buttonGroup}>
                        <Button
                          onClick={() => setPropertyType("residential")}
                          type="button"
                          buttonText="Residential"
                          buttonColor={
                            propertyType === "residential"
                              ? "primary"
                              : "secondary"
                          }
                        />
                        <Button
                          onClick={() => setPropertyType("commercial")}
                          type="button"
                          buttonText="Commercial"
                          buttonColor={
                            propertyType === "commercial"
                              ? "primary"
                              : "secondary"
                          }
                        />
                      </ButtonGroup>
                    </div>
                  )}
                  <div className={classes.sqftFieldWrapper}>
                    <Input
                      inputGroupClassName={classes.inputGroupStyle}
                      rightIcon="SQFT"
                      labelClassName={classes.label}
                      label={
                        isSidingReport
                          ? "Home Square Footage"
                          : "Roof Square Footage"
                      }
                      value={
                        isSidingReport ? values.home_square : values.roof_square
                      }
                      placeholder="Enter SQFT"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        isSidingReport ? errors.home_square : errors.roof_square
                      }
                      touched={
                        isSidingReport
                          ? touched.home_square
                          : touched.roof_square
                      }
                      name={isSidingReport ? "home_square" : "roof_square"}
                    />
                  </div>
                </div>
              </div>
              <FormGroup className={classes.formGroup}>
                <Input
                  labelClassName={classes.label}
                  inputGroupClassName={classes.inputGroupStyle}
                  label="Property Owner Name"
                  value={values.owner_name}
                  placeholder="Enter owner name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.owner_name}
                  touched={touched.owner_name}
                  name="owner_name"
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Input
                  inputGroupClassName={classes.inputGroupStyle}
                  labelClassName={classes.label}
                  label="Property Owner Email Address"
                  value={values.owner_email}
                  placeholder="Enter email address "
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.owner_email}
                  touched={touched.owner_email}
                  name="owner_email"
                />
              </FormGroup>
              <FormGroup
                className={cx("text-start", classes.formGroupCheckBox)}
              >
                <CheckBox
                  checkBoxClassName="mb-0"
                  onChange={handleChange}
                  checked={values?.email_copy_to_owner || false}
                  onBlur={handleBlur}
                  error={errors.email_copy_to_owner}
                  touched={touched.email_copy_to_owner}
                  showOutline
                  label={
                    <Typography variant="p14" className="mb-0">
                      Email a copy of the final report to the property owner
                    </Typography>
                  }
                  name="email_copy_to_owner"
                />
              </FormGroup>
              <div className={classes.insuranceDetails}>
                <FormGroup className={classes.formGroup}>
                  <Select
                    menuPlacement="top"
                    components={{
                      DropdownIndicator: DropdownIndicator,
                    }}
                    name="insurance_company_id"
                    value={values.insurance_company_id}
                    placeholder="Select insurance company"
                    label="Insurance Company"
                    error={errors.insurance_company_id as string}
                    touched={touched.insurance_company_id as unknown as boolean}
                    leftIconClass={classes.icon}
                    onBlur={() => {
                      setFieldTouched("insurance_company_id", true);
                    }}
                    onChange={(selectedOption) => {
                      setSelectedCompany(selectedOption as OptionType);
                      setFieldValue("insurance_company_id", selectedOption);
                    }}
                    options={companyList}
                  />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                  <Input
                    inputGroupClassName={classes.inputGroupStyle}
                    label="Insurance Claim Number"
                    labelClassName={classes.label}
                    value={values.insurance_claim_number}
                    placeholder="Enter claim number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.insurance_claim_number}
                    touched={touched.insurance_claim_number}
                    name="insurance_claim_number"
                  />
                </FormGroup>
              </div>
              {values?.insurance_company_id?.value == "31" && (
                <FormGroup className={classes.formGroup}>
                  <Input
                    required
                    inputGroupClassName={classes.inputGroupStyle}
                    label="Insurance Company Name"
                    labelClassName={classes.label}
                    value={values.other_insurance_company}
                    placeholder="Insurance company name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.other_insurance_company}
                    touched={touched.other_insurance_company}
                    name="other_insurance_company"
                  />
                </FormGroup>
              )}
              <div className={classes.nextStep}>
                <Button
                  loading={loading}
                  disabled={
                    !isValid || !dirty || !(propertyType || isSidingReport)
                  }
                  type="submit"
                  buttonText="Continue to Step 2"
                  rightIcon={<i className="fa fa-arrow-circle-o-right" />}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CheckoutSidebar>
  );
};

export default PropertyForm;
