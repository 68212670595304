/* eslint-disable max-lines */
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import get from "lodash/get";
import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import CardIcon from "src/assets/icons/card-icon.svg";
import { alertPopup } from "src/components/AlertPopup/AlertPopupManager";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { ErrorMessageProps } from "src/pages/OrderCreate/Components/CheckoutForm/types";
import StripeForm from "src/pages/OrderCreate/Components/StripeForm";
import { StripeStateProps } from "src/pages/OrderCreate/Components/StripeForm/types";
import {
  addPaymentMethod,
  getCurrentUser,
  removePaymentMethod,
} from "src/store/actions/user";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";

type EditPaymentProps = {
  name_on_card: string;
};

type StripeElementProps = {
  cardExpiry: StripeStateProps | null;
  cardCvc: StripeStateProps | null;
  cardNumber: StripeStateProps | null;
};

const PaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { data } = useReducerData("user", "user", {});

  const payment_methods = get(data, "payment_methods");
  const payment_method_id = get(data, "payment_methods.id");

  const [loading, setLoading] = useState(false);

  const [mode, setMode] = useState<"view" | "edit">("view");
  const [error, setError] = useState<ErrorMessageProps>({
    number: "",
    date: "",
    code: "",
  });

  const initialStripeValue = {
    cardExpiry: null,
    cardCvc: null,
    cardNumber: null,
  };

  const [stripeState, setStripeState] =
    useState<StripeElementProps>(initialStripeValue);

  const actions = useStoreActions({
    removePaymentMethod,
    getCurrentUser,
    addPaymentMethod,
  });

  const handleRemovePayment = (id: number) => {
    alertPopup.showCustom({
      warning: true,
      onConfirm: () => {},
      title: "Are you sure you want to delete this payment method?",
      children: "This action can not be undone",
      customButtonList: [
        {
          buttonText: "Keep Payment Method",
          buttonColor: "success",
          handleConfirm: () => {},
        },
        {
          buttonText: "Delete Payment Method",
          buttonColor: "danger",
          handleConfirm: async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { status } = await actions.removePaymentMethod(id);
            if (status) {
              setStripeState(initialStripeValue);
              setMode("view");
              actions.getCurrentUser();
            }
          },
        },
      ],
    });
  };

  const getButtonStates = () => {
    if (mode === "view" && payment_methods === null) {
      return { class: "add", label: "Add Card" };
    }
    if (mode === "view" && payment_methods) {
      return { class: "edit", label: "Edit" };
    }
    if (mode === "edit" && payment_methods === null) {
      return { class: "onEdit", label: "" };
    }
    if (mode === "edit" && payment_methods) {
      return { class: "delete", label: "Delete Card" };
    }

    return { class: "add", label: "Add Card" };
  };

  const handleAction = () => {
    switch (getButtonStates().class) {
      case "add":
        setMode("edit");
        return;
      case "edit":
        setMode("edit");
        return;
      case "onEdit":
        setMode("edit");
        return;
      case "delete":
        handleRemovePayment(payment_method_id);
        return;

      default:
        return;
    }
  };

  const validatePaymentForm = (type: string) => {
    if (error.number === "" && type === "cardNumber") {
      setError({ ...error, number: "Please enter card number" });
    }
    if (error.date === "" && type === "cardExpiry") {
      setError({ ...error, date: "Please enter expiry date" });
    }
    if (error.code === "" && type === "cardCvc") {
      setError({ ...error, code: "Please enter secure code" });
    }
  };

  const handleInputChange = (event: StripeStateProps) => {
    setStripeState((prev) => ({
      ...prev,
      [event.elementType]: event,
    }));
  };

  const editUserProfileSchema = Yup.object().shape({
    name_on_card: Yup.string().required("Cardholder name is required"),
  });

  const isValidNumber = !error.number && stripeState.cardNumber?.complete;
  const isValidDate = !error.date && stripeState.cardExpiry?.complete;
  const isValidCode = !error.number && stripeState.cardCvc?.complete;

  const isPaymentDetailsValid = isValidNumber && isValidDate && isValidCode;

  const renderForm = () => {
    return (
      <div>
        <Formik
          onSubmit={async (values) => {
            setLoading(true);
            const cardNumberElement = await elements?.getElement(
              CardNumberElement
            );
            if (!cardNumberElement) {
              setLoading(false);
              return;
            }
            if (!stripe || !elements) {
              setLoading(false);
              return;
            }
            const { token } = await stripe.createToken(cardNumberElement, {
              name: values.name_on_card,
            });

            const expiryMonth =
              token?.card?.exp_month.toString().length === 1
                ? `0${token?.card?.exp_month}`
                : token?.card?.exp_month;
            const expiryYear = token?.card?.exp_year.toString().slice(2, 4);
            const cardExpiry = `${expiryMonth}/${expiryYear}`;
            const stripeToken = token?.id;
            const lastFourDigits = token?.card?.last4;

            const payload = {
              token: stripeToken,
              name_on_card: values.name_on_card,
              card_last_digit: lastFourDigits,
              expiry: cardExpiry,
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { status } = await actions.addPaymentMethod(payload);
            if (status) {
              setMode("view");
              setStripeState(initialStripeValue);
              actions.getCurrentUser();
            }
            ///
            setLoading(false);
          }}
          validationSchema={editUserProfileSchema}
          initialValues={{
            name_on_card: payment_methods?.name_on_card || "",
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
          }: FormikProps<EditPaymentProps>) => (
            <Form className={cx("d-flex flex-column", classes.form)}>
              <Typography
                fontWeight="medium"
                variant="p14"
                className={classes.label}
              >
                Card Details
              </Typography>
              <div className={classes.stripeForm}>
                <StripeForm
                  error={error}
                  setError={setError}
                  validatePaymentForm={validatePaymentForm}
                  numberOptions={{ placeholder: "Card number" }}
                  expiryOptions={{ placeholder: "MM/YY" }}
                  cvcOptions={{ placeholder: "CVV" }}
                  onInputChange={handleInputChange}
                />
              </div>
              <FormGroup className={classes.formGroup}>
                <Input
                  inputGroupClassName={classes.inputStyle}
                  labelClassName={classes.profileInputLabel}
                  value={values.name_on_card}
                  placeholder="Cardholder name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.name_on_card}
                  touched={touched.name_on_card}
                  name="name_on_card"
                />
              </FormGroup>

              <div className={classes.actionButton}>
                <Button
                  disabled={
                    !isValid ||
                    (getButtonStates().class !== "delete" && !dirty) ||
                    !isPaymentDetailsValid
                  }
                  type="submit"
                  className={classes.saveButton}
                  buttonText="Save Changes"
                  loading={loading}
                />
                <Button
                  type="submit"
                  buttonText="Cancel"
                  buttonColor="secondary"
                  onClick={() => {
                    setStripeState(initialStripeValue);
                    setMode("view");
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <Card cardBodyClassName="p-0" cardClassName={classes.paymentMethodWrapper}>
      <div>
        <div className={classes.header}>
          <Typography
            className="text-start mb-0"
            fontWeight="semibold"
            variant="p24"
          >
            Payment Method
          </Typography>
          <Button
            // variant="outline"
            buttonClassName={cx(
              classes.button,
              classes[getButtonStates().class]
            )}
            buttonText={getButtonStates().label}
            size="small"
            onClick={handleAction}
          />
        </div>

        {mode === "edit" ? renderForm() : null}

        {payment_methods && mode === "view" ? (
          <div className="d-flex gap-3 mb-0 mt-3">
            <div className="d-flex align-items-center flex-wrap gap-5 w-100">
              <div className="d-flex align-items-center gap-3 ">
                <img alt="CardIcon" src={CardIcon} width={35} height={35} />
                <div>
                  <Typography
                    className="text-start mb-0 text-uppercase"
                    variant="p12"
                    fontWeight="medium"
                  >
                    CARD NUMBER
                  </Typography>
                  <Typography
                    className="text-start mb-0"
                    fontWeight="semibold"
                    variant="p16"
                  >
                    {payment_methods?.card_last_digit
                      ? `**** ${payment_methods?.card_last_digit}`
                      : "-"}
                  </Typography>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3 ">
                <FontAwesomeIcon color="#1F76FE" icon={faAddressCard} />
                <div>
                  <Typography
                    className="text-start mb-0 text-uppercase"
                    variant="p12"
                    fontWeight="medium"
                  >
                    CARD HOLDER NAME
                  </Typography>
                  <Typography
                    className="text-start mb-0"
                    fontWeight="semibold"
                    variant="p16"
                  >
                    {payment_methods?.name_on_card || "-"}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Card>
  );
};

export default PaymentMethod;
