import cx from "classnames";
import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { postCheckoutPromoCode } from "src/store/actions/checkout";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./styles.module.scss";
import { ProductsProps, Props } from "./types";

const OrderSummary: React.FC<Props> = ({ generateLabel = true }) => {
  const { width = 0 } = useWindowSize();
  const { data } = useReducerData("checkout", "products", {});
  const { loading } = useReducerData("checkout", "promoSuccessDetails", {
    loading: false,
  });

  const actions = useStoreActions({
    postCheckoutPromoCode,
  });

  const isTablet = width < 1024;

  const [products, setProducts] = useState<ProductsProps>(null);
  const [showPromoCodeInputs, setShowPromoCodeInputs] = useState(false);
  const [promoCodeSuccessDetails, setPromoCodeSuccessDetails] = useState({
    id: "",
    name: "",
    amount: 0,
    type: "",
    percentage: 0,
  });
  const [error, setError] = useState("");
  const [code, setCode] = useState("");

  const productAmount = products?.product_details?.unit_amount || 0;
  const productQuantity = products?.product_details?.unit || 1;
  const shippingAmount = products?.shipping_details?.unit_amount || 0;
  const shippingQuantity = products?.shipping_details?.unit || 1;
  const subscriptionAmount = products?.subscription_details?.unit_amount || 0;
  const subscriptionQuantity = products?.subscription_details?.unit || 1;
  const totalAmount =
    productAmount * productQuantity +
    shippingAmount * shippingQuantity -
    subscriptionAmount * subscriptionQuantity;
  const shippingDiscount = (generateLabel ? 0 : shippingAmount) || 0;
  const subscriptionDiscount = subscriptionAmount * subscriptionQuantity;
  const subTotal =
    productAmount + shippingAmount - shippingDiscount - subscriptionDiscount;
  useEffect(() => {
    if (data) {
      setProducts(data);
    }
  }, [data]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCode(value);
    setError("");
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value !== "") {
      setError("");
    }
  };

  const handleSubmit = async () => {
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { status, data, ...rest } = await actions.postCheckoutPromoCode({
      promo_code: code,
    });
    if (status === 1) {
      setPromoCodeSuccessDetails((prev) => ({ ...prev, ...(rest || {}) }));
    } else {
      setError(data?.error || "");
    }
  };

  const totalAmountWithDiscount = (
    totalAmount: number,
    discountType: string,
    discountAmount: number,
    percentageAmount: number
  ) => {
    switch (discountType) {
      case "percentage_discount":
        return (totalAmount - totalAmount * (percentageAmount / 100)).toFixed(
          2
        ) as unknown as number;
      case "fixed_amount_discount":
        return totalAmount - discountAmount;
      default:
        return totalAmount;
    }
  };

  return (
    <Card
      cardClassName={classes.cardWrapper}
      cardTitleClassName={classes.title}
      cardBodyClassName={classes.cardBody}
      cardTitle="Order Summary"
    >
      <div className={classes.summaryWrapper}>
        {products?.product_details && (
          <div className={classes.items}>
            <Typography variant="p16">
              {products?.product_details?.unit || 0} x{" "}
              {products?.product_details?.name || ""}
            </Typography>
            <Typography variant="p16">${productAmount}</Typography>
          </div>
        )}
        {products?.subscription_details && (
          <div className={classes.subscriptionCredit}>
            <Typography variant="p16">
              {products?.subscription_details?.unit || 0} x{" "}
              {products?.subscription_details?.name || ""}
            </Typography>
            <Typography variant="p16">-${subscriptionAmount}</Typography>
          </div>
        )}
        {products?.shipping_details && generateLabel && (
          <div className={classes.items}>
            <Typography variant="p16">
              {products?.shipping_details?.unit || 0} x{" "}
              {products?.shipping_details?.name || ""}
            </Typography>
            <Typography variant="p16">${shippingAmount}</Typography>
          </div>
        )}
        <div className={classes.items}>
          <Typography variant="p16" fontWeight="bold">
            Subtotal
          </Typography>
          <Typography variant="p16" fontWeight="bold">
            ${subTotal}
          </Typography>
        </div>
        <div className={classes.promoCode}>
          {!showPromoCodeInputs && !promoCodeSuccessDetails?.id && (
            <div
              className={classes.enterCode}
              onClick={() => subTotal > 0 && setShowPromoCodeInputs(true)}
            >
              <Typography className={"mt-3"} variant="p14">
                + Add a promo code
              </Typography>
            </div>
          )}
          {showPromoCodeInputs && !promoCodeSuccessDetails?.id && (
            <div className={classes.promoCodeInput}>
              <FormGroup>
                <Input
                  className={classes.promoCodeInputStyle}
                  placeholder="Promo code"
                  onChange={handleInputChange}
                  onBlur={handleOnBlur}
                  value={code}
                  name="promoCode"
                />
                {error && <span className={classes.errorMessage}>{error}</span>}
              </FormGroup>
              <Button
                variant={isTablet ? "fill" : "outline"}
                buttonClassName={cx(classes.applyButton, {
                  [classes.loadingBtn]: loading,
                })}
                disabled={!code || loading || subTotal === 0}
                loading={loading}
                buttonColor="info"
                buttonText="Apply"
                onClick={handleSubmit}
              />
            </div>
          )}
          {promoCodeSuccessDetails?.id && (
            <div className={classes.promoCodeWrapper}>
              <span>
                Promo code:<b className={classes.boldText}>{code}</b>
              </span>
              <span>
                {promoCodeSuccessDetails?.percentage ? (
                  <b>{promoCodeSuccessDetails?.percentage || 0}% off</b>
                ) : (
                  <b>${promoCodeSuccessDetails?.amount || 0} discount</b>
                )}
              </span>
            </div>
          )}
        </div>
        <div className={cx(classes.items, "mt-3")}>
          <Typography variant="p20" fontWeight="bold">
            Total due
          </Typography>
          <Typography variant="p20" fontWeight="bold">
            $
            {subTotal > 0
              ? totalAmountWithDiscount(
                  totalAmount,
                  promoCodeSuccessDetails.type,
                  promoCodeSuccessDetails.amount,
                  promoCodeSuccessDetails.percentage
                ) - shippingDiscount
              : 0}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default OrderSummary;
