import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Modal from "src/components/Modal";
import Typography from "src/components/Typography";
import { postSupportRequest } from "src/store/actions/user";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./styles.module.scss";
import { FormProps, Props } from "./types";

const SupportModal: React.FC<Props> = ({ isOpen, onToggle }) => {
  const { loading } = useReducerData("user", "supportFormSubmit", {
    loading: false,
  });
  const actions = useStoreActions({ postSupportRequest });

  const checkoutSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    msg: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values: FormProps) => {
    await actions.postSupportRequest(values);
    onToggle();
  };

  return (
    <Modal className={classes.modalWrapper} isOpen={isOpen} toggle={onToggle}>
      <div>
        <Typography variant="h3" fontWeight="bold">
          Email Support
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={checkoutSchema}
          initialValues={{
            subject: "",
            msg: "",
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
          }: FormikProps<FormProps>) => (
            <Form className={cx(classes.form, "d-flex flex-column")}>
              <FormGroup className={classes.formGroup}>
                <Input
                  inputGroupClassName={classes.inputGroupStyle}
                  labelClassName={classes.label}
                  required={true}
                  label="Subject"
                  value={values.subject}
                  placeholder="Subject..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.subject}
                  touched={touched.subject}
                  name="subject"
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Input
                  required={true}
                  type="textarea"
                  rows="6"
                  inputClassName={classes.textarea}
                  inputGroupClassName={classes.inputGroupStyle}
                  labelClassName={classes.label}
                  label="Message"
                  value={values.msg}
                  placeholder="Message..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.msg}
                  touched={touched.msg}
                  name="msg"
                />
              </FormGroup>

              <div className={classes.actionButtons}>
                <Button
                  buttonClassName={classes.submitButton}
                  loading={loading}
                  disabled={!isValid || !dirty}
                  type="submit"
                  buttonColor="primary"
                  buttonText="Submit"
                />
                <Button
                  buttonColor="secondary"
                  buttonText="Cancel"
                  onClick={onToggle}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default SupportModal;
