import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { Container, FormGroup } from "reactstrap";
import * as Yup from "yup";
import Logo from "src/assets/icons/logo.svg";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Input from "src/components/Input";
import { resetPassword } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { passwordRegex } from "src/utils/constants";
import classes from "./styles.module.scss";
import { ConfirmPasswordProps } from "./types";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const actions = useStoreActions({ resetPassword });
  const { loading } = useReducerData("auth", "passwordReset", {
    loading: false,
  });

  const resetPasswordInSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(passwordRegex, "Password needs to be 10+ characters"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  });

  const handleSubmit = async (
    values: ConfirmPasswordProps,
    formikHelpers: FormikHelpers<ConfirmPasswordProps>
  ) => {
    const { password, confirmPassword } = values;
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    const data = {
      email,
      token,
      password,
      password_confirmation: confirmPassword,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.resetPassword(data);
    if (status) {
      formikHelpers.resetForm();
      navigate("/auth/login");
    }
  };

  return (
    <Container className={classes.container}>
      <div
        className={cx(
          classes.resetPasswordHeader,
          "d-flex flex-column  align-items-center justify-content-center"
        )}
      >
        <div className={classes.logo}>
          <Link to={"/auth/login"}>
            <img alt="logo" src={Logo} />
          </Link>
        </div>
        <p>Reset your password</p>
      </div>
      <Card
        cardClassName={classes.card}
        cardTitle="Enter your new password below"
      >
        <Formik
          onSubmit={(values, formikHelpers) =>
            handleSubmit(values, formikHelpers)
          }
          validationSchema={resetPasswordInSchema}
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
          }: FormikProps<ConfirmPasswordProps>) => (
            <Form className="d-flex flex-column">
              <FormGroup>
                <Input
                  value={values.password}
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password}
                  touched={touched.password}
                  name="password"
                  type="password"
                  leftIcon={<i className="fa fa-solid fa-key" />}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  value={values.confirmPassword}
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  name="confirmPassword"
                  type="password"
                  leftIcon={<i className="fa fa-solid fa-key" />}
                />
              </FormGroup>

              <div className={classes.buttonWrapper}>
                <Button
                  disabled={!isValid || !dirty}
                  buttonClassName={classes.setPassword}
                  type="submit"
                  buttonText="Set Password"
                  loading={loading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};

export default ResetPassword;
