import { AnyAction, Reducer, combineReducers } from "redux";
import { RootState } from "../index";
import Types from "../types/auth";
import AdminReducer from "./admin";

import AuthReducer from "./auth";
import CheckoutReducer from "./checkout";
import HomeReducer from "./home";
import OrderReducer from "./order";
import SubscriptionPlanReducer from "./subscription";
import UserReducer from "./user";

const reducers = {
  order: OrderReducer,
  auth: AuthReducer,
  user: UserReducer,
  checkout: CheckoutReducer,
  home: HomeReducer,
  plans: SubscriptionPlanReducer,
  admin: AdminReducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === Types.USER_LOGOUT_SUCCESS) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
