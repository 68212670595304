import { Reducer } from "redux";

import SubscriptionTypes from "../types/subscription";
import Types from "../types/user";

const initialState = {
  user: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  supportFormSubmit: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  addPayment: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  clientDetails: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
};

const UserReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const isAdmin = state.user?.data.is_admin === 1;
  const userType = isAdmin ? "clientDetails" : "user";

  switch (type) {
    case Types.USER_FETCH_STARTED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_FETCH_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.USER_FETCH_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_UPDATE_STARTED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          isError: false,
          message: "",
          data: {
            ...state.user.data,
            ...payload.data,
          },
        },
      };
    case Types.USER_UPDATE_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_REMOVE_PAYMENT_METHOD_STARTED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_REMOVE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          isError: false,
          message: "",
        },
      };
    case Types.USER_REMOVE_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_POST_SUPPORT_REQUEST_STARTED:
      return {
        ...state,
        supportFormSubmit: {
          ...state.supportFormSubmit,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_POST_SUPPORT_REQUEST_SUCCESS:
      return {
        ...state,
        supportFormSubmit: {
          ...state.supportFormSubmit,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.USER_POST_SUPPORT_REQUEST_FAILED:
      return {
        ...state,
        supportFormSubmit: {
          ...state.supportFormSubmit,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.ADD_PAYMENT_METHOD_STARTED:
      return {
        ...state,
        addPayment: {
          ...state.addPayment,
          loading: true,
          isError: false,
        },
      };
    case Types.ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        addPayment: {
          ...state.addPayment,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.ADD_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        addPayment: {
          ...state.addPayment,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case SubscriptionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        [userType]: {
          ...state?.[userType],
          data: {
            ...state?.[userType].data,
            subscription: {
              ...(state?.[userType]?.data?.subscription ?? {}),
              ...(payload?.plan ?? {}),
            },
          },
        },
      };
    case SubscriptionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        [userType]: {
          ...state?.[userType],
          data: {
            ...state[userType].data,
            subscription: {
              ...(state?.[userType]?.data?.subscription ?? {}),
              ...(payload?.plan ?? {}),
            },
          },
        },
      };
    case Types.GET_CLIENT_DETAILS_STARTED:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          loading: true,
          isError: false,
        },
      };
    case Types.GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          data: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.GET_CLIENT_DETAILS_FAILED:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.POST_USER_TERMS_STARTED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.POST_USER_TERMS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          isError: false,
          message: "",
          data: {
            ...state.user.data,
            ...payload.data,
          },
        },
      };
    case Types.POST_USER_TERMS_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    default:
      return state;
  }
};

export default UserReducer;
