import Cookie from "js-cookie";
import { PropertyDetailProps } from "src/pages/OrderDetails/types";

export const getFormattedNumber = (value: {
  currentTarget: { value: string };
}) => {
  const x = value.currentTarget.value
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return x && !x[2] ? x[1] : x && x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
};

export const isLoggedIn = () => {
  const token = Cookie.get("token");
  return !!token;
};

export const getFullAddress = (address: PropertyDetailProps) => {
  return `${address.address} ${address.city} ${address.state} ${address.zip_code}`;
};
