import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";

import classes from "./App.module.scss";
import ScrollToTop from "./components/ScrollToTop";
import AdminLayout from "./layout/AdminLayout";
import AuthLayout from "./layout/AuthLayout";
import CheckoutLayout from "./layout/CheckoutLayout";
import PrivateLayout from "./layout/PrivateLayout";
import PublicLayout from "./layout/PublicLayout";
import RoutesList from "./routes";
import { useReducerData } from "./store/hooks";
import { isLoggedIn } from "./utils/utils";

const App = () => {
  const getLoginState = isLoggedIn();
  const location = useLocation();
  const { data: user } = useReducerData("user", "user", {});

  const [isLogin, setIsLogin] = useState(getLoginState);

  useEffect(() => {
    const isLogIn = isLoggedIn();
    setIsLogin(isLogIn);
  }, [location]);

  const renderRoutes = () => {
    const renderRoute = (
      Component: React.FC,
      layout: string,
      toRedirect: boolean
    ) => {
      if (Component) {
        switch (layout) {
          case "private":
            return !isLogin ? (
              <Navigate to="/auth/login" replace />
            ) : toRedirect ? (
              user.is_admin ? (
                <Navigate to="/admin/dashboard" replace />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            ) : (
              <PrivateLayout>
                <Component />
              </PrivateLayout>
            );
          case "admin":
            return !isLogin ? (
              <Navigate to="/auth/login" replace />
            ) : toRedirect ? (
              <Navigate to="/admin/dashboard" replace />
            ) : (
              <AdminLayout>
                <Component />
              </AdminLayout>
            );
          case "auth":
            return isLogin ? (
              user.is_admin ? (
                <Navigate to="/admin/dashboard" replace />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            ) : toRedirect ? (
              <Navigate to="/auth/login" replace />
            ) : (
              <AuthLayout>
                <Component />
              </AuthLayout>
            );
          case "public":
            return (
              <PublicLayout>
                <Component />
              </PublicLayout>
            );
          case "checkout":
            return isLogin ? (
              <CheckoutLayout>
                <Component />
              </CheckoutLayout>
            ) : (
              <Navigate to="/auth/login?create=true" replace />
            );
        }
      }
      return null;
    };

    return RoutesList.map((route) => {
      if (
        (route.layout === "admin" || route.layout === "auth") &&
        user.is_admin
      ) {
        return (
          <Route
            key={route.name}
            path={route.path}
            element={renderRoute(
              route.component,
              route.layout,
              route.path === "*"
            )}
          />
        );
      } else if (!user.is_admin && route.layout !== "admin") {
        return (
          <Route
            key={route.name}
            path={route.path}
            element={renderRoute(
              route.component,
              route.layout,
              route.path === "*"
            )}
          />
        );
      }
    });
  };

  return (
    <div className={classes.app}>
      <ScrollToTop />
      <Routes>{renderRoutes()}</Routes>
    </div>
  );
};

export default App;
