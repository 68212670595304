import React from "react";
import ContactUs from "./Components/ContactUs";
import FAQ from "./Components/FAQ";
import HeroComponent from "./Components/HeroComponent";
import NTSMerch from "./Components/NTSMerch";
import NTSMission from "./Components/NTSMission";
import Pricing from "./Components/Pricing";
import ReportSample from "./Components/ReportSample";

const Home = () => {
  return (
    <div>
      <HeroComponent />
      <ReportSample />
      <FAQ />
      <Pricing />
      <NTSMission />
      <NTSMerch />
      <ContactUs />
    </div>
  );
};

export default Home;
