import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { alertPopup } from "src/components/AlertPopup/AlertPopupManager";

const StripeCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = searchParams.get("subscription");
  const openPopup = () => {
    if (params === "1") {
      alertPopup.showCustom({
        success: true,
        onConfirm: () => navigate("/order/create/select-report"),
        title: "Success! Thanks for subscribing!",
        children:
          "Would you like to order an NTS Shingle Identification report now?",
        customButtonList: [
          {
            buttonText: "Order a report",
            buttonColor: "success",
            handleConfirm: () => navigate("/order/create/select-report"),
          },
          {
            buttonText: "Not now",
            buttonColor: "secondary",
            variant: "outline",
            handleConfirm: () => {
              navigate("/dashboard");
            },
          },
        ],
      });
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    openPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
};

export default StripeCallback;
