import cx from "classnames";
import React from "react";
import { Input } from "reactstrap";
import classes from "./styles.module.scss";
import { Props } from "./types";

const CheckBox: React.FC<Props> = (Props) => {
  const {
    checkBoxClassName = "",
    labelClassName = "",
    disabled = false,
    error = "",
    label,
    id,
    showOutline = false,
    checked = false,
    indeterminate = false,
    touched = false,
    ...rest
  } = Props;

  return (
    <>
      <div
        className={cx(
          "custom-control custom-checkbox",
          classes.checkboxStyling
        )}
      >
        <Input
          className={cx("custom-control-input", {
            [checkBoxClassName]: checkBoxClassName,
            [classes.outline]: showOutline,
            "custom-checkbox-indeterminate": indeterminate,
          })}
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          {...rest}
        />

        {label && (
          <label
            className={cx("custom-control-label px-2 w-100", {
              [labelClassName]: labelClassName,
            })}
            htmlFor={id}
          >
            {label}
          </label>
        )}
      </div>

      {touched && error && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </>
  );
};

export default CheckBox;
