import cx from "classnames";
import React, { useEffect } from "react";
import Typography from "../Typography";
import classes from "./style.module.scss";
import { ToastProps } from "./types";

const Toast: React.FC<ToastProps> = (props) => {
  const {
    destroy,
    content,
    duration = 3000,
    title,
    position = "top",
    variant = "success",
    size = "md",
    showIndicationText = false,
  } = props;

  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => {
      destroy();
    }, duration);

    return () => clearTimeout(timer);
  }, [destroy, duration]);

  const getIcon = () => {
    switch (variant) {
      case "success":
        return <i className="fa fa-check-circle fa-lg"></i>;
      case "danger":
        return <i className="fa fa-exclamation-triangle fa-lg"></i>;
      case "default":
      default:
        return <i className="fa fa-thumbs-up fa-lg"></i>;
    }
  };

  const indicationText = () => {
    switch (variant) {
      case "success":
        return <span>Success!</span>;
      case "danger":
        return <span>Failed!</span>;
      case "default":
      default:
        return <span>Success!</span>;
    }
  };

  return (
    <div
      className={cx(
        classes.toastContainer,
        classes[variant],
        classes[`${position}-${size}`]
      )}
    >
      <div className={cx(classes.toastWrapper)}>
        <div className={classes.icon}>{getIcon()}</div>
        <div className={classes.contentWrapper}>
          {title && (
            <div className={classes.title}>
              {showIndicationText && indicationText()}
              {title}
            </div>
          )}
          <Typography variant="p14" fontWeight="bold" className="mb-0">
            {content}
          </Typography>
        </div>
      </div>
      <button onClick={destroy}>
        <i className="fa fa-times"></i>
      </button>
    </div>
  );
};

export default Toast;
