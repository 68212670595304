import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import { AllOrderParams } from "src/api/admin";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Modal from "src/components/Modal";
import Typography from "src/components/Typography";
import { CustomerInfo } from "src/pages/Customers/types";
import { getAllCustomers, updateCustomer } from "src/store/actions/admin";
import { postSubscriptionPlanCheckout } from "src/store/actions/subscription";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { telephoneNumberRegex } from "src/utils/constants";
import { getFormattedNumber } from "src/utils/utils";
import classes from "./styles.module.scss";
import { FormProps } from "./types";

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  customerInfo: CustomerInfo;
  sortState: AllOrderParams;
};

const CustomerEditForm: React.FC<Props> = ({
  isOpen = false,
  onToggle,
  customerInfo,
  sortState,
}) => {
  const { loading } = useReducerData("admin", "updateCustomer", {
    loading: false,
  });
  const actions = useStoreActions({
    postSubscriptionPlanCheckout,
    updateCustomer,
    getAllCustomers,
  });

  const checkoutSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Must be valid email addresses"),
    phone: Yup.string().matches(
      telephoneNumberRegex,
      "Number needs to be in the format XXX-XXX-XXXX"
    ),
  });

  const handleSubmit = async (values: FormProps) => {
    const customerId = customerInfo.id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.updateCustomer(customerId, values);
    if (status === 1) {
      actions.getAllCustomers(sortState);
      onToggle();
    }
  };

  return (
    <Modal className={classes.modalWrapper} isOpen={isOpen} toggle={onToggle}>
      <div>
        <Typography variant="h3" fontWeight="bold">
          Edit Details
        </Typography>
      </div>
      <div className={classes.wrapper}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={checkoutSchema}
          initialValues={{
            first_name: customerInfo.first_name,
            last_name: customerInfo.last_name,
            email: customerInfo.email,
            phone: customerInfo.phone,
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
            setFieldValue,
          }: FormikProps<FormProps>) => (
            <Form className={cx(classes.form, "d-flex flex-column")}>
              <FormGroup className={classes.formGroup}>
                <Input
                  inputGroupClassName={classes.inputGroupStyle}
                  labelClassName={classes.label}
                  required={true}
                  label="First Name"
                  value={values.first_name}
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.first_name}
                  touched={touched.first_name}
                  name="first_name"
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Input
                  required={true}
                  inputGroupClassName={classes.inputGroupStyle}
                  labelClassName={classes.label}
                  label="Last Name"
                  value={values.last_name}
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.last_name}
                  touched={touched.last_name}
                  name="last_name"
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Input
                  required={true}
                  inputGroupClassName={classes.inputGroupStyle}
                  labelClassName={classes.label}
                  label="Email"
                  value={values.email}
                  placeholder="example@gmail.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  touched={touched.email}
                  name="email"
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Input
                  inputGroupClassName={classes.inputGroupStyle}
                  labelClassName={classes.label}
                  label="Contact Phone"
                  value={values.phone}
                  placeholder="555-555-5555"
                  onChange={(value) => {
                    setFieldValue("phone", getFormattedNumber(value));
                  }}
                  onBlur={handleBlur}
                  error={errors.phone}
                  touched={touched.phone}
                  name="phone"
                />
              </FormGroup>

              <div className={classes.actionButtons}>
                <Button
                  buttonClassName={classes.submitButton}
                  loading={loading}
                  disabled={!isValid || !dirty}
                  type="submit"
                  buttonColor="primary"
                  buttonText="Save Changes"
                />
                <Button
                  buttonColor="secondary"
                  buttonText="Cancel"
                  onClick={onToggle}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default CustomerEditForm;
