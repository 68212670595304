import { Reducer } from "redux";

import Types from "../types/order";

const initialState = {
  allOrders: {
    loading: false,
    isError: false,
    message: "",
    orders: {},
  },
  orderById: {
    loading: false,
    isError: false,
    message: "",
    order: {},
  },
  shippingLabel: {
    loading: false,
    isError: false,
    message: "",
    label: {},
  },
};

const OrderReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.FETCH_ORDERS_STARTED:
      return {
        ...state,
        allOrders: {
          ...state.allOrders,
          loading: true,
          isError: false,
        },
      };
    case Types.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        allOrders: {
          ...state.allOrders,
          orders: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.FETCH_ORDERS_FAILED:
      return {
        ...state,
        allOrders: {
          ...state.allOrders,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.FETCH_ORDER_BY_ID_STARTED:
      return {
        ...state,
        orderById: {
          ...state.orderById,
          loading: true,
          isError: false,
        },
      };
    case Types.FETCH_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        orderById: {
          ...state.orderById,
          order: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.FETCH_ORDER_BY_ID_FAILED:
      return {
        ...state,
        orderById: {
          ...state.orderById,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    case Types.FETCH_SHIPPING_LABEL_STARTED:
      return {
        ...state,
        shippingLabel: {
          ...state.shippingLabel,
          loading: true,
          isError: false,
        },
      };
    case Types.FETCH_SHIPPING_LABEL_SUCCESS:
      return {
        ...state,
        shippingLabel: {
          ...state.shippingLabel,
          label: payload.data,
          loading: false,
          isError: false,
        },
      };
    case Types.FETCH_SHIPPING_LABEL_FAILED:
      return {
        ...state,
        shippingLabel: {
          ...state.shippingLabel,
          message: payload.message,
          loading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default OrderReducer;
