import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  default as BaseAxios,
} from "axios";
import Cookie from "js-cookie";

class RequestClass {
  axios: AxiosInstance;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
    this.axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
    };
    this.axios.defaults.withCredentials = true;
  }

  async call(config: AxiosRequestConfig, download = false) {
    try {
      const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;
      let headers: { [key: string]: string } = {
        Accept: "application/json",
        "Content-Type":
          (config?.headers?.contentType as string) ?? "application/json",
      };

      const accessToken = Cookie.get("token");

      if (accessToken) {
        headers = {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }
      const res = await this.axios.request({
        baseURL: serverBaseUrl,
        headers,
        ...config,
      });
      if (download) return res;
      const apiStatus = res.data?.status;
      // NOTE: small hack to override the order status
      if (config.url?.includes("/api/orders/")) {
        const orderStatus = res.data.status;
        return {
          ...res.data,
          order_status: orderStatus,
          status: apiStatus === false ? 0 : 1,
        };
      }
      return { ...res.data, status: apiStatus === false ? 0 : 1 };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorStatus = error?.response?.status || null;
        const data = error?.response?.data || null;
        return {
          status: 0,
          errorStatus,
          message: (error as { message: string }).message,
          data,
        };
      }
    }
  }
}

const Request = new RequestClass();
export { Request };
