import cx from "classnames";
import React from "react";
import Autocomplete from "react-google-autocomplete";
import { InputGroup } from "reactstrap";
import classes from "./styles.module.scss";
import { Props } from "./types";

const mapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";

const GoogleAutoCompleteInput: React.FC<Props> = ({
  inputClassName = "",
  inputGroupClassName = "",
  outlined = true,
  focused = false,
  error = "",
  label,
  labelClassName = "",
  id,
  alternative = false,
  inputId,
  touched = false,
  required = false,
  options,
  onPlaceSelected,
  value,
  ...rest
}) => {
  const renderLabel = () => {
    if (!label) return;
    return (
      <label
        className={cx("form-control-label mb-1", {
          [labelClassName]: labelClassName,
        })}
        htmlFor={id}
      >
        {label}
        {required && <span className={classes.requiredLabel}>*</span>}
      </label>
    );
  };
  return (
    <div>
      {renderLabel()}
      <InputGroup
        className={cx("input-group-merge", classes.formGroupStyling, {
          [inputGroupClassName]: inputGroupClassName,
          "input-group-alternative": alternative,
          [classes.focused]: focused,
          [classes.isInvalid]: touched && error,
        })}
      >
        <Autocomplete
          {...(inputId ? { id: inputId } : {})}
          className={cx(classes.inputStyling, {
            [inputClassName]: inputClassName,
            "border-0": !outlined,
          })}
          value={value}
          apiKey={mapApiKey}
          onPlaceSelected={onPlaceSelected}
          options={options && options}
          {...rest}
        />
      </InputGroup>
      {touched && error && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </div>
  );
};

export default GoogleAutoCompleteInput;
