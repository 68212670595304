import cx from "classnames";
import React from "react";
import { ButtonGroup as BaseButtonGroup } from "reactstrap";
import classes from "./styles.module.scss";
import { Props } from "./types";

const ButtonGroup: React.FC<Props> = ({
  children,
  error = "",
  className = "",
  ...rest
}) => {
  return (
    <>
      <BaseButtonGroup
        className={cx(classes.buttonGroupStyles, {
          [className]: className,
          [classes.isInvalid]: error,
        })}
        {...rest}
      >
        {children}
      </BaseButtonGroup>
      {error && <div className={classes.invalidFeedback}>{error}</div>}
    </>
  );
};

export default ButtonGroup;
