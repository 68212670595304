import React from "react";
import { useNavigate } from "react-router";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";

type Props = {
  path: string;
  isAdmin?: boolean;
};

const BreadCrumbs: React.FC<Props> = ({ path, isAdmin = false }) => {
  const navigate = useNavigate();
  const routes = isAdmin ? path.split("/").slice(1, 3) : path.split("/");
  const header = isAdmin ? path.split("/")[2] : path.split("/")[1];
  const breadcrumbRoutes = routes.slice(1, routes.length);
  if (breadcrumbRoutes.length > 1) {
    breadcrumbRoutes[1] = "Order Details";
  }

  const handleRouteChange = (path: string) => {
    if (isAdmin) return;
    const findRouteIndex = routes.findIndex((el) => el === path);
    const fullPath = routes.slice(0, findRouteIndex + 1).join("/");
    if (findRouteIndex === 1) {
      navigate(fullPath);
    }
  };

  return (
    <div className={classes.breadcrumbs}>
      <Typography className="mb-0" fontWeight="semibold" variant="p20">
        {header}
      </Typography>
      <div className={classes.routes}>
        <i className="fa fa-home" />
        {breadcrumbRoutes.map((path, index) => (
          <div key={index} className={classes.route}>
            <i className="fa fa-minus" />
            <div onClick={() => handleRouteChange(path)}>
              <Typography className="mb-0" fontWeight="semibold" variant="p14">
                {path}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BreadCrumbs;
