import React from "react";
import Carddollar from "src/assets/icons/card-dollar.svg";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";
import { Props } from "./types";

const SubscriptionCard: React.FC<Props> = ({
  handleButtonClick,
  headerText = "New subscriptions available",
  title = "Save on reports with a monthly subscription",
  footerText = "Click here to learn about our subscription tiers!",
}) => {
  return (
    <div className={classes.wrapper} onClick={handleButtonClick}>
      <div className={classes.subWrapper}>
        <div>
          {headerText}
          <Typography
            variant="p20"
            fontWeight="bold"
            className={classes.titleText}
          >
            {title}
          </Typography>
        </div>
        <div className={classes.orderButton}>
          <button>
            <img alt="image not found" src={Carddollar} />
          </button>
        </div>
      </div>
      <Typography className={classes.date} variant="p14" fontWeight="light">
        {footerText}
      </Typography>
    </div>
  );
};

export default SubscriptionCard;
