import moment from "moment";
import React from "react";
import Card from "src/components/Card";
import Typography from "src/components/Typography";
import { PlanProps } from "src/pages/Plans/types";
import { useReducerData } from "src/store/hooks";
import classes from "./styles.module.scss";
import { Props } from "./types";

const SubscriptionSummary: React.FC<Props> = ({ hasCredit }) => {
  const { data: user } = useReducerData("user", "user", {});
  const { data: plans } = useReducerData("plans", "subscriptionPlans", {
    data: [],
  });
  const { data: products } = useReducerData("checkout", "products", {});
  const getCurrentPlan = plans?.find(
    (plan: PlanProps) => plan?.price_id === user?.subscription?.price_id
  );

  const getTimeLeft = () => {
    const expiryDate = moment(user?.subscription?.ends_at);
    const expiryDay = expiryDate.date();
    const expiryYear = expiryDate.year();
    const month = moment(user?.subscription?.ends_at).format("MMM");
    return `${month} ${expiryDay}, ${expiryYear}`;
  };

  const renderContent = () => {
    if (hasCredit) {
      return (
        <Typography variant="p16" className={classes.cardText}>
          You have {user?.subscription?.credits} credits remaining that will
          expire at midnight on {getTimeLeft()}
        </Typography>
      );
    }
    return (
      <>
        <Typography variant="p16" className={classes.cardText}>
          You have used all {getCurrentPlan?.reports_count} credits for the
          current subscription period.
        </Typography>
        <Typography variant="p16">
          Additional reports purchased before midnight on {getTimeLeft()} will
          be discounted to{" "}
          <b>
            $
            {products?.product_details?.unit_amount -
              products?.subscription_details?.unit_amount}{" "}
            per report.
          </b>
        </Typography>
      </>
    );
  };

  return (
    <Card
      cardClassName={classes.cardWrapper}
      cardTitleClassName={classes.title}
      cardBodyClassName={classes.cardBody}
      cardTitle="Your Subscription"
    >
      {renderContent()}
    </Card>
  );
};

export default SubscriptionSummary;
