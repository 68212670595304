import { Request } from "./request";

export type User = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
};

export type resetPasswordParams = {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
};

export const postSignup = async (data: User) => {
  return Request.call({
    url: "/api/register",
    method: "POST",
    data,
  });
};

export const postLogin = async (email: string, password: string) => {
  return Request.call({
    url: "/api/login",
    method: "POST",
    data: { email, password },
  });
};

export const resendVerificationEmail = async (email: string) => {
  return Request.call({
    url: "/api/email/verification-notification",
    method: "POST",
    data: { email },
  });
};

export const resetPassword = async (params: resetPasswordParams) => {
  return Request.call({
    url: "/api/reset_password",
    method: "POST",
    data: params,
  });
};

export const forgotPassword = async (email: string) => {
  return Request.call({
    url: "/api/reset_password_link",
    method: "POST",
    data: { email },
  });
};
